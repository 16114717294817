import { html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { store } from "lxl-data";
import { configApi, selectConfig } from "lxl-data/config";

import { faqApi, selectFaq } from "lxl-data/faq";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import styles from "./lxl-app-my-loyalty.scss";
@customElement("lxl-app-my-loyalty")
export class LxlMyLoyalty extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LxlConnectedElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property({ type: Boolean, reflect: true })
  suspended: boolean;

  @property({ type: Boolean, reflect: true })
  unsubscribed: boolean;

  @property({ type: Boolean })
  handleStatus: boolean;

  @state()
  menuSelectedIndex: string = "0";

  @state()
  showFaq: boolean = false;

  @state()
  dataFaq: any;

  query = configApi.endpoints.getConfig;
  selector = selectConfig;

  navigateToEarnPoints() {
    this.menuSelectedIndex = "1";
  }

  navigateToFAQ(e: Event) {
    e.preventDefault();

    const liveRegion = this.shadowRoot.getElementById('live-region');

    if (this.suspended || this.unsubscribed) {
      this.showFaq = true;
    } else {
      this.menuSelectedIndex = "4";
      if (liveRegion) {
        liveRegion.textContent =  this.localize("LXL_ACCESSIBILITY_FAQ_TAB_NAVIGATION", [this.menuSelectedIndex + 1]);
      }
    }
  }

  lxlConnectedLoadingRender() {
    return html`<lxl-component-skeleton height="640"></lxl-component-skeleton>`;
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('tab-selection-changed', this.handleTabSelection);
    window.addEventListener("lxlreloadtoken", (e) =>
      console.log("listener lxlreloadtoken", e)
    );
    store
      .dispatch(
        faqApi.endpoints.getFaq.initiate({
          jwt: this.jwt,
          locale: this.locale,
        })
      )
      .then((data) => {
        this.dataFaq = selectFaq(store.getState())?.faqs;
      });
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('tab-selection-changed', this.handleTabSelection);
}

private handleTabSelection(e: CustomEvent) {
  const selectedIndex = e.detail.selectedIndex;
  
  const liveRegion = this.shadowRoot.getElementById('live-region');
  if (liveRegion) {
      liveRegion.textContent = this.localize("LXL_ACCESSIBILITY_TAB_NAVIGATION", [e.detail.tabSelected, selectedIndex + 1]);
  }
}

  updated(changedProps): void {
    if (
      changedProps.get("suspended") !== undefined ||
      changedProps.get("unsubscribed") !== undefined
    ) {
      if (!this.suspended && !this.unsubscribed) {
        // Restore visibility button
        this.showFaq = false;
      }
    }
  }

  renderMenu() {
    if (this.suspended || this.unsubscribed) {
      return html``;
    } else {
      return html`
        <div 
            class="lxl-app-my-loyalty__menu" 
            onclick="javascript:tealium_data2track.push({id: 'Click', data_element_id: 'LoyaltyProgram_SectionTab', data_analytics_available_call: '1'});">
          <lxl-component-tabs
            @lxltabsclick="${(e) => this.setMenuSelectedIndex(e)}"
            .tabsData="${[
              this.localize("LXL_MYLOYALTY_MENU_DASHBOARD"),
              this.localize("LXL_MYLOYALTY_MENU_EARN_POINTS"),
              this.localize("LXL_MYLOYALTY_MENU_REDEEM_BENEFITS"),
              this.localize("LXL_MYLOYALTY_MENU_HISTORY"),
              this.localize("LXL_MYLOYALTY_MENU_FAQ"),
            ]}"
            selectedItemIndex="${this.menuSelectedIndex}"
            showNavigation
            tabs-id="my-loyalty"
          ></lxl-component-tabs>
        </div>
      `;
    }
  }

  onUnsubscribe() {
    if (this.handleStatus) {
      this.suspended = true;
    }
  }

  onReactivate() {
    if (this.handleStatus) {
      this.suspended = false;
    }
  }

  renderDashboard() {
    return html` <lxl-page-dashboard
      jwt="${this.jwt}"
      locale="${this.locale}"
      ?suspended="${this.suspended}"
      ?unsubscribed="${this.unsubscribed}"
      @lxlprofilewidgetbuttonclick="${() => this.navigateToEarnPoints()}"
      @lxlwidgetprofileunsubscribe="${() => this.onUnsubscribe()}"
      @lxlwidgetprofilerestore="${this.onReactivate}"
    >
    </lxl-page-dashboard>`;
  }

  renderContent() {
    if (this.suspended || this.unsubscribed) {
      return this.renderDashboard();
    } else {
      return html`${choose(
        this.menuSelectedIndex,
        [
          ["0", () => this.renderDashboard()],
          [
            "1",
            () => html` <lxl-page-missions
              jwt="${this.jwt}"
              locale="${this.locale}"
            >
            </lxl-page-missions>`,
          ],
          [
            "2",
            () => html` <lxl-page-benefits
              jwt="${this.jwt}"
              locale="${this.locale}"
            >
            </lxl-page-benefits>`,
          ],
          [
            "3",
            () => html` <lxl-page-history
              jwt="${this.jwt}"
              locale="${this.locale}"
            >
            </lxl-page-history>`,
          ],
          [
            "4",
            () => html` <lxl-page-faq jwt="${this.jwt}" locale="${this.locale}">
            </lxl-page-faq>`,
          ],
        ],
        () => this.renderDashboard()
      )}`;
    }
  }

  lxlConnectedRender() {
    return html`
      <div class="lxl-app-my-loyalty">
      <span id="live-region" style="position: absolute; width: 1px; height: 1px; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0;" aria-live="assertive" aria-atomic="true"></span>
      <h2 class="title">${this.localize("LXL_MYLOYALTY_TITLE")}</h2>
        <p class="sub-title">${this.localize(this.unsubscribed ? "LXL_MYLOYALTY_SUBTITLE" : "LXL_MYLOYALTY_SUBTITLE_MEMBER")}</p>
        <slot></slot>
        ${this.renderMenu()} ${this.renderContent()}
        ${choose(this.menuSelectedIndex !== "4", [
          [
            true,
            () =>
              html` <div class="lxl-app-my-loyalty__footer" aria-labelledby="FAQ" role="region">
                <div
                  class="lxl-app-my-loyalty__footer_img"
                  style="background-image: url(${`${
                    this.assetsUrl || ""
                  }/faq-banner.png`})"
                ></div>
                <h3 aria-label="${this.localize("LXL_MYLOYALTY_FAQ_LINK_TITLE")}">${this.localize("LXL_MYLOYALTY_FAQ_LINK_TITLE")}</h3>
                <span aria-label="${this.localize("LXL_MYLOYALTY_FAQ_LINK_TEXT")}">${this.localize("LXL_MYLOYALTY_FAQ_LINK_TEXT")}</span>

                ${!this.showFaq
                  ? html` <lxl-component-button
                      role="button"
                      aria-label="${this.localize("LXL_MYLOYALTY_FAQ_LINK_LABEL")}"
                      @click="${(e: Event) => this.navigateToFAQ(e)}"
                      >${this.localize("LXL_MYLOYALTY_FAQ_LINK_LABEL")}
                    </lxl-component-button>`
                  : nothing}
                ${!!this.showFaq
                  ? html`<div>
                      <lxl-component-accordion
                        .accordionData="${this.dataFaq}"
                      ></lxl-component-accordion>
                    </div>`
                  : nothing}
              </div>`,
          ],
        ])}
      </div>
    `;
  }

  private setMenuSelectedIndex(e: CustomEvent) {
    this.menuSelectedIndex = (e.detail.selectedIndex as number).toString();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-app-my-loyalty": LxlMyLoyalty;
  }
}
