import { LitElement, html, nothing } from "lit";
import {customElement, property} from 'lit/decorators.js';
import styles from './lxl-component-tier-info-card.scss';

@customElement('lxl-component-tier-info-card')
export class LxlComponentTierInfoCard extends LitElement {
    static styles = styles;

    static shadowRootOptions = {
        ...LitElement.shadowRootOptions,
        mode: "open" as ShadowRootMode,
    };

    @property() iconUrl: string;
    @property() title: string;
    @property() content: string;


    getIcon() {
        return this.iconUrl ?
          html`
            <img class="lxl-component-tier-info-card-icon" src="${this.iconUrl}" alt="" />
          ` :
          nothing;
    }

    render() {
        return html`
            <div class="lxl-component-tier-info-card" role="region" aria-labelledby="tier-info-title">
                ${this.getIcon()}
                <h4 id="tier-info-title">${this.title}</h4>
                <p>${this.content}</p>
            </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'lxl-component-tier-info-card': LxlComponentTierInfoCard;
}
}
