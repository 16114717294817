import { LitElement, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import skeletonStyles from "./lxl-widget-list-card-skeleton.scss";
import styles from "./lxl-widget-list-card.scss";

export enum StatusColor {
  DEFAULT = "DEFAULT",
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}
@customElement("lxl-widget-list-card")
export class LxlWidgetListCard extends LitElement {
  static styles = styles;

  /* This code is setting the `shadowRootOptions` static property of the `LxlWidgetListCard` class. It
  is using the spread operator (`...`) to copy all the properties of the `shadowRootOptions` property
  of the `LitElement` class, and then setting the `mode` property to `"closed"` to ensure that the
  shadow root of the custom element is closed and cannot be accessed from outside the element. This is
  a security measure to prevent external styles and scripts from affecting the element's internal
  structure and behavior. */
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property({ attribute: "list-card-id" })
  listCardId: string;

  /* `urlImage: string;` This property is used to store the URL of an image that will
  be displayed on the card. The value of this property is set using the `url-image` attribute when
  the custom element is used in HTML. For example, `<lxl-widget-list-card
  url-image="https://example.com/image.jpg"></lxl-widget-list-card>`. The value of
  `urlImage` can then be accessed and used to set the `background-image` style of the card's image
  section.  If the `urlImage` property is not set or is set to an empty string, the
  image section will be not displayed.*/
  @property({ type: String, attribute: "url-image" })
  urlImage: string;

  /* `statusText: string;` This property is used to set the text content of of the badge
  section of the card.  */
  @property({ type: String, attribute: "status-text" })
  statusText: string;

  /* `statusText: string;` This property is used to apply status logic.  */
  @property({ type: String, attribute: "status" })
  status: string;

  /* The `statusColor: StatusColor = StatusColor.DEFAULT`. This property is used to 
  determine whether or not the statusColor css class has been set on statusText content*/
  @property({ type: StatusColor, attribute: "status-color" })
  statusColor: StatusColor = StatusColor.DEFAULT;

  /* `points: string;` This property is used to set the text content of the badge
  section of the card. */
  @property({ type: String })
  points: string;

  /* `expiresDate: string;` This property is used to store the expiration date of the
    card, which is displayed in the card's badge section if it is set. The value of this property is
    set using the `expires-date` attribute when the custom element is used in HTML. For example,
    `<lxl-widget-list-card expires-date="2022-12-31"></lxl-widget-list-card>`.*/
  @property({ type: String, attribute: "expires-date" })
  expiresDate: string;

  /* `title: string;` This property is used to set the text content of the title
  section of the card. */
  @property({ type: String })
  title: string;

  /* `detail: string;` This property is used to set the text content of the
  detail section of the card.
  */
  @property({ type: String })
  detail: string;

  /* This property is used to set the locale for formatting the expiration date displayed on the card. 
  The `toLocaleDateString()` method is used to format the date, and the `locale` property is passed as
  an argument to this method to specify the locale to use for formatting. If the `locale` property
  is not set or is set to an empty string, the default value of `"en-US"` will be used. */
  @property()
  locale: string;

  /* `buttonText: string = "Button";` This property is used to set the
  text content of the button displayed on the card. */
  @property({ type: String, attribute: "button-text" })
  buttonText: string;

  /* The `disabled: boolean = false`. This property is used to 
  determine whether or not the card is disabled and should display a backdrop layout */
  @property({ type: Boolean })
  disabled: boolean = false;

  /* The `lockedImage: boolean = false`.This property is used to 
  determine whether or not the locked icon is displayed */
  @property({ type: Boolean })
  lockedImage: boolean = false;

  /* The `hideButton: boolean = false`. This property is used to 
  determine whether or not the button of the card is displayed */
  @property({ type: Boolean })
  hideButton: boolean = false;

  /* `bubbles = false;` is defining a property called `bubbles` with a default value of `false`. This
  property is used to determine whether or not the custom event dispatched by the component should
  bubble up the DOM tree. If `bubbles` is set to `true`, the event will bubble up the DOM tree and can
  be captured by parent elements. If `bubbles` is set to `false`, the event will not bubble up the DOM
  tree and can only be captured by the component itself or its shadow DOM children. The `bubbles`
  property can be overridden by passing a different value as an attribute when using the component. */
  @property()
  bubbles = false;

  render() {
    const statusColor = {
      "status-color-primary": this.statusColor === StatusColor.PRIMARY,
      "status-color-secondary": this.statusColor === StatusColor.SECONDARY,
    };

    const lockedClass = `lxl-widget-list-card__image--locked ${this.status.toLowerCase()}`;

    const hideImage = { "hide-image": !this.urlImage.trim() };

    return html` <div
      class="lxl-widget-list-card  ${classMap(hideImage)}"
      id="lxl-widget-list-card-${this.listCardId ?? nothing}"
      @click="${(e: Event) => this._dispatchClick(e, "CARD")}"
      role="article"
    >
      <div
        class="lxl-widget-list-card__backdrop ${this.disabled
          ? "disabled"
          : ""}"
        aria-hidden="${this.disabled ? "true" : "false"}"
      ></div>

      <div
        class="lxl-widget-list-card__image"
        style="background-image: url('${this.urlImage}')"
        aria-hidden="${!this.urlImage.trim() ? "true" : "false"}"
      >
        <div
          class="${lockedClass}"
          aria-hidden="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 8H17V5C17 2.103 14.897 0 12 0C9.103 0 7 2.103 7 5V8H3C2.4475 8 2 8.4475 2 9V23C2 23.5525 2.4475 24 3 24H21C21.5525 24 22 23.5525 22 23V9C22 8.4475 21.5525 8 21 8ZM8 5C8 2.645 9.645 1 12 1C14.355 1 16 2.645 16 5V8H8V5ZM21 23H3V9H21V23Z"
              fill="black"
            />
            <path
              d="M12 18C13.103 18 14 17.103 14 16C14 14.897 13.103 14 12 14C10.897 14 10 14.897 10 16C10 17.103 10.897 18 12 18ZM12 15C12.5515 15 13 15.4485 13 16C13 16.5515 12.5515 17 12 17C11.4485 17 11 16.5515 11 16C11 15.4485 11.4485 15 12 15Z"
              fill="black"
            />
          </svg>
        </div>
      </div>

      <div class="lxl-widget-list-card__content">
        <div class="lxl-widget-list-card__content--header">
          ${!!this.statusText
            ? html`<div
                class="lxl-widget-list-card__content--header-badge ${classMap(
                  statusColor
                )}"
                aria-label="${this.statusText}"
              >
                ${this.statusText}
              </div>`
            : nothing}
          ${!!this.points
            ? html`<div
                class="lxl-widget-list-card__content--header-badge points"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <g id="004" clip-path="url(#clip0_8392_1442)">
                    <path
                      id="Vector"
                      d="M16.4653 6.058C16.386 5.821 16.1786 5.64667 15.9313 5.609L11.3933 4.91533C11.284 4.89867 11.1903 4.82833 11.143 4.72767L9.10364 0.383333C9.04864 0.266333 8.9623 0.170667 8.85764 0.104C8.75264 0.0373333 8.6293 0 8.49997 0C8.3063 0 8.1253 0.084 8.00097 0.224667C7.9593 0.271667 7.9243 0.324667 7.89664 0.383333L5.8563 4.728C5.80897 4.829 5.71497 4.899 5.60497 4.916L1.06797 5.609C0.82097 5.64667 0.61397 5.821 0.534637 6.058C0.455304 6.29533 0.515637 6.559 0.690637 6.738L4.0013 10.1303C4.07597 10.2067 4.10897 10.3107 4.09197 10.4153C3.9623 11.2133 3.30897 15.2223 3.30897 15.2223C3.2853 15.3673 3.31064 15.5153 3.3783 15.643C3.40097 15.6857 3.42797 15.726 3.45997 15.7633C3.58664 15.912 3.77364 15.9987 3.96897 15.9987C4.0823 15.9987 4.19464 15.9697 4.29364 15.9147L8.33897 13.679C8.3883 13.6517 8.44397 13.6373 8.5003 13.6373C8.55664 13.6373 8.6123 13.6517 8.66164 13.679L12.711 15.917C12.7866 15.959 12.87 15.9857 12.956 15.9957C12.9816 15.9983 13.0073 16 13.033 16C13.2133 16 13.3876 15.9267 13.5143 15.795C13.6596 15.6437 13.7253 15.4327 13.6916 15.2257C13.6916 15.2257 13.0376 11.2133 12.908 10.4153C12.891 10.3107 12.924 10.2067 12.9986 10.1303L16.31 6.73767C16.4843 6.55867 16.5446 6.295 16.4653 6.058ZM12.1693 10.0253C12.1683 10.0263 12.168 10.0277 12.168 10.029L13.0333 15.3327C13.0333 15.333 13.0333 15.333 13.033 15.333L8.49997 12.828L3.96964 15.3317C3.9693 15.3317 3.96897 15.332 3.96864 15.332C3.96764 15.332 3.96664 15.331 3.96664 15.3297L4.83164 10.029C4.83197 10.0277 4.8313 10.0263 4.8303 10.0253L1.16697 6.272C1.16597 6.27067 1.16664 6.26833 1.16864 6.268L6.2333 5.49433L8.49997 0.666667L10.7663 5.49367C10.7666 5.494 10.767 5.49467 10.7676 5.49467L15.8313 6.268C15.833 6.26833 15.834 6.27067 15.8326 6.27167L12.1693 10.0253Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8392_1442">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                ${this.points}
              </div>`
            : nothing}
          ${!!this.expiresDate
            ? html`<div
                class="lxl-widget-list-card__content--header-badge date"
                aria-label="${new Date(this.expiresDate.split('T')[0]).toLocaleDateString(this.locale, { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: "UTC", })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_8392_937)">
                    <path
                      d="M15.8333 1.33333H12.8333V0H12.1667V1.33333H8.83333V0H8.16667V1.33333H4.83333V0H4.16667V1.33333H1.16667C0.799 1.33333 0.5 1.63233 0.5 2V15.3333C0.5 15.701 0.799 16 1.16667 16H15.8333C16.201 16 16.5 15.701 16.5 15.3333V2C16.5 1.63233 16.201 1.33333 15.8333 1.33333ZM15.8337 15.3333H1.16667V5.33333H15.8333L15.8337 15.3333ZM1.16667 4.66667V2H4.16667V3.33333H4.83333V2H8.16667V3.33333H8.83333V2H12.1667V3.33333H12.8333V2H15.8333V4.66667H1.16667Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8392_937">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                ${new Date(this.expiresDate.split('T')[0]).toLocaleDateString(this.locale, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  timeZone: "UTC"
                })}
              </div>`
            : nothing}
        </div>
        <div class="lxl-widget-list-card__content--body">
          ${!!this.title
            ? html`<div
                class="lxl-widget-list-card__content--body-title"
                id="card-title-${this.listCardId}"
                title="${this.title}"
              >
                ${this.title}
              </div>`
            : nothing}
          ${!!this.detail
            ? html` <div
                class="lxl-widget-list-card__content--body-detail"
                title="${this.detail}"
              >
                ${this.detail}
              </div>`
            : nothing}
        </div>
        ${!this.hideButton
          ? html`<div class="lxl-widget-list-card__content--footer">
              <lxl-component-button
                @click="${(e: Event) => this._dispatchClick(e, "BUTTON")}"
                title="${this.buttonText}"
                >${this.buttonText}</lxl-component-button
              >
            </div>`
          : nothing}
      </div>
    </div>`;
  }

  private _dispatchClick(e: Event, type: "BUTTON" | "CARD" = "BUTTON") {
    e.stopPropagation();
    const eventType =
      type === "BUTTON" ? "lxllistcardbuttonclick" : "lxllistcardclick";
    this.dispatchEvent(
      new CustomEvent(eventType, {
        detail: { listCardId: this.listCardId },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-list-card": LxlWidgetListCard;
  }
}

@customElement("lxl-widget-list-card-skeleton")
export class LxlWidgetListCardSkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html`
      <div class="lxl-widget-list-card-skeleton">
        <div class="lxl-widget-list-card-skeleton__image">
          <lxl-component-skeleton
            height="100%"
            width="100%"
          ></lxl-component-skeleton>
        </div>
        <div class="lxl-widget-list-card-skeleton__content">
          <div class="lxl-widget-list-card-skeleton__content--header">
            <div
              class="lxl-widget-list-card-skeleton__content--header-badge"
              style="width:50%;"
            >
              <lxl-component-skeleton height="30"></lxl-component-skeleton>
            </div>
          </div>
          <div class="lxl-widget-list-card-skeleton__content--body">
            <lxl-component-skeleton height="30"></lxl-component-skeleton>
            <lxl-component-skeleton height="42"></lxl-component-skeleton>
          </div>
          <div class="lxl-widget-list-card-skeleton__content--footer">
            <lxl-component-skeleton button height="40"></lxl-component-skeleton>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-list-card-skeleton": LxlWidgetListCardSkeleton;
  }
}
