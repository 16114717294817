import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import { faqApi, selectFaq } from "lxl-data/faq";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import skeletonStyles from "./lxl-page-faq-skeleton.scss";
import styles from "./lxl-page-faq.scss";
@customElement("lxl-page-faq")
export class LxlPageFaq extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  query = faqApi.endpoints.getFaq;
  selector = selectFaq;

  lxlConnectedLoadingRender() {
    return html` <lxl-page-faq-skeleton></lxl-page-faq-skeleton> `;
  }

  lxlConnectedRender() {
    return html` <div class="lxl-page-faq" role="region">
      <div class="lxl-page-faq__list" role="list">
        <lxl-component-accordion
          .accordionData="${this.data?.faqs}"
          role="listitem"
        ></lxl-component-accordion>
      </div>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-faq": LxlPageFaq;
  }
}

@customElement("lxl-page-faq-skeleton")
export class LxlPageFaqSkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html`<div class="lxl-page-faq-skeleton">
      <div class="lxl-page-faq-skeleton__list">
        <lxl-component-skeleton></lxl-component-skeleton>
      </div>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-faq-skeleton": LxlPageFaqSkeleton;
  }
}
