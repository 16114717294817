import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import styles from "./lxl-widget-info-box.scss";

@customElement("lxl-widget-info-box")
export class LxlWidgetInfoBox extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property() title = "";
  @property() content = "";

  render() {
    return html` <lxl-component-card>
      <div class="lxl-widget-info-box">
        <h5>${this.title}</h5>
        <p>${this.content}</p>
      </div>
    </lxl-component-card>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-info-box": LxlWidgetInfoBox;
  }
}
