import { LitElement, html, nothing } from "lit";
import { customElement, property, query, queryAll, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import Swiper from "swiper";
import styles from "./lxl-component-tabs.scss";



@customElement("lxl-component-tabs")
export class LxlComponentTabs extends LitElement  {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property({ attribute: "tabs-id" })
  tabsId: string;

  /* `tabsData` is a property of the `LxlComponentTabs` class that is defined as an array of strings.
  This property is used to store the data that will be displayed. By default, the `tabsData`
  property is an empty array, but it can be set to an array of strings when an instance of the
  `LxlComponentTabs` class is created. */
  @property({ type: Array<String> })
  tabsData: Array<string> = [];


  /* `tabContainer` is a property of the `LxlComponentTabs` class decorated with the `@query` decorator.
   It is used to query and store a reference to the main container element of the tabs component in the
   component's template. This reference is used to manage focus and key event listeners for enabling
   keyboard navigation among the tabs. It must be an HTML element with the class `.lxl-component-tabs`
   that acts as the parent container for all individual tab elements. */
  @query(".lxl-component-tabs")
  tabContainer: HTMLElement;

  /* `bubbles = false;` is defining a default value for the `bubbles` property of the
  `LxlComponentTabs` class. If no value is provided for `bubbles` when an instance of the class is
  created, it will default to `false`. This property is used to determine whether the custom event
  dispatched by the component should bubble up through the DOM and be handled by any element in the
  document. If `bubbles` is set to `true`, the event will bubble up through the DOM, and if it is
  set to `false`, it will not. */
  @property()
  bubbles = false;

  /* `selectedItemIndex: number = 0;` is defining a property called `selectedItemIndex` on the
  `LxlComponentTabs` class that is of type `number`. It is initializing the default value of this
  property to `0`. This property is used to keep track of the index of the currently selected item in
  the menu. It is initialized to `0`, which means that the first item in the menu will be selected by
  default. This property is used to apply a different style to the selected item in the menu. */
  @property({ reflect: true, type: Number })
  selectedItemIndex: number = 0;

  /*  This property is used to determine whether or not to show the navigation
  buttons in the tabs component. If `showNavigation` is set to `true`, the navigation buttons will be
  displayed, and if it is set to `false` the navigation buttons will be linked to showMobileNavigation props value. */
  @property({ type: Boolean })
  showNavigation: boolean = false;

  /**   This property is used to determine whether or not to
   * show the navigation buttons in the tabs component on mobile devices. If `showMobileNavigation` is
   * set to `true`, the navigation buttons will be displayed on mobile devices, and if it is set to
   *`false`, the navigation buttons will be hidden on mobile devices. */
  @property({ type: Boolean })
  showMobileNavigation: boolean = false;

  /* The `tabsBarWidth` property is initializing the default value of the width of the `tabsBar`
  element in the component's template. It is set to `"0px"` by default, which means that the
  `tabsBar` element will have a width of `0 pixels` initially. This property is used to dynamically
  update the width of the `tabsBar` element based on the selected tab, ensuring that the `tabsBar`
  element is aligned with the selected tab. */
  @state()
  tabsBarWidth: string = "0px";

  /* The `tabsBarPositionLeft` property is initializing the default value of the left position of the
  `tabsBar` element in the component's template. It is set to `"0px"` by default, which means that
  the `tabsBar` element will be positioned at the leftmost position initially. This property is used
  to dynamically update the left position of the `tabsBar` element based on the selected tab,
  ensuring that the `tabsBar` element is aligned with the selected tab. */
  @state()
  tabsBarPositionLeft: string = "0px";

  /* `swiperEl` is a property of the `LxlComponentTabs` class that is decorated with the `@query`
  decorator. It is used to query and store a reference to the `swiper-container` element in the
  component's template.  */
  @query("swiper-container")
  swiperEl;

  /* The `lxlPrev` property is declaring a variable of type `HTMLElement` that will be used to store a
  reference to the element with the class `lxl-swiper-button-prev` in the component's template. This
  element represents the navigation button for moving to the prev slide in the Swiper component. By
  querying and storing a reference to this element, the component can interact with it and update
  its behavior or appearance if needed. */
  @query(".lxl-swiper-button-prev")
  lxlPrev: HTMLElement;

  /* The `lxlNext` property is declaring a variable of type `HTMLElement` that will be used to store a
  reference to the element with the class `lxl-swiper-button-next` in the component's template. This
  element represents the navigation button for moving to the next slide in the Swiper component. By
  querying and storing a reference to this element, the component can interact with it and update
  its behavior or appearance if needed. */
  @query(".lxl-swiper-button-next")
  lxlNext: HTMLElement;

  /* `selectedTab` is a property of the `LxlComponentTabs` class that is decorated with the `@query`
  decorator. It is used to query and store a reference to the `lxl-component-tabs__item active` element in the
  component's template.  */
  @query(".lxl-component-tabs__item[active]")
  selectedTab: HTMLElement;

  @queryAll(".lxl-component-tabs__item")
  tabItems: NodeList;

  /**  The `swiperRef` property is declaring a variable of type `Swiper` that will be used to store a
   * reference to the Swiper instance. This allows the component to interact with the Swiper API and
   * perform actions such as updating the slides or accessing the Swiper methods and properties.
   * */
  swiperRef: Swiper;

  /* The  property `nativeWindow` on the LxlComponentTabs` class with the value of the `window` object. */
  nativeWindow: Window = window;

  /* The `isMobile` property is a boolean variable that is used to determine whether the user is
 accessing the tabs component from a mobile device. */
  isMobile: boolean;

  connectedCallback(): void {
    super.connectedCallback();
    this.tabContainer?.setAttribute("tabindex", "0");
    this.tabContainer?.addEventListener("keydown", this.handleKeyDown);

    this.isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        this.nativeWindow?.navigator.userAgent.toLowerCase()
      );
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.tabContainer.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    let newIndex;
    switch (e.key) {
      case "ArrowRight":
        e.preventDefault();
        newIndex = (this.selectedItemIndex + 1) % this.tabsData.length;
        this.itemClicked(e, newIndex);
        break;
      case "ArrowLeft":
        e.preventDefault();
        newIndex = (this.selectedItemIndex - 1 + this.tabsData.length) % this.tabsData.length;
        this.itemClicked(e, newIndex);
        break;
      case "Home":
        e.preventDefault();
        this.updateSelectedIndex(0);
        break;
      case "End":
        e.preventDefault();
        this.updateSelectedIndex(this.tabsData.length - 1);
        break;
    }
  };

  updateSelectedIndex(newIndex: number) {
    this.selectedItemIndex = newIndex;
    this.requestUpdate();
    this.updateFocus(newIndex);
  }

  updateFocus(index: number) {
    const newActiveTab = this.tabItems[index];
    if (newActiveTab instanceof HTMLElement) {
      newActiveTab.focus();
    }
  }


  render() {
    return html` <div
      class="lxl-component-tabs"
      id="lxl-component-tabs-${this.tabsId ?? nothing}"
    >
      <swiper-container
        free-mode="true"
        space-between="30"
        grab-cursor="true"
        slides-offset-before="0"
        slides-offset-after="0"
        slides-per-view="auto"
        centered-slides="false"
        centered-slides-bounds="false"
        init="false"
        tabindex="0"
        @keydown="${this.handleKeyDown}"
      >
        ${map(
          this.tabsData,
          (value, index) => html`<swiper-slide>
            <div
              aria-labelledby="nav"
              class="lxl-component-tabs__item"
              id="lxl-component-tabs-item-${this.tabsId ?? nothing}-${index}"
              ?active=${this.selectedItemIndex === index}
              aria-selected=${this.selectedItemIndex === index
                ? "true"
                : "false"}
                role="tab"
              aria-live=${this.selectedItemIndex === index ? 'assertive' : 'off'}
              tabindex="${index === this.selectedItemIndex ? "0" : "-1"}"
              @click="${(e: Event) => this.itemClicked(e, index)}"
            >
              ${value}
            </div>
          </swiper-slide>`
        )}
        <div
          class="lxl-component-tabs__bar"
          style="width:${this.tabsBarWidth};left:${this.tabsBarPositionLeft};"
        ></div>
      </swiper-container>
      ${this.showNavigation || (this.showMobileNavigation && this.isMobile)
        ? html` <div class="lxl-swiper-button-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M1.83917 9.58332L9.46125 1.96124L8.87209 1.37207L0.244171 9.99999L8.87209 18.6279L9.46125 18.0387L1.83917 10.4167H20V9.58332H1.83917Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="lxl-swiper-button-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M11.1279 1.37207L10.5388 1.96124L18.1608 9.58332H0V10.4167H18.1608L10.5388 18.0387L11.1279 18.6279L19.7558 9.99999L11.1279 1.37207Z"
                  fill="black"
                />
              </svg>
            </div>`
        : nothing}
    </div>`;
  }

  firstUpdated() {
    this.swiperInit();
  }

  updated(changedProps) {
    this.swiperRef?.update();

    this.tabsBarBehavior();

    /* 
      // Test: Mobile behaviour after having clicked a tab
     this.swiperMobileBehaviour();
     */

    if (
      changedProps.get("showNavigation") !== undefined ||
      changedProps.get("showMobileNavigation") !== undefined
    ) {
      this.fixUndefinedNavigation();
    }
  }

  /**
   * Status: NOT USED
   * The function `swiperMobileBehaviour` slides the swiper to the selected item index if the current device is mobile.
   */
  private swiperMobileBehaviour() {
    // For debugging mode (is always mobile) when resize up to 426px
    const mediaQuery = window.matchMedia("(min-width: 426px)");

    if (this.isMobile && !mediaQuery.matches) {
      this.swiperRef?.slideTo(this.selectedItemIndex);
    }
  }

  /**
   * The `swiperInit()` function initializes a Swiper element with specified parameters and assigns it
   * to `this.swiperEl`.
   */
  private swiperInit() {
    // Swiper parameters
    const swiperParams = {
      ...((this.showNavigation ||
        (this.showMobileNavigation && this.isMobile)) && {
        navigation: {
          nextEl: this.lxlNext,
          prevEl: this.lxlPrev,
        },
      }),
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      resistanceRatio: 0.5, // 0 value in order to disable the resistance default 0.85
      injectStyles: [
        `
        .swiper-wrapper {}
        :host .swiper-wrapper {}
        :root {}
      `,
      ],
      on: {
        init: (swiper: Swiper) => {
          this.swiperRef = swiper;
        },
        afterInit: (swiper: Swiper) => {
          setTimeout(() => {
            this.swiperRef = swiper;
            swiper.update();
          });
        },
      },
    };
    // Assign all parameters to Swiper element
    Object.assign(this.swiperEl, swiperParams);
    // Initialize it
    this.swiperEl.initialize();
  }

  /**
   * The function fix a visual bug when showMobile or showMobileNavigation props are changed dinamically.
   * An "undefined" value is set on the text content of the node with class `swiper-button-next` replacing the svg children element.
   * It updates the navigation next button of a swiper element by removing the text content "undefined" value.
   * It updates the swiperRef.
   */
  private fixUndefinedNavigation() {
    const swiperShadowRoot = this.swiperEl.shadowRoot as ShadowRoot;
    if (!!swiperShadowRoot.querySelector(".swiper-button-next")) {
      swiperShadowRoot.querySelector(".swiper-button-next").textContent = "";
      this.swiperRef.update();
    }
  }

  /* `itemClicked(index: number)` is a private method of the `LxlComponentTabs` class that is
  called when a tab is clicked. It takes an index parameter that represents the index
  of the clicked tab in the `tabsData` array. */
  private itemClicked(e: Event, index: number) {
    e.stopPropagation();

    this.selectedItemIndex = index;

    this.dispatchEvent(new CustomEvent('tab-selection-changed', {
      detail: { selectedIndex: index, tabSelected: this.tabsData[index] },
      bubbles: true,
      composed: true
  }));

    this._dispatchClickEvent(index);
  }

  /**
   * The function calculates the width and left position of the selected tab's parent element.
   */
  private tabsBarBehavior() {
    this.tabsBarWidth = `${this.selectedTab.offsetWidth}px`;

    this.tabsBarPositionLeft = `${this.selectedTab.parentElement.offsetLeft}px`;
  }

  /* The `_dispatchClickEvent(index: number)` method is a private method of the
  `LxlComponentTabs` class that is called when a menu item is clicked. It dispatches a
  custom event named "lxltabsclick" with the `detail` property set to an object containing
  the `selectedIndex` and `tabsId` properties. The `selectedIndex` property is set to the
  index of the clicked item in the `tabsData` array, and the `tabsId` property is set to the
  value of the `tabsId` property of the `LxlComponentTabs` instance. The `bubbles` property
  of the custom event is set to the value of the `bubbles` property of the
  `LxlComponentTabs` instance, and the `composed` property is set to `true`. This custom
  event can be listened to by other elements in the DOM and can be used to trigger actions
  based on the selected menu item. */
  private _dispatchClickEvent(index: number) {
    this.dispatchEvent(
      new CustomEvent("lxltabsclick", {
        detail: { selectedIndex: index, tabsId: this.tabsId },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-tabs": LxlComponentTabs;
  }
}
