import { LitElement, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import styles from "./lxl-component-paginator.scss";
@customElement("lxl-component-paginator")
export class LxlComponentPaginator extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  /** The element id */
  @property({ attribute: "paginator-id" })
  paginatorId: string;

  /** The number of items per page */
  @property({ type: Number })
  pageSize = 4;

  /** Total count of the elements. */
  @property({ type: Number })
  totalItems: number = 0;

  /** The selected page */
  @property({ type: Number })
  currentPage = 1;

  /** The maximum number of navigation pages displayed  */
  @property({ type: Number })
  maxPages = 4;

  /* `bubbles = false;` is defining a property `bubbles` with a default value of `false`. This property
  can be used to control whether events dispatched by the component should bubble up the DOM tree or
  not. If `bubbles` is set to `true`, events dispatched by the component will bubble up the DOM tree
  and can be handled by parent elements. If `bubbles` is set to `false`, events will not bubble up
  and can only be handled by the component itself or its shadow DOM children. By default, `bubbles`
  is set to `false`, which means that events dispatched by the component will not bubble up the DOM
  tree. */
  @property()
  bubbles = false;

  @property({ type: String, attribute: "custom-left-arrow-icon" })
  customLeftArrowIcon: string;

  @property({ type: String, attribute: "custom-right-arrow-icon" })
  customRightArrowIcon: string;

  /** Number of paginated pages. */
  @state()
  totalPages: number;

  /** Has pages before the current page. */
  @state()
  hasBefore: boolean;

  /** Has pages after the current page. */
  @state()
  hasNext: boolean;

  /** Displayed page elements */
  @state()
  displayedPages: Array<any>;

  /** First navigation page displayed */
  @state()
  startPage: number;

  /** Last navigation page displayed */
  @state()
  endPage: any;

  @state()
  hideLeftDots: boolean = true;

  @state()
  hideRightDots: boolean = true;

  @state()
  hideFirst: boolean = true;

  @state()
  hideLast: boolean = false;

  /* The above code is defining a private variable called `defaultLeftArrowIcon` in a TypeScript class.
The variable is assigned an HTML template string that represents an SVG element. The SVG element is
a left arrow icon, with a black fill color. */
  private defaultLeftArrowIcon = html`<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g>
      <path
        d="M10.8619 15.8046L3.05688 7.99998L10.8619 0.195312L11.8046 1.13798L4.94289 7.99998L11.8046 14.862L10.8619 15.8046Z"
      />
    </g>
  </svg>`;

  /* The above code is defining a private variable called `defaultRightArrowIcon` in a TypeScript class.
The variable is assigned an HTML template string that represents an SVG element. The SVG element is
a right arrow icon, with a black fill color. */
  private defaultRightArrowIcon = html`<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g>
      <path
        d="M5.13798 15.8046L4.19531 14.862L11.057 7.99998L4.19531 1.13798L5.13798 0.195312L12.943 7.99998L5.13798 15.8046Z"
      />
    </g>
  </svg>`;

  connectedCallback(): void {
    super.connectedCallback();
    if (!!this.totalItems) {
      const { startItemIndex, endItemIndex } = this.getItemIndexes(
        this.currentPage,
        this.pageSize,
        this.totalItems
      );

      this.generateDisplayedPages(
        this.currentPage,
        this.pageSize,
        this.totalItems,
        this.maxPages,
        "NEXT"
      );

      this._dispatchClickEvent(startItemIndex, endItemIndex);
    }
  }

  render() {
    return html`<nav
      class="lxl-component-paginator"
      id="lxl-component-paginator-${this.paginatorId ?? nothing}"
    >
      <ul class="lxl-component-paginator__list">
        <li
          style="${!this.hasBefore ? "visibility:hidden;" : nothing}"
        >
          <span @click="${(e: Event) => this.onPrev(e)}">
          ${
            !!this.customLeftArrowIcon
              ? unsafeHTML(this.customLeftArrowIcon)
              : this.defaultLeftArrowIcon
          }</span
        </span>
        </li>
        <li
          ?hidden="${this.hideFirst}"
          @click="${(e: Event) => this.onBegin(e)}"
        >
          <span>1</span>
        </li>
        <li
          ?hidden="${this.hideLeftDots}"
          @click="${(e: Event) => this.onPrev(e)}"
        >
          <span>...</span>
        </li>
        ${map(
          this.displayedPages,
          (item, index) => html`<li
            class="${item === this.currentPage ? "current" : ""}"
          >
            <span
              @click="${item !== this.currentPage
                ? (e: Event) => this.moveTo(e, item)
                : nothing}"
              >${item}</span
            >
          </li>`
        )}
        <li
          ?hidden="${this.hideRightDots}"
          @click="${(e: Event) => this.onNext(e)}"
        >
          <span>...</span>
        </li>
        <li ?hidden="${this.hideLast}" @click="${(e: Event) => this.onEnd(e)}">
          <span>${this.totalPages}</span>
        </li>
        <li
          style="${!this.hasNext ? "visibility:hidden;" : nothing}"
        >
          <span @click="${(e: Event) => this.onNext(e)}"
            >${
              !!this.customRightArrowIcon
                ? unsafeHTML(this.customRightArrowIcon)
                : this.defaultRightArrowIcon
            }</span
          >
        </li>
      </ul>
    </nav>`;
  }

  updated(changedProps) {
    if (changedProps.get("totalItems") !== undefined) {
      const { startItemIndex, endItemIndex } = this.getItemIndexes(
        this.currentPage,
        this.pageSize,
        this.totalItems
      );

      this.generateDisplayedPages(
        this.currentPage,
        this.pageSize,
        this.totalItems,
        this.maxPages,
        "NEXT"
      );

      this._dispatchClickEvent(startItemIndex, endItemIndex);
    }
  }

  reload() {
    const { startItemIndex, endItemIndex } = this.getItemIndexes(
      1,
      this.pageSize,
      this.totalItems
    );

    this.generateDisplayedPages(
      1,
      this.pageSize,
      this.totalItems,
      this.maxPages,
      "NEXT"
    );

    this._dispatchClickEvent(startItemIndex, endItemIndex);
  }

  /**
   * The `moveTo` function updates the current page, checks if there are any pages, and dispatches a
   * click event.
   * @param {Event} e - The "e" parameter is an event object that represents the event that triggered the
   * function.
   * @param currentPage - The currentPage parameter represents the current page number in a pagination
   * system. It is used to determine which page to move to when a certain event occurs.
   */
  moveTo(e: Event, currentPage) {
    e.stopPropagation();
    const { startItemIndex, endItemIndex } = this.getItemIndexes(
      currentPage,
      this.pageSize,
      this.totalItems
    );

    this.currentPage = currentPage;

    this.checkHasPages(currentPage, this.totalPages);

    this._dispatchClickEvent(startItemIndex, endItemIndex);
  }

  /**
   * The `onPrev` function handles the logic for navigating to the previous page in a pagination
   * component.
   * @param {Event} e - The parameter `e` is an event object that is passed to the `onPrev` function
   */
  onPrev(e: Event) {
    e.stopPropagation();

    if (this.currentPage > this.startPage) {
      this.currentPage--;
      this.checkHasPages(this.currentPage, this.totalPages);
    } else {
      const currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;

      this.generateDisplayedPages(
        currentPage,
        this.pageSize,
        this.totalItems,
        this.maxPages,
        "PREV"
      );
    }

    const { startItemIndex, endItemIndex } = this.getItemIndexes(
      this.currentPage,
      this.pageSize,
      this.totalItems
    );

    this._dispatchClickEvent(startItemIndex, endItemIndex);
  }

  /**
   * The `onNext` function handles the logic for navigating to the next page in a pagination component.
   * @param {Event} e - The parameter "e" is an event object that is passed to the function.
   */
  onNext(e: Event) {
    e.stopPropagation();

    if (this.currentPage < this.endPage) {
      this.currentPage++;

      this.checkHasPages(this.currentPage, this.totalPages);
    } else {
      const currentPage =
        this.currentPage < this.totalPages
          ? this.currentPage + 1
          : this.totalPages;

      this.generateDisplayedPages(
        currentPage,
        this.pageSize,
        this.totalItems,
        this.maxPages,
        "NEXT"
      );
    }

    const { startItemIndex, endItemIndex } = this.getItemIndexes(
      this.currentPage,
      this.pageSize,
      this.totalItems
    );

    this._dispatchClickEvent(startItemIndex, endItemIndex);
  }

  /**
   * The `onBegin` function generates and dispatches a click event for the first page of a pagination
   * component.
   * @param {Event} e - The parameter `e` is an event object that is passed to the `onBegin` function.
   */
  onBegin(e: Event) {
    e.stopPropagation();

    const currentPage = 1;

    this.generateDisplayedPages(
      currentPage,
      this.pageSize,
      this.totalItems,
      this.maxPages,
      "PREV"
    );

    const { startItemIndex, endItemIndex } = this.getItemIndexes(
      this.currentPage,
      this.pageSize,
      this.totalItems
    );

    this._dispatchClickEvent(startItemIndex, endItemIndex);
  }

  /**
   * The `onEnd` function generates and dispatches a click event for the last page of a pagination
   * component.
   * @param {Event} e - The parameter `e` is an event object of type `Event`.
   */
  onEnd(e: Event) {
    e.stopPropagation();

    const currentPage = this.totalPages;
    this.generateDisplayedPages(
      currentPage,
      this.pageSize,
      this.totalItems,
      this.maxPages,
      "NEXT"
    );

    const { startItemIndex, endItemIndex } = this.getItemIndexes(
      this.currentPage,
      this.pageSize,
      this.totalItems
    );

    this._dispatchClickEvent(startItemIndex, endItemIndex);
  }
  /**
   * The function generates a list of displayed pages based on the current page, page size, total items,
   * maximum number of pages, and direction.
   * @param currPage - The current page number.
   * @param pageSize - The `pageSize` parameter represents the number of items displayed on each page.
   * @param totalItems - The total number of items in the dataset.
   * @param maxPages - The `maxPages` parameter represents the maximum number of pages to be displayed in
   * the pagination component.
   * @param {"PREV" | "NEXT"} direction - The `direction` parameter is a string that specifies the
   * direction of pagination. It can have two possible values: "PREV" or "NEXT". This parameter is used
   * to determine whether the pagination should move to the previous page or the next page.
   */
  private generateDisplayedPages(
    currPage,
    pageSize,
    totalItems,
    maxPages,
    direction: "PREV" | "NEXT"
  ) {
    const { currentPage, totalPages, startPage, endPage, pages } =
      this.paginate(totalItems, currPage, pageSize, maxPages, direction);

    const { hideLeftDots, hideRightDots } = this.hideDots(
      startPage,
      endPage,
      totalPages,
      maxPages
    );

    const { hideFirst, hideLast } = this.hideSides(
      startPage,
      endPage,
      totalPages,
      maxPages
    );

    this.displayedPages = [...pages];
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.startPage = startPage;
    this.endPage = endPage;

    this.checkHasPages(currentPage, totalPages);

    this.hideLeftDots = hideLeftDots;
    this.hideRightDots = hideRightDots;
    this.hideFirst = hideFirst;
    this.hideLast = hideLast;
  }

  /**
   * The function "checkHasPages" updates the "hasBefore" and "hasNext" properties based on the current
   * page and total number of pages.
   * @param {number} currentPage - The current page number.
   * @param {number} totalPages - The total number of pages in the document or data set.
   */
  private checkHasPages(currentPage: number, totalPages: number) {
    this.hasBefore = this.checkHasBefore(currentPage);
    this.hasNext = this.checkHasNext(currentPage, totalPages);
  }

  /**
   * The `paginate` function calculates the necessary pagination properties based on the total number of
   * items, current page, page size, maximum number of pages to display, and pagination direction.
   * @param {number} totalItems - The total number of items to be paginated.
   * @param {number} currentPage - The current page number.
   * @param {number} pageSize - The pageSize parameter determines the number of items to be displayed on
   * each page.
   * @param {number} maxPages - The `maxPages` parameter determines the maximum number of pages to
   * display in the pagination.
   * @param {"PREV" | "NEXT"} [direction=NEXT] - The `direction` parameter is an optional parameter that
   * specifies the direction of pagination. It can have two possible values: "PREV" or "NEXT". By
   * default, the direction is set to "NEXT".
   * @returns an object with the following properties:
   */
  private paginate(
    totalItems: number,
    currentPage: number,
    pageSize: number,
    maxPages: number,
    direction: "PREV" | "NEXT" = "NEXT"
  ) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;

    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      // const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      // const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

      if (currentPage <= maxPages) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPages > totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        //startPage = currentPage - maxPagesBeforeCurrentPage;
        //endPage = currentPage + maxPagesAfterCurrentPage;
        startPage =
          direction === "NEXT" ? currentPage : currentPage - maxPages + 1;
        endPage =
          direction === "NEXT" ? currentPage + maxPages - 1 : currentPage;
      }
    }

    // create an array of pages
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      pages,
    };
  }

  /**
   * The function calculates the start and end indexes of items on a given page, based on the current
   * page number, page size, and total number of items.
   * @param {number} currentPage - The currentPage parameter represents the current page number in a
   * paginated list of items. It indicates which page of items should be displayed.
   * @param {number} pageSize - The `pageSize` parameter represents the number of items displayed on each
   * page.
   * @param {number} totalItems - The total number of items in the list or collection.
   * @returns an object with the properties `startItemIndex` and `endItemIndex`.
   */
  private getItemIndexes(
    currentPage: number,
    pageSize: number,
    totalItems: number
  ) {
    const startItemIndex = (currentPage - 1) * pageSize;
    const endItemIndex = Math.min(
      startItemIndex + pageSize - 1,
      totalItems - 1
    );
    return { startItemIndex, endItemIndex };
  }

  private _dispatchClickEvent(startItemIndex: number, endItemIndex: number) {
    this.dispatchEvent(
      new CustomEvent("lxlpaginatorclick", {
        detail: {
          paginatorId: this.paginatorId,
          startItemIndex,
          endItemIndex,
          currentPage: this.currentPage,
        },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }

  /**
   * The function determines whether to hide the left and right dots in a pagination component based on
   * the current page, total number of pages, and maximum number of visible pages.
   * @param startPage - The starting page number of the current visible page range.
   * @param endPage - The endPage parameter represents the last page number in a pagination system.
   * @param totalPages - The total number of pages in the pagination.
   * @param maxPages - The maximum number of pages to display on the pagination bar.
   * @returns An object with two properties: "hideLeftDots" and "hideRightDots".
   */
  private hideDots(startPage, endPage, totalPages, maxPages) {
    return {
      hideLeftDots: endPage <= maxPages || startPage === 2,
      hideRightDots: totalPages - startPage <= maxPages,
    };
  }

  /**
   * The function determines whether to hide the first and last pages based on the current page range and
   * the maximum number of visible pages.
   * @param startPage - The starting page number of the current visible range of pages.
   * @param endPage - The end page of the current range of pages being displayed.
   * @param totalPages - The total number of pages in the document or book.
   * @param maxPages - The maximum number of pages to display at a time.
   * @returns An object with two properties: "hideFirst" and "hideLast".
   */
  private hideSides(startPage, endPage, totalPages, maxPages) {
    return {
      hideFirst: endPage <= maxPages || startPage === 2,
      hideLast: totalPages - startPage <= maxPages,
    };
  }

  private checkHasBefore(currPage) {
    return currPage > 1;
  }

  private checkHasNext(currPage, totalPages) {
    return currPage < totalPages;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-paginator": LxlComponentPaginator;
  }
}
