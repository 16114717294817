import { LitElement, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import styles from "./lxl-component-wallet-apple.scss";
import { cardApi, store } from "lxl-data";

declare global {
  interface Window {
    ApplePaySession: any;
  }
}

@customElement("lxl-component-wallet-apple")
export class LxlComponentWalletApple extends LitElement {
  static styles = styles;

  static shadowRootOptions = { ...LitElement.shadowRootOptions, mode: "open" as ShadowRootMode };

  @property()
  locale: string;

  @property()
  jwt: string;

  @property({attribute: 'button-icon-url'})
  buttonIconUrl: string;

  downloadCard() {
    store.dispatch(cardApi.endpoints.getAppleWalletUrl.initiate({locale: this.locale, jwt: this.jwt})).then(res => {
      if ("error" in res) {
        return;
      } else {
        window.open(res.data, '_blank');
      }
    });
  }

  handleKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.downloadCard();
      event.preventDefault();
    }
  }

  render() {
    return html`
      <div class="lxl-component-wallet-apple" @keydown=${this.handleKeydown} role="button">
        <div @click=${this.downloadCard} class="lxl-component-wallet-apple-button-container">
          <img class="lxl-component-wallet-apple-add-button" alt="add to apple wallet"
               src=${this.buttonIconUrl} />
        </div>
      </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-wallet-apple": LxlComponentWalletApple;
  }
}
