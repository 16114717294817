import { LitElement, html, nothing } from "lit";
import {customElement, property, query, state} from 'lit/decorators.js';
import styles from './lxl-component-wallet-google.scss';


@customElement('lxl-component-wallet-google')
export class LxlComponentWalletGoogle extends LitElement {
    static styles = styles;

    static shadowRootOptions = {...LitElement.shadowRootOptions, mode: 'closed' as ShadowRootMode};

    @property()
    locale: string;

    @property({attribute: 'google-wallet-url'})
    addToGoogleWalletUrl: string;

    @property({attribute: 'button-icon-url'})
    buttonIconUrl: string;

    handleKeydown(event) {
        if (event.key === 'Enter' || event.key === ' ') { 
            window.open(this.addToGoogleWalletUrl, '_blank');
            event.preventDefault(); 
        }
    }


    render() {
        if (this.addToGoogleWalletUrl) {
            return html`
            <div class="lxl-component-wallet-google" @keydown="${this.handleKeydown}" role="button">
                <a
                  tabindex="-1"
                  target="_blank"
                  onclick="javascript:tealium_data2track.push({id: 'Click', data_element_id: 'LoyaltyWidget_Link'});"
                  href=${this.addToGoogleWalletUrl}>
                    <img class="lxl-component-wallet-google-add-button" alt="add to google wallet" src=${this.buttonIconUrl} />
                </a>
            </div>`
        } else {
            return nothing;
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'lxl-component-wallet-google': LxlComponentWalletGoogle;
}
}
