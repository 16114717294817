import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import styles from "./lxl-component-card.scss";

@customElement("lxl-component-card")
export class LxlComponentCard extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html` <div class="lxl-component-card">
      <slot></slot>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-card": LxlComponentCard;
  }
}
