import { LitElement, html, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { store } from "lxl-data";
import {
  Mission,
  MissionCategory,
  MissionStatus,
  missionsApi,
  selectMissions,
} from "lxl-data/missions";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import { lxlPortal } from "lxl-utils/lxl-portal";
import missionModalStyles from "./lxl-page-missions-modal-card-detail.scss";
import skeletonStyles from "./lxl-page-missions-skeleton.scss";
import styles from "./lxl-page-missions.scss";
export class MissionsSortItem {
  id: string;
  label: string;
  value?: string;
}
@customElement("lxl-page-missions")
export class LxlPageMissions extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @state() selectedFilterIndex = 0;

  @state() selectedSortIndex = 0;

  @state()
  missionsData: Mission[];

  @state()
  missionsDataTotal: number;

  @state()
  noItemsFoundMessage: string;

  @query("lxl-component-paginator")
  paginatorElement: LitElement & { reload: () => {} };

  @query("lxl-component-page-filter")
  filterElement: LitElement;

  query = missionsApi.endpoints.getMissions;

  selector = selectMissions;

  sortItems: MissionsSortItem[] = [{ id: "", label: "", value: "" }];

  filterItems: Array<any> = [{ label: "", value: "" }];

  @property({ type: Boolean })
  showModal: boolean;

  cardDetailData: Mission;

  @query('#ariaLiveAnnouncer') ariaLiveAnnouncer: HTMLElement;

  /**
   * The function fetchData() initiates a request to fetch missions data using an API endpoint and then
   * calls the init() function.
   */
  fetchData() {
    store
      .dispatch(
        missionsApi.endpoints.getMissions.initiate({
          jwt: this.jwt,
          locale: this.locale,
        })
      )
      .then(() => this.init());
  }

  updated(changedProperties){
    if(changedProperties.has('showModal')){
      const ariaMessage = this.showModal ? this.localize('LXL_ACCESSIBILITY_MODAL_OPENED') : this.localize('LXL_ACCESSIBILITY_MODAL_CLOSED');
      this.ariaLiveAnnouncer.textContent = ariaMessage;
    }
  }

  /**
   * The function `lxlConnectedLoadingRender` returns an HTML template for displaying a loading message
   * centered on the page.
   * @returns The code is returning an HTML div element with the text "Loading" inside. The div has
   */
  lxlConnectedLoadingRender() {
    return html` <lxl-page-missions-skeleton></lxl-page-missions-skeleton> `;
  }

  lxlConnectedRender() {
    this.sortItems = [
      {
        id: "0",
        label: this.localize("LXL_WIDGET_EARN_POINTS_SORT_NEWEST"),
        value: "value 1",
      },
      {
        id: "1",
        label: this.localize("LXL_WIDGET_EARN_POINTS_SORT_NEXT_EXPIRATION"),
        value: "value 2",
      },
      {
        id: "2",
        label: this.localize("LXL_WIDGET_EARN_POINTS_SORT_POINTS_FROM_MANY"),
        value: "value 3",
      },
      {
        id: "3",
        label: this.localize("LXL_WIDGET_EARN_POINTS_SORT_POINTS_FROM_FEW"),
        value: "value 4",
      },
    ];

    this.filterItems = [
      {
        label: this.localize("LXL_WIDGET_EARN_POINTS_FILTER_ALL"),
        value: "ALL",
      },
      {
        label: this.localize("LXL_WIDGET_EARN_POINTS_FILTER_AVAILABLE"),
        value: "AVAILABLE",
      },
      {
        label: this.localize("LXL_WIDGET_EARN_POINTS_FILTER_COMPLETED"),
        value: "COMPLETED",
      },
      {
        label: this.localize("LXL_WIDGET_EARN_POINTS_FILTER_EXPIRED"),
        value: "EXPIRED",
      },
    ];

    return html` <div class="lxl-page-missions" role="region">
      <div id="ariaLiveAnnouncer" aria-live="assertive" style="position: absolute; width: 1px; height: 1px; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0);"></div>
      <lxl-component-page-filter
        filter-id="missions"
        selectedFilterIndex="${this.selectedFilterIndex}"
        .filterItems="${this.filterItems}"
        .sortItems="${this.sortItems}"
        sort-text="${this.localize("LXL_WIDGET_EARN_POINTS_SORT")}"
        @lxlselectfilter="${(e: CustomEvent) =>
          this.selectFilterIndex(e.detail.selectedFilterIndex)}"
        @lxlchecksort="${(e: CustomEvent) =>
          this.selectSortIndex(e.detail.selectedSortItem)}"
      >
      </lxl-component-page-filter>

      <div class="lxl-page-missions__list" role="list">
        ${map(
          this.missionsData,
          (item, index) => html` <div class="lxl-page-missions__list--card" role="listitem">
            <lxl-widget-list-card
              listCardId=${item.missionCode}
              url-image="${item.image}"
              status-text="${this.localize(
                this.getLocalizeStatusKey(item.status)
              )}"
              status-color=${item.status === MissionStatus.COMPLETED
                ? "PRIMARY"
                : item.status === MissionStatus.EXPIRED
                ? "SECONDARY"
                : nothing}
              status="${item.status}"
              points="${item.pointsLabel}"
              expires-date="${item.endDate}"
              title="${item.name}"
              detail="${item.description}"
              button-text="${this.localize(
                "LXL_WIDGET_EARN_POINTS_CARD_BUTTON_LABEL"
              )}"
              locale="${this.locale}"
              disabled="${this.disableCard(item.status) || nothing}"
              hideButton="${this.disableCard(item.status) || nothing}"
              @lxllistcardbuttonclick="${(e: CustomEvent): void => {
                e.stopPropagation();
                if (this.disableCard(item.status)) {
                  return;
                }

                this.cardDetailData = item;
                this.showModal = true;
              }}"
              @lxllistcardclick="${(e: CustomEvent) => {
                e.stopPropagation();
                if (this.disableCard(item.status)) {
                  return;
                }
                this.cardDetailData = item;
                this.showModal = true;
              }}"
            >
            </lxl-widget-list-card>
          </div>`
        )}
        ${this.missionsDataTotal === 0 ? this.noItemsFoundMessage : nothing}
      </div>
      ${this.missionsDataTotal > 0
        ? html`
            <div class="lxl-page-missions__paginator">
              <div class="lxl-page-missions__paginator--container">
                <lxl-component-paginator
                  paginator-id="missions-paginator"
                  pageSize="4"
                  totalItems="${this.missionsDataTotal || 0}"
                  @lxlpaginatorclick="${(e: CustomEvent) => {
                    this.paginatorMissionsClick(e.detail);
                  }}"
                >
                </lxl-component-paginator>
              </div>
            </div>
          `
        : nothing}
      ${lxlPortal(
        this.showModal && !!this.cardDetailData,
        html`<lxl-component-mission-modal-card-detail
          .detailModalData=${this.cardDetailData}
          .stepperLocalize=${[
            this.localize(
              "LXL_WIDGET_EARN_POINTS_CARD_DETAIL_TRANSACTION_STEPPER1"
            ),
            this.localize(
              "LXL_WIDGET_EARN_POINTS_CARD_DETAIL_TRANSACTION_STEPPER2"
            ),
            this.localize(
              "LXL_WIDGET_EARN_POINTS_CARD_DETAIL_TRANSACTION_STEPPER3"
            ),
          ]}
          locale="${this.locale}"
        ></lxl-component-mission-modal-card-detail>`,
        () => {
          this.showModal = false;
          this.cardDetailData = null;
        }
      )}
    </div>`;
  }

  /**
   * The `init` function initializes the `missionsDataTotal` property by assigning it the length of the
   * `missions` array in the `data` object, or 0 if the `data` object or `missions` array is undefined.
   */
  private init() {
    this.missionsDataTotal = this.data?.missions?.length || 0;
    this.getNoItemFoundMessage(0);
  }

  /**
   * The `paginatorMissionsClick` function updates the `missionsData` array based on the provided start
   * and end item indices.
   */
  private paginatorMissionsClick({
    startItemIndex,
    endItemIndex,
  }: {
    startItemIndex: number;
    endItemIndex: number;
  }) {
    this.scrollItems();
    const status = this.getMissionItemStatus(this.selectedFilterIndex);
    const missionItems = this.getMissionItems(status);

    this.missionsData = [...missionItems].slice(
      startItemIndex,
      endItemIndex + 1
    );
  }

  /**
   * The `getMissionItems` method is a private method of the `LxlPageMissions` class
   * that takes a `status` parameter and returns an array of filtered mission items based on
   * the selected filter status. It uses the `filter` method to iterate through the `missions`
   * array of the `lxlMissionsData` property and returns only the items that match the
   * selected filter status.
   * @param {any} status - The `status` parameter is a variable that is used to filter the mission items
   * based on their status. It can have one of the following values:
   * @returns The `getMissionItems` function is returning an array of mission items that match
   * the given `status` parameter. If the `status` parameter is "ALL", then all mission items are
   * returned. If the `status` parameter is "AVAILABLE", then mission items with status "AVAILABLE" or
   * "NEW" are returned. Otherwise, mission items with the specified `status` are returned. The
   */
  private getMissionItems(status: any) {
    const sortedData = this.sortedData();

    return sortedData.filter((item) =>
      status === "ALL"
        ? true
        : status === MissionStatus.AVAILABLE
        ? item.status === MissionStatus.AVAILABLE ||
          item.status === MissionStatus.NEW
        : item.status === status
    );
  }

  /**
   * The `selectFilterIndex` method is a function of the `LxlPageMissions` class that is called
   * when the user selects a filter option from the filter menu. It takes an `index` parameter
   * that represents the position of the selected filter item in the `filterItems` array.
   * This function selects a filter index, retrieves the mission item status, filters the mission items
   * based on the status, and updates the total number of mission items.
   * @param {number} index - a number representing the index of the selected filter option.
   */
  private async selectFilterIndex(index: number) {
    this.selectedFilterIndex = index;

    this.getNoItemFoundMessage(index);

    this.recalculateTotalItems(index);

    if (this.missionsDataTotal > 0) {
      await this.updateComplete;
      this.paginatorElement?.reload();
    } else {
      this.missionsData = [];
    }
  }

  /**
   * The function sets the "noItemsFoundMessage" property based on the status and page name.
   * @param {number} index - The index parameter is a number that represents the position of an item in a
   * list or array.
   */
  private getNoItemFoundMessage(index: number) {
    const status: string = this.getMissionItemStatus(index);
    const label: string = this.getMissionItemLabel(index);
    const pageName = this.localize(
      "LXL_MYLOYALTY_MENU_EARN_POINTS"
    ).toLowerCase();

    this.noItemsFoundMessage = this.localize(
      "LXL_MYLOYALTY_NO_ITEMS_FOUND_MESSAGE",
      [status !== "ALL" ? label.toLowerCase() : "", pageName]
    ).replace(/\s+/g, " ");
  }

  /**
   * The function `selectSortIndex` updates the selected sort index, recalculates the total items based
   * on the selected filter index, and reloads the paginator if there are missions data, otherwise it
   * clears the missions data.
   * @param {MissionsSortItem} selectedItem - The `selectedItem` parameter is an object of type
   * `MissionsSortItem`.
   */
  private async selectSortIndex(selectedItem: MissionsSortItem) {
    this.selectedSortIndex = this.sortItems.findIndex(
      (el) => el.id === selectedItem.id
    );

    this.recalculateTotalItems(this.selectedFilterIndex);

    if (this.missionsDataTotal > 0) {
      await this.updateComplete;
      this.paginatorElement?.reload();
    } else {
      this.missionsData = [];
    }
  }

  /**
   * The function "recalculateTotalItems" updates the total number of mission items based on the status
   * of a specific item.
   * @param {number} index - The index parameter is a number that represents the position of an item in
   * a list or array.
   */
  private recalculateTotalItems(index: number) {
    const status = this.getMissionItemStatus(index);

    this.missionsDataTotal = this.getMissionItems(status).length;
  }

  /**
   * The function "sortedData" sorts the "missions" data based on the selected sort index.
   * @returns the sortedData array.
   */
  private sortedData() {
    let sortedData = [];

    switch (this.selectedSortIndex) {
      case 0:
        sortedData = this.sortByDate(this.data.missions, "NEWEST");
        break;
      case 1:
        sortedData = this.sortByDate(this.data.missions, "EXPIRATION");
        break;
      case 2:
        sortedData = this.sortByEarnPoints(this.data.missions, "DESC");
        break;
      case 3:
        sortedData = this.sortByEarnPoints(this.data.missions, "ASC");
        break;
    }
    return sortedData;
  }

  /**
   * The function sorts an array of Mission objects by their earn points property, with an optional
   * direction parameter to specify ascending or descending order.
   * @param {Mission[]} data - An array of Mission objects.
   * @param {"ASC" | "DESC"} [direction=ASC] - The "direction" parameter is used to specify the sorting
   * order of the missions. It can have two possible values: "ASC" for ascending order and "DESC" for
   * descending order. By default, the sorting order is set to "ASC".
   * @returns an array of Mission objects sorted by their earn points. The array includes all Mission
   * objects with pointsComputationType "FACTOR" followed by Mission objects with pointsComputationType
   * "FIXED" sorted in either ascending or descending order based on the direction parameter.
   */
  private sortByEarnPoints(data: Mission[], direction: "ASC" | "DESC" = "ASC") {
    const grouped = this.groupedData(data, "pointsComputationType");

    return [
      ...grouped["FACTOR"],
      ...(grouped["FIXED"] as Mission[]).sort((a, b) => {
        return direction === "ASC"
          ? this.sortAsc(a.points, b.points)
          : this.sortDesc(a.points, b.points);
      }),
    ];
  }

  /**
   * The function sorts an array of Mission objects by either their start date in descending order or
   * their end date in ascending order.
   * @param {Mission[]} data - An array of Mission objects. Each Mission object has properties such as
   * startDate and endDate.
   * @param {"NEWEST" | "EXPIRATION"} [type=NEWEST] - The "type" parameter is a string that specifies
   * the sorting type. It can have two possible values: "NEWEST" or "EXPIRATION".
   * @returns a sorted array of Mission objects based on the specified sorting type.
   */
  private sortByDate(
    data: Mission[],
    type: "NEWEST" | "EXPIRATION" = "NEWEST"
  ) {
    return [...data].sort((a, b) => {
      return type === "NEWEST"
        ? this.sortDesc(a.startDate, b.startDate)
        : this.sortAsc(a.endDate, b.endDate);
    });
  }

  /**
   * The function takes an array of Mission objects and groups them based on a specified field,
   * returning an object with the grouped data.
   * @param {Mission[]} data - An array of objects representing missions. Each mission object should
   * have a field specified by the "field" parameter.
   * @param {string} field - The `field` parameter is a string that represents the property of the
   * `Mission` objects in the `data` array that you want to group by.
   * @returns an object where the keys are the unique values of the specified field in the data array,
   * and the values are arrays containing the objects from the data array that have the corresponding
   * value for the specified field.
   */
  private groupedData(data: Mission[], field: string) {
    return [...data].reduce((acc, curr) => {
      const currField = (curr[field] as string).toUpperCase();
      if (!acc[currField]) {
        acc[currField] = [];
      }
      acc[currField].push(curr);
      return acc;
    }, {});
  }

  /**
   * The function "disableCard" returns true if the mission status is either expired or completed.
   * @param {MissionStatus} status - The status parameter is of type MissionStatus, which is an
   * enumeration.
   * @returns a boolean value.
   */
  private disableCard(status: MissionStatus): boolean {
    return (
      status === MissionStatus.EXPIRED || status === MissionStatus.COMPLETED
    );
  }

  /**
   * The function sorts two values in ascending order.
   * @param a - The parameter "a" is the first value being compared in the sorting function.
   * @param b - The parameter "b" is the second value being compared in the sorting function.
   * @returns The sortAsc function is returning -1 if a is less than b, 1 if a is greater than b, and 0
   * if a is equal to b.
   */
  private sortAsc(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  /**
   * The function sorts two values in descending order.
   * @param a - The first parameter, "a", is a value that will be compared to the second parameter, "b",
   * in order to determine their relative order in a descending sort.
   * @param b - The parameter "b" is the second value being compared in the sort function.
   * @returns The sortDesc function returns 1 if a is less than b, -1 if a is greater than b, and 0 if a
   * is equal to b.
   */
  private sortDesc(a, b) {
    return a < b ? 1 : a > b ? -1 : 0;
  }

  /**  The `getMissionItemStatus` method is returning the value of the `value` property of the
   * filter item at the specified index in the `filterItems` array. This value is used to
   * filter the missions data based on the selected filter. The `value` property of each filter
   * item corresponds to a specific status of the missions, such as "AVAILABLE", "COMPLETED",
   * etc.
   * @param {number} index - The index parameter is a number that represents the position of an item in
   * an array called filterItems. The function returns the value of the item at that index position.
   * @returns The `getMissionItemStatus` function is returning the value of the `value` property of the
   * `filterItems` array at the specified `index`.
   */
  private getMissionItemStatus(index: number) {
    return this.filterItems[index].value;
  }

  /**
   * The function returns the label of a mission item at a given index.
   * @param {number} index - The index parameter is a number that represents the position of an item in
   * an array.
   * @returns The label of the mission item at the specified index in the filterItems array.
   */
  private getMissionItemLabel(index: number) {
    return this.filterItems[index].label;
  }

  /**
   * This function returns a string value representing the corresponding localization key for that
   * status
   * @param {MissionStatus} status - MissionStatus enum type, which represents the status of a mission.
   * @returns a string value which is the key for the localized status of a mission based on the input
   * status parameter.
   */
  private getLocalizeStatusKey(status: MissionStatus) {
    let statusKey: string;
    switch (status) {
      case MissionStatus.AVAILABLE:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_AVAILABLE";
        break;
      case MissionStatus.COMPLETED:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_COMPLETED";
        break;
      case MissionStatus.EXPIRED:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_EXPIRED";
        break;

      case MissionStatus["IN PROGRESS"]:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_IN_PROGRESS";
        break;
      case MissionStatus.NEW:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_NEW";
        break;
    }
    return statusKey;
  }

  /**
   * The scrollItems function scrolls the filterElement into view smoothly.
   */
  private scrollItems() {
    setTimeout(() => {
      this.filterElement?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-missions": LxlPageMissions;
  }
}

@customElement("lxl-component-mission-modal-card-detail")
export class LxlComponentMissionModalCardDetail extends LitElement {
  static styles = missionModalStyles;

  @property({
    type: Object,
  })
  detailModalData: Mission;
  @property({
    type: Array,
  })
  stepperLocalize: Array<string>;

  @property()
  locale: string;

  @property({ type: Boolean, reflect: true })
  showModal: boolean;

  @query("lxl-component-modal")
  modalElement: LitElement & { close: () => {} };

  @query(".lxl-component-mission-modal-card-detail") modalContent: HTMLElement;

  firstUpdated(): void {
      setTimeout(() => {
        this.modalContent.focus();
      }, 0)
  }

  render() {
    return html`
      <lxl-component-modal prevent-backdrop-close>
        <div class="lxl-component-mission-modal-card-detail" role="dialog">
          <lxl-widget-list-card-detail
            list-card-detail-id="page-missions"
            url-image="${this.detailModalData?.image}"
            category="${this.detailModalData?.missionCategory}"
            title="${this.detailModalData?.name}"
            points="${this.detailModalData?.pointsLabel}"
            expires-date="${this.detailModalData?.endDate}"
            detail="${this.detailModalData?.description}"
            locale="${this.locale}"
          >
            ${this.detailModalData?.missionCategory ===
            MissionCategory.TRANSACTION
              ? html`<div
                  class="lxl-component-mission-modal-card-detail__slot1--transaction"
                  slot="content-slot"
                >
                  <div class="stepper">
                    <div class="stepper__header">
                      <div class="stepper__header--number">1</div>
                      <div class="stepper__header--line"></div>
                    </div>
                    <div class="stepper__content">
                      ${this.stepperLocalize[0]}
                    </div>
                  </div>
                  <div class="stepper">
                    <div class="stepper__header">
                      <div class="stepper__header--number">2</div>
                      <div class="stepper__header--line"></div>
                    </div>
                    <div class="stepper__content">
                      ${this.stepperLocalize[1]}
                    </div>
                  </div>
                  <div class="stepper">
                    <div class="stepper__header">
                      <div class="stepper__header--number">3</div>
                      <div class="stepper__header--line"></div>
                    </div>
                    <div class="stepper__content">
                      ${this.stepperLocalize[2]}
                    </div>
                  </div>
                </div>`
              : nothing}
            ${!!this.detailModalData?.missionUrl
              ? html` <div
                  autofocus
                  class="lxl-component-mission-modal-card-detail__slot2"
                  slot="footer-slot"
                >
                  <lxl-component-button
                    @click="${(e: Event) => {
                      e.stopPropagation();
                      /* console.log(
                        `${this.detailModalData?.missionCta} button clicked`
                      );*/
                      /*console.log("redirect");*/
                      window.open(this.detailModalData?.missionUrl, "_blank");
                      this.modalElement.close();
                    }}"
                    title="${this.detailModalData?.missionCta}"
                    >${this.detailModalData?.missionCta}</lxl-component-button
                  >
                </div>`
              : nothing}
          </lxl-widget-list-card-detail>
        </div>
      </lxl-component-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-mission-modal-card-detail": LxlComponentMissionModalCardDetail;
  }
}

@customElement("lxl-page-missions-skeleton")
export class LxlPageMissionsSkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html`<div class="lxl-page-missions-skeleton">
      <lxl-component-page-filter-skeleton></lxl-component-page-filter-skeleton>
      <div class="lxl-page-missions-skeleton__list">
        ${map(
          Array.from(Array(3).keys()),
          () =>
            html`<lxl-widget-list-card-skeleton></lxl-widget-list-card-skeleton>`
        )}
      </div>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-missions-skeleton": LxlPageMissionsSkeleton;
  }
}
