import { LitElement, html, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import styles from "./lxl-widget-list-card-detail.scss";
@customElement("lxl-widget-list-card-detail")
export class LxlWidgetListCardDetail extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };
  /* `listCardDetailId: string;` This property is used to set the element id. */
  @property({ attribute: "list-card-id" })
  listCardDetailId: string;

  /* `urlImage: string;` This property is used to store the URL of an image that will
  be displayed on the card. The value of this property is set using the `url-image` attribute when
  the custom element is used in HTML. For example, `<lxl-widget-list-card
  url-image="https://example.com/image.jpg"></lxl-widget-list-card>`. The value of
  `urlImage` can then be accessed and used to set the `background-image` style of the card's image
  section.  If the `urlImage` property is not set or is set to an empty string, the
  image section will be not displayed.*/
  @property({ type: String, attribute: "url-image" })
  urlImage: string;

  /* `category: string;` This property is used to set the text content of the category
  section of the card. */
  @property({ type: String })
  category: string;

  /* `title: string;` This property is used to set the text content of the title
  section of the card. */
  @property({ type: String })
  title: string;

  /* `points: string;` This property is used to set the text content of the badge
  section of the card. */
  @property({ type: String })
  points: string;

  /* `expiresDate: string;` This property is used to store the expiration date of the
    card, which is displayed in the card's badge section if it is set. The value of this property is
    set using the `expires-date` attribute when the custom element is used in HTML. For example,
    `<lxl-widget-list-card expires-date="2022-12-31"></lxl-widget-list-card>`.*/
  @property({ type: String, attribute: "expires-date" })
  expiresDate: string;

  /* `detail: string;` This property is used to set the text content of the
  detail section of the card.
  */
  @property({ type: String })
  detail: string;

  /* This property is used to set the locale for formatting the expiration date displayed on the card. 
  The `toLocaleDateString()` method is used to format the date, and the `locale` property is passed as
  an argument to this method to specify the locale to use for formatting. If the `locale` property
  is not set or is set to an empty string, the default value of `"en-US"` will be used. */
  @property()
  locale: string;

  /* `bubbles = false;` is defining a property called `bubbles` with a default value of `false`. This
  property is used to determine whether or not the custom event dispatched by the component should
  bubble up the DOM tree. If `bubbles` is set to `true`, the event will bubble up the DOM tree and can
  be captured by parent elements. If `bubbles` is set to `false`, the event will not bubble up the DOM
  tree and can only be captured by the component itself or its shadow DOM children. The `bubbles`
  property can be overridden by passing a different value as an attribute when using the component. */
  @property()
  bubbles = false;

  /* The `maxContentHeight` property is used to store the maximum height of the content section of the
  card. It is calculated based on the viewport height and the height of the card itself. If the height
  of the card exceeds a certain threshold (90% of the viewport height), the `maxContentHeight` is set
  to the remaining height available for the content section. This ensures that the content section
  does not overflow and can be scrolled if necessary. */
  @state()
  maxContentHeight: string;

  /* The isOnTop property is used to know if the scrollable content has reached the top */
  @state()
  isOnTop: boolean = true;

  /* The isOnBottom property is used to know if the scrollable content has reached the bottom */
  @state()
  isOnBottom: boolean = false;

  @query(".lxl-widget-list-card-detail__body--content")
  contentCardElement: LitElement;

  resizeTimeOut;

  connectedCallback(): void {
    super.connectedCallback();
    window.addEventListener("resize", this.resizeHandler);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.contentCardElement.removeEventListener("scroll", this.blurHandler);
    window.removeEventListener("resize", this.resizeHandler);
  }

  render() {
    const hideImage = { "hide-image": !this.urlImage.trim() };

    return html` <div
      class="lxl-widget-list-card-detail  ${classMap(hideImage)}"
      id="lxl-widget-list-card-detail-${this.listCardDetailId ?? nothing}"
      @click="${(e: Event) => this._dispatchClick(e)}"
    >
      <div
        class="lxl-widget-list-card-detail__image"
        style="background-image: url('${this.urlImage}')"
      ></div>
      <div
        class="lxl-widget-list-card-detail__body ${!this.isOnTop &&
        !!this.maxContentHeight
          ? "blur"
          : ""}"
      >
        <div
          class="lxl-widget-list-card-detail__body--content"
          style="max-height: ${this.maxContentHeight || nothing};"
        >
          ${!!this.category
            ? html`<div
                class="lxl-widget-list-card-detail__body--content-category"
              >
                ${this.category}
              </div>`
            : nothing}
          ${!!this.title
            ? html` <div
                class="lxl-widget-list-card-detail__body--content-title"
              >
                ${this.title}
              </div>`
            : nothing}
          <div class="lxl-widget-list-card-detail__body--content-info">
            ${!!this.points
              ? html` <div
                  class="lxl-widget-list-card-detail__body--content-info-badge points"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <g id="004" clip-path="url(#clip0_8392_1442)">
                      <path
                        id="Vector"
                        d="M16.4653 6.058C16.386 5.821 16.1786 5.64667 15.9313 5.609L11.3933 4.91533C11.284 4.89867 11.1903 4.82833 11.143 4.72767L9.10364 0.383333C9.04864 0.266333 8.9623 0.170667 8.85764 0.104C8.75264 0.0373333 8.6293 0 8.49997 0C8.3063 0 8.1253 0.084 8.00097 0.224667C7.9593 0.271667 7.9243 0.324667 7.89664 0.383333L5.8563 4.728C5.80897 4.829 5.71497 4.899 5.60497 4.916L1.06797 5.609C0.82097 5.64667 0.61397 5.821 0.534637 6.058C0.455304 6.29533 0.515637 6.559 0.690637 6.738L4.0013 10.1303C4.07597 10.2067 4.10897 10.3107 4.09197 10.4153C3.9623 11.2133 3.30897 15.2223 3.30897 15.2223C3.2853 15.3673 3.31064 15.5153 3.3783 15.643C3.40097 15.6857 3.42797 15.726 3.45997 15.7633C3.58664 15.912 3.77364 15.9987 3.96897 15.9987C4.0823 15.9987 4.19464 15.9697 4.29364 15.9147L8.33897 13.679C8.3883 13.6517 8.44397 13.6373 8.5003 13.6373C8.55664 13.6373 8.6123 13.6517 8.66164 13.679L12.711 15.917C12.7866 15.959 12.87 15.9857 12.956 15.9957C12.9816 15.9983 13.0073 16 13.033 16C13.2133 16 13.3876 15.9267 13.5143 15.795C13.6596 15.6437 13.7253 15.4327 13.6916 15.2257C13.6916 15.2257 13.0376 11.2133 12.908 10.4153C12.891 10.3107 12.924 10.2067 12.9986 10.1303L16.31 6.73767C16.4843 6.55867 16.5446 6.295 16.4653 6.058ZM12.1693 10.0253C12.1683 10.0263 12.168 10.0277 12.168 10.029L13.0333 15.3327C13.0333 15.333 13.0333 15.333 13.033 15.333L8.49997 12.828L3.96964 15.3317C3.9693 15.3317 3.96897 15.332 3.96864 15.332C3.96764 15.332 3.96664 15.331 3.96664 15.3297L4.83164 10.029C4.83197 10.0277 4.8313 10.0263 4.8303 10.0253L1.16697 6.272C1.16597 6.27067 1.16664 6.26833 1.16864 6.268L6.2333 5.49433L8.49997 0.666667L10.7663 5.49367C10.7666 5.494 10.767 5.49467 10.7676 5.49467L15.8313 6.268C15.833 6.26833 15.834 6.27067 15.8326 6.27167L12.1693 10.0253Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8392_1442">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  ${this.points}
                </div>`
              : nothing}
            ${!!this.expiresDate
              ? html` <div
                  class="lxl-widget-list-card-detail__body--content-info-badge date"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_8392_937)">
                      <path
                        d="M15.8333 1.33333H12.8333V0H12.1667V1.33333H8.83333V0H8.16667V1.33333H4.83333V0H4.16667V1.33333H1.16667C0.799 1.33333 0.5 1.63233 0.5 2V15.3333C0.5 15.701 0.799 16 1.16667 16H15.8333C16.201 16 16.5 15.701 16.5 15.3333V2C16.5 1.63233 16.201 1.33333 15.8333 1.33333ZM15.8337 15.3333H1.16667V5.33333H15.8333L15.8337 15.3333ZM1.16667 4.66667V2H4.16667V3.33333H4.83333V2H8.16667V3.33333H8.83333V2H12.1667V3.33333H12.8333V2H15.8333V4.66667H1.16667Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8392_937">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  ${new Date(this.expiresDate.split('T')[0]).toLocaleDateString(this.locale, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    timeZone: "UTC"
                  })}
                </div>`
              : nothing}
          </div>
          ${!!this.detail
            ? html`<div
                class="lxl-widget-list-card-detail__body--content-detail"
              >
                ${this.detail}
              </div>`
            : nothing}
          <slot name="content-slot"></slot>
        </div>
        <div
          class="lxl-widget-list-card-detail__footer ${!this.isOnBottom &&
          !!this.maxContentHeight
            ? "blur"
            : ""}"
        >
          <slot name="footer-slot"></slot>
        </div>
      </div>
    </div>`;
  }

  firstUpdated() {
    this.getHeight();
    this.contentCardElement.addEventListener("scroll", this.blurHandler);
  }

  /**
   * The function calculates the maximum content height based on the available viewport height and the
   * height of the card and its content.
   */
  private async getHeight() {
    await this.updateComplete;
    const viewportH = window.innerHeight;
    const maxH = viewportH * 0.9;
    const cardH = this.clientHeight;
    const cardContentH = this.contentCardElement.clientHeight;

    if (cardH > maxH) {
      const fixedContent = cardH - cardContentH;

      this.maxContentHeight = Math.floor(maxH - fixedContent) + "px";
    } else {
      this.maxContentHeight = null;
    }
  }

  /* This method is used as an event handler for the `resize` event. */
  private resizeHandler = () => {
    clearTimeout(this.resizeTimeOut);

    this.resizeTimeOut = setTimeout(
      () => new Promise(() => this.getHeight()),
      400
    );
  };

  /*  This method is responsible for updating the values of `isOnTop` and `isOnBottom` properties based on the current
scrollbar position. The `getScrollbarPosition` method is called to determine the current position of
the scrollbar, and the returned values are assigned to the `isOnTop` and `isOnBottom` properties. */
  private blurHandler = () => {
    const { isOnTop, isOnBottom } = this.getScrollbarPosition();

    this.isOnTop = isOnTop;
    this.isOnBottom = isOnBottom;
  };

  /**
   * The function returns an object indicating whether the scrollbar is at the top or bottom of the
   * content card element.
   * @returns an object with two properties: "isOnTop" and "isOnBottom".
   */
  private getScrollbarPosition() {
    const isOnTop = !this.contentCardElement?.scrollTop || false;
    const isOnBottom =
      this.contentCardElement?.offsetHeight +
        this.contentCardElement?.scrollTop >=
      this.contentCardElement?.scrollHeight;
    return { isOnTop, isOnBottom };
  }

  private _dispatchClick(e: Event) {
    e.stopPropagation();

    this.dispatchEvent(
      new CustomEvent("lxllistcarddetailclick", {
        detail: { listCardDetailId: this.listCardDetailId },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-list-card-detail": LxlWidgetListCardDetail;
  }
}
