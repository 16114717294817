import { html, LitElement, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { store } from "lxl-data";
import {
  Benefit,
  benefitsApi,
  BenefitsState,
  BenefitStatus,
  RedemptionRule,
  selectBenefits,
} from "lxl-data/benefits";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import { lxlPortal } from "lxl-utils/lxl-portal";
import benefitModalStyles from "./lxl-page-benefit-modal-card-detail.scss";
import skeletonStyles from "./lxl-page-benefits-skeleton.scss";
import styles from "./lxl-page-benefits.scss";
import {getCssVar} from 'lxl-utils/lxl-css';
export class BenefitsSortItem {
  id: string;
  label: string;
  value?: string;
}
@customElement("lxl-page-benefits")
export class LxlPageBenefits extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property()
  lxlBenefitsData: BenefitsState;

  @state() selectedFilterIndex = 0;

  @state() selectedSortIndex = 0;

  @state()
  benefitsData: Benefit[];

  @state()
  benefitsDataTotal: number;

  @state()
  noItemsFoundMessage: string;

  @query("lxl-component-paginator")
  paginatorElement: LitElement & { reload: () => {} };

  @query("lxl-component-page-filter")
  filterElement: LitElement;

  sortItems: BenefitsSortItem[] = [{ id: "", label: "", value: "" }];

  filterItems: Array<any> = [{ label: "", value: "" }];

  @property({ type: Boolean })
  showModal: boolean;

  cardDetailData: Benefit;

  query = benefitsApi.endpoints.getBenefits;

  @query('#ariaLiveAnnouncer') ariaLiveAnnouncer: HTMLElement;

  selector = selectBenefits;

  updated(changedProperties){
    if(changedProperties.has('showModal')){
      const ariaMessage = this.showModal ? this.localize('LXL_ACCESSIBILITY_MODAL_OPENED') : this.localize('LXL_ACCESSIBILITY_MODAL_CLOSED');
      this.ariaLiveAnnouncer.textContent = ariaMessage;
    }
  }

  fetchData() {
    store
      .dispatch(
        benefitsApi.endpoints.getBenefits.initiate({
          locale: this.locale,
          jwt: this.jwt,
        })
      )
      .then(() => {
        this.init();
      });
  }

  lxlConnectedLoadingRender() {
    return html` <lxl-page-benefits-skeleton></lxl-page-benefits-skeleton> `;
  }

  lxlConnectedRender() {
    this.sortItems = [
      {
        id: "0",
        label: this.localize("LXL_WIDGET_BENEFITS_SORT_NEWEST"),
        value: "value 1",
      },
      {
        id: "1",
        label: this.localize("LXL_WIDGET_BENEFITS_SORT_NEXT_EXPIRATION"),
        value: "value 2",
      },
    ];

    this.filterItems = [
      {
        label: this.localize("LXL_WIDGET_BENEFITS_FILTER_ALL"),
        value: "ALL",
      },
      {
        label: this.localize("LXL_WIDGET_BENEFITS_FILTER_AVAILABLE"),
        value: "AVAILABLE",
      },
      {
        label: this.localize("LXL_WIDGET_BENEFITS_FILTER_BOOKED"),
        value: "BOOKED",
      },
      {
        label: this.localize("LXL_WIDGET_BENEFITS_FILTER_USED"),
        value: "USED",
      },
      {
        label: this.localize("LXL_WIDGET_BENEFITS_FILTER_EXPIRED"),
        value: "EXPIRED",
      },
    ];

    return html` <div class="lxl-page-benefits" role="region">
      <div id="ariaLiveAnnouncer" aria-live="assertive" style="position: absolute; width: 1px; height: 1px; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0);"></div>
      <lxl-component-page-filter
        filter-id="benefits"
        selectedFilterIndex="${this.selectedFilterIndex}"
        .filterItems="${this.filterItems}"
        .sortItems="${this.sortItems}"
        sort-text=${this.localize("LXL_WIDGET_BENEFITS_SORT")}
        @lxlselectfilter="${(e: CustomEvent) =>
          this.selectFilterIndex(e.detail.selectedFilterIndex)}"
        @lxlchecksort="${(e: CustomEvent) =>
          this.selectSortIndex(e.detail.selectedSortItem)}"
      >
      </lxl-component-page-filter>
      <div class="lxl-page-benefits__list" role="list">
        ${map(
          this.benefitsData,
          (item, index) => html` <div class="lxl-page-benefits__list--card" role="listitem" onclick="javascript:tealium_data2track.push({id: 'Click', data_element_id: 'LoyaltyProgram_Benefits', data_description:'ViewDetails'});">
            <lxl-widget-list-card
              listCardId=${item.benefitCode}
              url-image="${item.image}"
              status-text="${this.localize(
                this.getLocalizeStatusKey(item.status),
                item.status === BenefitStatus.FREEZED ? [item.availableIn] : []
              )}"
              status-color=${item.status === BenefitStatus.USED
                ? "PRIMARY"
                : item.status === BenefitStatus.EXPIRED
                ? "SECONDARY"
                : nothing}
              status="${item.status}"
              expires-date="${item.endDate}"
              title="${item.benefitName}"
              detail="${item.benefitDescriptionShort}"
              button-text="${this.localize(
                "LXL_WIDGET_BENEFITS_CARD_BUTTON_LABEL"
              )}"
              locale="${this.locale}"
              disabled="${this.disableCard(item.status) || nothing}"
              hideButton="${this.hideButton(item.status) || nothing}"
              locked="${item.status === BenefitStatus.FREEZED || nothing}"
              @lxllistcardbuttonclick="${(e: CustomEvent): void => {
                e.stopPropagation();
                if (this.hideButton(item.status)) {
                  return;
                }

                this.cardDetailData = item;
                this.showModal = true;
              }}"
              @lxllistcardclick="${(e: CustomEvent) => {
                e.stopPropagation();
                if (this.disableCard(item.status)) {
                  return;
                }
                this.cardDetailData = item;
                this.showModal = true;
              }}"
            >
            </lxl-widget-list-card>
          </div>`
        )}
        ${this.benefitsDataTotal === 0 ? this.noItemsFoundMessage : nothing}
      </div>
      ${this.benefitsDataTotal > 0
        ? html` <div class="lxl-page-benefits__paginator">
            <div class="lxl-page-benefits__paginator--container">
              <lxl-component-paginator
                paginator-id="benefits-paginator"
                pageSize="6"
                totalItems="${this.benefitsDataTotal || 0}"
                @lxlpaginatorclick="${(e: CustomEvent) => {
                  this.paginatorBenefitsClick(e.detail);
                }}"
              >
              </lxl-component-paginator>
            </div>
          </div>`
        : nothing}
      ${lxlPortal(
        this.showModal && !!this.cardDetailData,
        html`<lxl-component-benefit-modal-card-detail
          .detailModalData=${this.cardDetailData}
          promo-message=${this.localize(
            "LXL_WIDGET_BENEFITS_CARD_DETAIL_PROMOCODE_MESSAGE"
          )}
          error-message="${this.localize(
            "LXL_WIDGET_BENEFITS_CARD_DETAIL_PROMOCODE_MESSAGE_ERROR"
          )}"
          locale="${this.locale}"
          jwt="${this.jwt}"
          @lxlbenefitredeem="${(e: CustomEvent) => {
            if (!!e.detail.redeemed) {
              this.fetchData();
            }
          }}"
        ></lxl-widget-list-card-benefit-detail>`,
        () => {
          this.showModal = false;
          this.cardDetailData = null;
        }
      )}
    </div>`;
  }

  /**
   * The function "hideButton" returns true if the status is either USED, BOOKED, EXPIRED, or FREEZED.
   * @param {BenefitStatus} status - The "status" parameter is of type "BenefitStatus".
   * @returns a boolean value.
   */
  private hideButton(status: BenefitStatus): boolean {
    return (
      status === BenefitStatus.USED ||
      status === BenefitStatus.BOOKED ||
      status === BenefitStatus.EXPIRED
    );
  }

  /**
   * The function "disableCard" checks if a given benefit status is one of the specified values and
   * returns a boolean indicating whether the card should be disabled.
   * @param {BenefitStatus} status - The `status` parameter is of type `BenefitStatus`.
   * @returns a boolean value.
   */
  private disableCard(status: BenefitStatus): boolean {
    return (
      status === BenefitStatus.USED ||
      status === BenefitStatus.BOOKED ||
      status === BenefitStatus.EXPIRED ||
      status === BenefitStatus.FREEZED
    );
  }

  private init() {
    this.benefitsDataTotal = this.data?.benefits?.length || 0;
    this.getNoItemFoundMessage(0);
  }

  /**
   * The `paginatorBenefitsClick` function updates the `benefitsData` array with a subset of
   * `benefitItems` based on the provided `startItemIndex` and `endItemIndex`.
   * @param {Object} {startItemIndex, endItemIndex} - The `startItemIndex` parameter represents the index of the first item in the range of
   * benefit items to be displayed. - The `endItemIndex` parameter represents the index of the last item in
   * the range of benefit items that you want to display.
   */

  private paginatorBenefitsClick({
    startItemIndex,
    endItemIndex,
  }: {
    startItemIndex: number;
    endItemIndex: number;
  }) {
    this.scrollItems();
    const status = this.getBenefitItemStatus(this.selectedFilterIndex);
    const benefitItems = this.getBenefitItems(status);

    this.benefitsData = [...benefitItems].slice(
      startItemIndex,
      endItemIndex + 1
    );
  }

  /**
   * The function filters and returns a list of benefit items based on their status.
   * @param {any} status - The `status` parameter is used to filter the benefit items based on their
   * status. It can have the following values:
   * @returns an array of items that match the given status.
   */
  private getBenefitItems(status: any) {
    const sortedData = this.sortedData();

    return sortedData.filter((item) =>
      status === "ALL"
        ? true
        : status === BenefitStatus.AVAILABLE
        ? item.status === BenefitStatus.AVAILABLE ||
          item.status === BenefitStatus.NEW
        : item.status === status
    );
  }

  /**
   * The function `selectFilterIndex` updates the selected filter index, recalculates the total items,
   * and reloads the paginator element if there are benefits data, otherwise it clears the benefits data.
   * @param {number} index - The `index` parameter is a number that represents the selected filter index.
   */
  private async selectFilterIndex(index: number) {
    this.selectedFilterIndex = index;

    this.getNoItemFoundMessage(index);

    this.recalculateTotalItems(index);

    if (this.benefitsDataTotal > 0) {
      await this.updateComplete;
      this.paginatorElement?.reload();
    } else {
      this.benefitsData = [];
    }
  }

  /**
   * The function `selectSortIndex` updates the selected sort index, recalculates the total items, and
   * reloads the paginator element if there are benefits data, otherwise it clears the benefits data.
   * @param {BenefitsSortItem} selectedItem - The `selectedItem` parameter is an object of type
   * `BenefitsSortItem`.
   */
  private async selectSortIndex(selectedItem: BenefitsSortItem) {
    this.selectedSortIndex = this.sortItems.findIndex(
      (el) => el.id === selectedItem.id
    );

    this.recalculateTotalItems(this.selectedFilterIndex);

    if (this.benefitsDataTotal > 0) {
      await this.updateComplete;
      this.paginatorElement?.reload();
    } else {
      this.benefitsData = [];
    }
  }

  /**
   * The function "recalculateTotalItems" updates the total number of benefit items based on their
   * status.
   * @param {number} index - The index parameter is a number that represents the position of an item in a
   * list or array. It is used to identify a specific item in order to perform some calculations or
   * operations on it.
   */
  private recalculateTotalItems(index: number) {
    const status = this.getBenefitItemStatus(index);

    this.benefitsDataTotal = this.getBenefitItems(status).length;
  }

  /**
   * The function `sortedData()` returns the sorted data based on the selected sort index.
   * @returns The function `sortedData()` is returning the result of the ternary operator. If
   * `this.selectedSortIndex` is equal to 0, it will call the `sortByDate()` function with the
   * `this.data.benefits` array and the string "NEWEST" as arguments. Otherwise, it will call
   * `sortByDate()` with the same array and the string "EXPIRATION" as
   */
  private sortedData() {
    return this.selectedSortIndex === 0
      ? this.sortByDate(this.data.benefits, "NEWEST")
      : this.sortByDate(this.data.benefits, "EXPIRATION");
  }

  /**
   * The `sortByDate` function sorts an array of `Benefit` objects by either their start date in
   * descending order or their end date in ascending order.
   * @param {Benefit[]} data - An array of Benefit objects.
   * @param {"NEWEST" | "EXPIRATION"} [type=NEWEST] - The `type` parameter is a string that specifies the
   * sorting type. It can have two possible values: "NEWEST" or "EXPIRATION".
   * @returns a sorted array of Benefit objects based on the specified sorting type.
   */
  private sortByDate(
    data: Benefit[],
    type: "NEWEST" | "EXPIRATION" = "NEWEST"
  ) {
    return [...data].sort((a, b) => {
      return type === "NEWEST"
        ? this.sortDesc(a.startDate, b.startDate)
        : this.sortAsc(a.endDate, b.endDate);
    });
  }

  private sortAsc(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  private sortDesc(a, b) {
    return a < b ? 1 : a > b ? -1 : 0;
  }

  private getBenefitItemStatus(index: number) {
    return this.filterItems[index].value;
  }

  private getBenefitItemLabel(index: number) {
    return this.filterItems[index].label;
  }

  /**
   * This function returns a string value representing the corresponding localization key for that
   * status
   * @param {BenefitStatus} status - BenefitStatus enum type, which represents the status of a benefit.
   * @returns a string value which is the key for the localized status of a benefit based on the input
   * status parameter.
   */
  private getLocalizeStatusKey(status: BenefitStatus) {
    let statusKey: string;
    switch (status) {
      case BenefitStatus.AVAILABLE:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_AVAILABLE";
        break;
      case BenefitStatus.BOOKED:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_BOOKED";
        break;
      case BenefitStatus.EXPIRED:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_EXPIRED";
        break;

      case BenefitStatus.FREEZED:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_FREEZED";
        break;
      case BenefitStatus.NEW:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_NEW";
        break;
      case BenefitStatus.USED:
        statusKey = "LXL_COMPONENTS_BADGE_STATUS_USED";
        break;
    }
    return statusKey;
  }

  /**
   * The function sets the "noItemsFoundMessage" property by localizing a message with the benefit item
   * status and page name.
   * @param {number} index - The index parameter is a number that represents the index of a benefit item.
   */
  private getNoItemFoundMessage(index: number) {
    const status: string = this.getBenefitItemStatus(index);
    const label: string = this.getBenefitItemLabel(index);
    const pageName = this.localize(
      "LXL_MYLOYALTY_MENU_REDEEM_BENEFITS"
    ).toLowerCase();

    this.noItemsFoundMessage = this.localize(
      "LXL_MYLOYALTY_NO_ITEMS_FOUND_MESSAGE",
      [status !== "ALL" ? label.toLowerCase() : "", pageName]
    ).replace(/\s+/g, " ");
  }
  /**
   * NOT USED: The scrollItems function scrolls the filterElement into view smoothly.
   */
  private scrollItems() {
    setTimeout(() => {
      this.filterElement?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-benefits": LxlPageBenefits;
  }
}

@customElement("lxl-component-benefit-modal-card-detail")
export class LxlComponentBenefitModalCardDetail extends LitElement {
  static styles = benefitModalStyles;

  @property({
    type: Object,
  })
  detailModalData: Benefit;

  @property()
  locale: string;

  @property()
  jwt: string;

  @property({ type: String, attribute: "promo-message" })
  promoMessage: string;

  @property({ type: String, attribute: "error-message" })
  errorMessage: string;

  @query("lxl-component-modal")
  modalElement: LitElement & { close: () => {} };

  @query(".lxl-component-benefit-modal-card-detail__slot2--error")
  errorHtml: HTMLDivElement;

  @state()
  showError: boolean = false;

  @query(".lxl-component-benefit-modal-card-detail") modalContent: HTMLElement;

  firstUpdated(): void {
      setTimeout(() => {
        this.modalContent.focus();
      }, 0)
  }

  errorIcon = html`<svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <rect y="-6.10352e-05" width="14" height="14" rx="7" fill="#990000" />
    <path d="M7 2.49988L7 8.50006" stroke="white" stroke-linecap="round" />
    <circle cx="7" cy="11.0001" r="0.5" fill="white" />
  </svg>`;

  render() {
    return html`
      <lxl-component-modal prevent-backdrop-close>
        <div class="lxl-component-benefit-modal-card-detail">
          <lxl-widget-list-card-detail
            list-card-detail-id="page-benefits"
            url-image="${this.detailModalData?.image}"
            category="${this.detailModalData?.category}"
            title="${this.detailModalData?.benefitName}"
            expires-date="${this.detailModalData?.endDate}"
            detail="${this.detailModalData?.benefitDescription}"
            locale="${this.locale}"
          >
            ${!!this.detailModalData?.promocodeRetail
              ? html`<div
                  class="lxl-component-benefit-modal-card-detail__slot1--promocode"
                  slot="content-slot"
                >
                  <lxl-component-barcode
                    class="lxl-component-benefit-modal-card-detail__slot1--promocode-barcode"
                    card-number="${this.detailModalData?.promocodeRetail}"
                    line-color="#222222"
                    background-color="#FFFFFF"
                    barcode-height="${getCssVar("--lxl-components-barcode-height")}"
                    barcode-width="${getCssVar("--lxl-components-barcode-width")}"
                    font-size="8"
                    background-padding="0px 0px"
                    background-radius="0px"
                    display-value="${false}"
                  ></lxl-component-barcode>
                  <div
                    class="lxl-component-benefit-modal-card-detail__slot1--promocode-message"
                  >
                    ${this.promoMessage}
                  </div>
                </div>`
              : nothing}
            ${!!this.detailModalData?.url
              ? html` <div
                  class="lxl-component-benefit-modal-card-detail__slot2"
                  slot="footer-slot"
                >
                  <div
                    class="lxl-component-benefit-modal-card-detail__slot2--button"
                  >
                    <lxl-component-button
                      @click="${(e: Event) => {
                        e.stopPropagation();

                        if (
                          this.detailModalData.redemptionRule ===
                          RedemptionRule.CUSTOMER_REQUEST
                        ) {
                          /*console.log("call");*/
                          this.redeem();
                        } else if (
                          this.detailModalData.redemptionRule ===
                          RedemptionRule.CLICK_ON_CTA
                        ) {
                          /*console.log("call and redirect");*/
                          this.redeem(this.detailModalData?.url);
                        } else {
                          /*console.log("redirect");*/
                          window.open(this.detailModalData?.url, "_blank");
                        }
                      }}"
                      title="${this.detailModalData?.ctaMyAccount}"
                    >
                      ${this.detailModalData?.ctaMyAccount}
                    </lxl-component-button>
                  </div>
                  ${this.showError
                    ? html`<div class="lxl-component-benefit-modal-card-detail__slot2--error">${this.errorIcon}<span >${this.errorMessage}</span></div>
              </div>`
                    : nothing}
                </div>`
              : nothing}
          </lxl-widget-list-card-detail>
        </div>
      </lxl-component-modal>
    `;
  }

  redeem(redirectUrl?: string) {
    store
      .dispatch(
        benefitsApi.endpoints.redeem.initiate({
          locale: this.locale,
          jwt: this.jwt,
          benefitCode: this.detailModalData.benefitCode,
        })
      )
      .then((res) => {
        if ("error" in res) {
          // console.error(res.error);
          this.showError = true;

          this.scrollErrorMessage();
        } else {
          this.dispatchEvent(
            new CustomEvent("lxlbenefitredeem", {
              detail: { redeemed: true },
              bubbles: true,
              composed: true,
            })
          );

          if (!!redirectUrl) {
            window.open(redirectUrl, "_blank");
          }

          this.modalElement.close();
        }
      });
  }

  private scrollErrorMessage() {
    setTimeout(() => {
      this.errorHtml?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-benefit-modal-card-detail": LxlComponentBenefitModalCardDetail;
  }
}

@customElement("lxl-page-benefits-skeleton")
export class LxlPageBenefitsSkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html`<div class="lxl-page-benefits-skeleton">
      <lxl-component-page-filter-skeleton></lxl-component-page-filter-skeleton>
      <div class="lxl-page-benefits-skeleton__list">
        ${map(
          Array.from(Array(3).keys()),
          () =>
            html`<lxl-widget-list-card-skeleton></lxl-widget-list-card-skeleton>`
        )}
      </div>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-benefits-skeleton": LxlPageBenefitsSkeleton;
  }
}
