import { LitElement, html, PropertyValues } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { styleMap } from 'lit/directives/style-map.js';
import styles from "./lxl-component-barcode.scss";
import * as JsBarcode from "jsbarcode";

@customElement("lxl-component-barcode")
export class LxlComponentBarcode extends LitElement {

  @query('.barcode')
  barcodeSvg: HTMLElement;

  @property({ type: String })
  'card-number': string;

  @property() 'line-color'= '#FFFFFF';
  @property({type: Number}) 'barcode-height';
  @property({type: Number}) 'barcode-width';
  @property({type: Number}) 'font-size';
  @property() 'background-color';
  @property() 'background-padding';
  @property() 'background-radius';
  @property() 'display-value': boolean = true;

  styles = {}

  static styles = styles;

  static shadowRootOptions = { ...LitElement.shadowRootOptions, mode: "open" as ShadowRootMode };
  updated(changedProp: PropertyValues<{ cardNumber: string }>) {

    if (this['card-number']) {
      JsBarcode.default(this.barcodeSvg)
        .options({
          background: this['background-color'] || 'transparent',
          lineColor: this['line-color'],
          height: this['barcode-height'],
          width: this['barcode-width'],
          displayValue: this['display-value']
        })
        .CODE128(this['card-number'], {
          fontSize: this['font-size'],
          textMargin: 2
        })
        .render();
    }
    super.updated(changedProp);
  }

  getBarcodeTemplate() {
    return this['card-number'] ? html`
      <div
        class="barcode-wrapper">
        <svg class="barcode">
        </svg>
      </div>` : html`<div></div>`
  }

  render() {
    this.styles = {
      backgroundColor: this['background-color'],
      borderRadius: this['background-radius'],
      padding: this['background-padding']
    }
    return this.getBarcodeTemplate();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'lxl-component-barcode': LxlComponentBarcode;
  }
}
