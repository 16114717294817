import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import styles from "./lxl-component-badge.scss";

@customElement("lxl-component-badge")
export class LxlComponentBadge extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property() type:
    | "default"
    | "available"
    | "booked"
    | "completed"
    | "expired"
    | "freezed"
    | "in-progress"
    | "new"
    | "used";

  render() {
    return html` <div class="lxl-component-badge ${this.type}">
      <slot></slot>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-badge": LxlComponentBadge;
  }
}
