import { LitElement, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import style from "./lxl-component-button.scss";

@customElement("lxl-component-button")
export class LxlComponentButton extends LitElement {
  @property({ type: Boolean, reflect: true }) disabled = false;

  @property({ type: String }) "aria-label": string;

  static styles = style;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html` <button
      tabindex="0"
      aria-label=${this["aria-label"] ?? nothing}
      ?disabled=${this.disabled}
      onclick="javascript:tealium_data2track.push({id: 'Click', data_element_id: 'LoyaltyWidget_CTA'});"
    >
      <slot></slot>
    </button>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-button": LxlComponentButton;
  }
}
