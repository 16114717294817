import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { store } from "lxl-data";
import { balanceApi, selectBalance } from "lxl-data/balance";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import { getCssVar } from "lxl-utils/lxl-css";
import skeletonStyles from "./lxl-widget-profile-skeleton.scss";
import styles from "./lxl-widget-profile.scss";

@customElement("lxl-widget-profile")
export class LxlWidgetProfile extends LxlConnectedElement {
  static styles = styles;
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  private __locale: string = "en";

  @property()
  public get locale(): string {
    return this.__locale;
  }

  public set locale(value: string) {
    const oldValue = this.locale;
    this.__locale = value;
    this.requestUpdate("locale", oldValue);
    if (!!this.jwt && !!this.__locale && oldValue) {
      this.fetchData();
    }
  }

  private __jwt: string;

  @property()
  public get jwt(): string {
    return this.__jwt;
  }

  public set jwt(value: string) {
    const oldValue = this.jwt;
    this.__jwt = value;
    this.requestUpdate("jwt", oldValue);
    if (!!this.__jwt && !!this.locale && oldValue) {
      this.fetchData();
    }
  }

  @property() id: string;

  @property() cta: string;

  @property({ type: Boolean })
  handleStatus: boolean;

  private _suspended: boolean;

  @property({ reflect: true, type: Boolean })
  public get suspended(): boolean {
    return this._suspended;
  }

  public set suspended(value: boolean) {
    const oldValue = this._suspended;
    this._suspended = value;
    this.requestUpdate();
    if (oldValue !== value) {
      this.fetchData();
    }
  }

  query = balanceApi.endpoints.getBalance;
  selector = selectBalance;

  fetchData() {
    store.dispatch(
      this.query.initiate({
        locale: this.locale,
        jwt: this.jwt,
        ...this.queryArgs,
      })
    );
  }

  stateChanged(state: any) {
    super.stateChanged(state);
  }

  buttonClick() {
    this.dispatchEvent(
      new CustomEvent("lxlprofilewidgetbuttonclick", {
        bubbles: true,
        composed: true,
        detail: {
          id: this.id || "lxl-widget-profile",
        },
      })
    );
  }

  unsubscribe() {
    store
      .dispatch(
        balanceApi.endpoints.unsubscribe.initiate({
          locale: this.locale,
          jwt: this.jwt,
        })
      )
      .then((res) => {
        if ("error" in res) {
          console.error(res.error);
        } else {
          if (this.handleStatus) {
            this.suspended = true;
          }

          this.dispatchEvent(
            new CustomEvent("lxlwidgetprofileunsubscribe", {
              bubbles: true,
              composed: true,
              detail: {
                id: this.id || "lxl-widget-profile",
              },
            })
          );
        }
      });
  }

  restore() {
    store
      .dispatch(
        balanceApi.endpoints.reactivate.initiate({
          locale: this.locale,
          jwt: this.jwt,
        })
      )
      .then((res) => {
        if ("error" in res) {
          console.error(res.error);
        } else {
          if (this.handleStatus) {
            this.suspended = false;
          }

          this.dispatchEvent(
            new CustomEvent("lxlwidgetprofilerestore", {
              bubbles: true,
              composed: true,
              detail: {
                id: this.id || "lxl-widget-profile",
              },
            })
          );
        }
      });
  }

  getTierBackgroundColor() {
    if (this.suspended) {
      return getCssVar(
        `--lxl-components-widget-profile-suspended-background-color`
      );
    }

    return getCssVar(
      `--lxl-components-widget-profile-tier-${this.data?.currentTier?.configuration?.tierCode}-background-color`,
      "--lxl-components-widget-profile-tier-1-background-color"
    );
  }

  getTierIcon() {
    return `${this.assetsUrl}/tier/${this.data?.currentTier?.configuration?.tierCode}/image.svg`;
  }

  renderActive() {
    return html` <div role="main" class="lxl-widget-profile-points-gauge">
        <lxl-component-points-gauge
          tierCode="${this.data?.currentTier?.configuration?.tierCode}"
          points="${this.data?.currentTier?.currentBalanceValue}"
          target-points="${this.data?.nextTierConfig
            ? this.data?.currentTier?.configuration?.maxThreshold
            : null}"
          min-points="${this.data?.currentTier?.configuration?.minThreshold}"
          points-label=${this.localize("LXL_COMPONENTS_POINTS_GAUGE")}
        ></lxl-component-points-gauge>
      </div>
      <div class="lxl-widget-profile-info">
        <div class="lxl-widget-profile-info-icon">
          <img src="${this.getTierIcon()}" alt="tier 1 badge icon" />
        </div>
        <div class="lxl-widget-profile-info-tier" aria-labelledby="tier-title" role="region">
          <h3 class="lxl-widget-profile-info-tier-title" id="tier-title">
            ${this.data?.currentTier?.configuration?.tierTitle}
          </h3>
          <div class="lxl-widget-profile-info-tier-subtitle">
            ${!!this.data?.nextTierConfig
              ? this.localize("LXL_WIDGET_PROFILE_SUBTITLE", [
                  (this.data?.currentTier?.configuration?.maxThreshold -
                    this.data?.currentTier?.currentBalanceValue +
                    1).toLocaleString(this.locale),
                  `${this.data.nextTierConfig?.tierCode} ${this.data.nextTierConfig?.tierName}`,
                ])
              : this.localize("LXL_WIDGET_PROFILE_SUBTITLE_LAST_TIER")}
          </div>
        </div>
      </div>
      <div class="lxl-widget-profile-action">
        <lxl-component-button
          class="lxl-widget-profile-action-button"
          @click="${this.buttonClick}"
        >
          ${this.cta ? this.cta : this.localize("LXL_WIDGET_PROFILE_CTA")}
        </lxl-component-button>
        <button
          tabindex="0"
          role="button"
          aria-label="${this.localize("LXL_ACCESSIBILITY_ARIA_LABEL_UNSUBSCRIBE_BUTTON")}"
          @click="${this.unsubscribe}"
          class="lxl-widget-profile-action-unsubscribe"
          onclick="javascript:tealium_data2track.push({id: 'Click', data_element_id: 'LoyaltyWidget_CTA'});"
        >
          ${this.localize("LXL_WIDGET_PROFILE_UNSUBSCRIBE_CTA")}
        </button>
      </div>
      <div class="lxl-widget-profile-footer-info" role="contentinfo">
        <div class="lxl-widget-profile-footer-info-icon">i</div>
        <span aria-label="${this.localize("LXL_WIDGET_PROFILE_INFO")}" class="lxl-widget-profile-footer-info-text"
          >${this.localize("LXL_WIDGET_PROFILE_INFO")}</span
        >
      </div>`;
  }

  renderSuspended() {
    return html`
      <div class="lxl-widget-profile-suspended-icon">
        <img
          src=${`${this.assetsUrl}/loyalty-logo.svg`}
          alt="loyalty program logo"
        />
      </div>
      <div class="lxl-widget-profile-suspended-title">
        <h3>${this.localize("LXL_WIDGET_PROFILE_INFO_SUSPENDED_TITLE")}</h3>
      </div>
      <div class="lxl-widget-profile-suspended-description">
        ${this.localize("LXL_WIDGET_PROFILE_INFO_SUSPENDED_DESCRIPTION")}
      </div>
      <div class="lxl-widget-profile-suspended-restore">
        <div class="lxl-widget-profile-suspended-restore-text">
          ${this.localize("LXL_WIDGET_PROFILE_INFO_SUSPENDED_RESTORE")}
        </div>
        <div class="lxl-widget-profile-suspended-restore-date">
          ${!!this.data.loyaltyProfile.reactivationDeadline
            ? new Date(
                this.data.loyaltyProfile.reactivationDeadline
              ).toLocaleDateString(this.locale, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                timeZone: "UTC"
              })
            : "-"}
        </div>
      </div>
      <div class="lxl-widget-profile-suspended-restore-cta">
        <lxl-component-button @click="${this.restore}">
          ${this.localize(
            "LXL_WIDGET_PROFILE_INFO_SUSPENDED_CTA"
          )}</lxl-component-button
        >
      </div>
    `;
  }

  lxlConnectedLoadingRender() {
    return this.suspended
      ? html`<lxl-component-skeleton height="240"></lxl-component-skeleton>`
      : html`<lxl-widget-profile-skeleton></lxl-widget-profile-skeleton>`;
  }

  lxlConnectedRender() {
    return html` <div
      style="background-color: ${this.getTierBackgroundColor()}"
      class="lxl-widget-profile ${this.suspended
        ? "lxl-widget-profile-suspended"
        : ""}"
    >
      ${this.suspended ? this.renderSuspended() : this.renderActive()}
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-profile": LxlWidgetProfile;
  }
}

@customElement("lxl-widget-profile-skeleton")
export class LxlWidgetProfileSkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html`
      <lxl-component-card>
        <div class="lxl-widget-profile">
          <div class="lxl-widget-profile-points-gauge">
            <lxl-component-skeleton round height="120"></lxl-component-skeleton>
          </div>
          <div class="lxl-widget-profile-info">
            <div class="lxl-widget-profile-info-tier">
              <lxl-component-skeleton height="14"></lxl-component-skeleton>
              <lxl-component-skeleton height="14"></lxl-component-skeleton>
            </div>
          </div>
          <div class="lxl-widget-profile-action">
            <lxl-component-skeleton button></lxl-component-skeleton>
          </div>
          <div class="lxl-widget-profile-footer-info">
            <lxl-component-skeleton height="14"></lxl-component-skeleton>
            <lxl-component-skeleton
              class="mobile-only"
              height="14"
            ></lxl-component-skeleton>
          </div>
        </div>
      </lxl-component-card>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-card-info-skeleton": LxlWidgetProfileSkeleton;
  }
}
