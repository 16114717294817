import { LitElement, html } from "lit";
import { customElement, property, query, queryAll, state } from "lit/decorators.js";
import { cardApi, selectCard, store } from "lxl-data";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import { lxlPortal } from "lxl-utils/lxl-portal";
import modalStyles from "./lxl-widget-card-info-modal.scss";
import skeletonStyles from "./lxl-widget-card-info-skeleton.scss";
import styles from "./lxl-widget-card-info.scss";

@customElement("lxl-widget-card-info")
export class LxlWidgetCardInfo extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @state() showModal: boolean;

  query = cardApi.endpoints.getCard;

  @query('#ariaLiveAnnouncer') ariaLiveAnnouncer: HTMLElement;

  selector = selectCard;

  private focusIndex = 0;

  @queryAll('[tabindex="0"]') focusableElements!: NodeListOf<HTMLElement>;

  firstUpdated() {
    this.shadowRoot!.addEventListener('keydown', this.handleArrowKeys.bind(this));
    this.updateFocus();

    const loyaltyCardElement = this.shadowRoot!.querySelector('.lxl-widget-card-info-barcode');
    if (loyaltyCardElement) {
    loyaltyCardElement.addEventListener('keydown', this.handleEnterKey.bind(this));
  }
  }

  handleEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.showModal = true;
      this.ariaLiveAnnouncer.textContent = this.localize('LXL_ACCESSIBILITY_MODAL_OPENED');
      this.requestUpdate();
      event.preventDefault();
    }
  }

  updateFocus() {
    if (this.focusableElements.length > 0 && this.focusIndex < this.focusableElements.length) {
      this.focusableElements[this.focusIndex].focus();
    }
  }

  handleArrowKeys(event: KeyboardEvent) {
    const maxIndex = this.focusableElements.length - 1;
    if (event.key === 'ArrowRight') {
      this.focusIndex = (this.focusIndex + 1 > maxIndex) ? 0 : this.focusIndex + 1;
      this.updateFocus();
      event.preventDefault();
    } else if (event.key === 'ArrowLeft') {
      this.focusIndex = (this.focusIndex - 1 < 0) ? maxIndex : this.focusIndex - 1;
      this.updateFocus();
      event.preventDefault();
    } else if (event.key === 'Enter' && this.focusableElements[this.focusIndex].classList.contains('lxl-widget-card-info-barcode')) {
      this.showModal = true;
      this.ariaLiveAnnouncer.textContent = this.localize('LXL_ACCESSIBILITY_MODAL_OPENED');
      this.requestUpdate();
      event.preventDefault();
    }
  }

  updated(changedProperties){
    if(changedProperties.has('showModal')){
      const ariaMessage = this.showModal ? this.localize('LXL_ACCESSIBILITY_MODAL_OPENED') : this.localize('LXL_ACCESSIBILITY_MODAL_CLOSED'); 
      this.ariaLiveAnnouncer.textContent = ariaMessage;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    store.dispatch(cardApi.endpoints.getGoogleWalletUrl.initiate({locale: this.locale, jwt: this.jwt}));
  }


  lxlConnectedLoadingRender() {
    return html`
      <lxl-widget-card-info-skeleton></lxl-widget-card-info-skeleton>
    `;
  }
  lxlConnectedRender() {
    return html`
      <lxl-component-card>
      <div id="ariaLiveAnnouncer" aria-live="assertive" style="position: absolute; width: 1px; height: 1px; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0);"></div>
        <div class="lxl-widget-card-info" role="region" aria-labelledby="card-info-title">
          <div class="lxl-widget-card-info-content">
            <div class="lxl-widget-card-info-content-title">
              <h3 id="card-info-title" aria-label="${this.localize("LXL_WIDGET_INFO_CARD_TITLE")}">${this.localize("LXL_WIDGET_INFO_CARD_TITLE")}</h3>
            </div>
            <div class="lxl-widget-card-info-content-joined">
              <p>${this.localize("LXL_WIDGET_INFO_CARD_JOINED")}</p>
              <p>
                <strong
                  >${this.data?.loyaltyCard?.cardActivationDate &&
                  new Date(
                    this.data?.loyaltyCard?.cardActivationDate
                  ).toLocaleDateString(this.locale)}</strong
                >
              </p>
            </div>
            <div class="lxl-widget-card-info-content-card-number">
              <p>${this.localize("LXL_WIDGET_INFO_CARD_NUMBER")}</p>
              <p><strong>${this.data?.loyaltyCard?.cardNumber}</strong></p>
            </div>
            <div class="lxl-widget-card-info-content-description">
              <p>${this.localize("LXL_WIDGET_INFO_CARD_DESCRIPTION")}</p>
            </div>
            <div class="lxl-widget-card-info-content-wallets">
              <div class="lxl-widget-card-info-content-wallets-google">
                <lxl-component-wallet-google
                  aria-label="${this.localize("LXL_ACCESSIBILITY_GOOGLE_WALLET")}"
                  class="wallet-google"
                  tabindex="0"
                  jwt="${this.jwt}"
                  locale="${this.locale}"
                  google-wallet-url=${this.data?.loyaltyCard?.googleWallet}
                  button-icon-url=${`${this.assetsGlobalUrl}/wallets/google/${this.locale || 'en'}_add_to_google_wallet_add-wallet-badge.svg`}
                >
                </lxl-component-wallet-google>
              </div>
              <div class="lxl-widget-card-info-content-wallets-apple">
                <lxl-component-wallet-apple
                  aria-label="${this.localize("LXL_ACCESSIBILITY_APPLE_WALLET")}"
                  class="wallet-apple"
                  tabindex="0"
                  locale="${this.locale}"
                  jwt="${this.jwt}"
                  card-url=${this.data?.loyaltyCard?.appleWallet}
                  button-icon-url=${`${this.assetsGlobalUrl}/wallets/apple/${(this.locale || "en").toUpperCase()}_Add_to_Apple_Wallet.svg`}
                >
                </lxl-component-wallet-apple>
              </div>
            </div>
          </div>
          <div
            tabindex="0"
            class="lxl-widget-card-info-barcode"
            @click="${() => (this.showModal = true)}"
            onclick="javascript:tealium_data2track.push({id: 'Click', data_element_id: 'LoyaltyWidget_Link', data_analytics_available_call:'0'});"
          >
            <lxl-component-loyalty-card
              cardimageurl=${`${this.assetsUrl}/loyalty-card-background.png`}
              card-number="${this.data?.loyaltyCard?.cardNumber}">
            </lxl-component-loyalty-card>
          </div>
        </div>
      </lxl-component-card>
      ${lxlPortal(
        this.showModal,
        html`
          <lxl-widget-card-info-modal
            tabindex="-1"
            title="${this.localize("LXL_WIDGET_INFO_CARD_TITLE")}"
            cardimageurl=${`${this.assetsUrl}/loyalty-card-background.png`}
            card-number="${this.data?.loyaltyCard?.cardNumber}"
            aria-label="${this.localize('LXL_WIDGET_INFO_MODAL')}"
          ></lxl-widget-card-info-modal>
        `,
        () => (this.showModal = false)
      )}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-card-info": LxlWidgetCardInfo;
  }
}

@customElement("lxl-widget-card-info-modal")
export class LxlWidgetCardInfoModal extends LitElement {
  static styles = modalStyles;

  @property() title;
  @property() "card-number";
  @property({type: String, attribute: 'cardimageurl'}) cardImageUrl;

  @query(".lxl-widget-card-info-barcode-modal") modalContent;
  

  render() {
    return html`
      <lxl-component-modal prevent-backdrop-close>
        <div class="lxl-widget-card-info-barcode-modal">
          <h3 tabindex="0">${this.title}</h3>
          <div class="lxl-widget-card-info-barcode-modal-card">
            <lxl-component-loyalty-card
              cardimageurl=${this.cardImageUrl}
              card-number="${this["card-number"]}"
            >
            </lxl-component-loyalty-card>
          </div>
        </div>
      </lxl-component-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-card-info-modal": LxlWidgetCardInfoModal;
  }
}

@customElement("lxl-widget-card-info-skeleton")
export class LxlWidgetCardInfoSkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html`
      <lxl-component-card>
        <div class="lxl-widget-card-info">
          <div class="lxl-widget-card-info-content">
            <div class="lxl-widget-card-info-content-points">
              <lxl-component-skeleton height="28"></lxl-component-skeleton>
              <lxl-component-skeleton height="14"></lxl-component-skeleton>
              <lxl-component-skeleton height="14"></lxl-component-skeleton>
            </div>
            <div class="lxl-widget-card-info-content-wallets">
              <div class="lxl-widget-card-info-content-wallets-google">
                <lxl-component-skeleton
                  button
                  height="40"
                ></lxl-component-skeleton>
              </div>
              <div class="lxl-widget-card-info-content-wallets-apple">
                <lxl-component-skeleton
                  button
                  height="40"
                ></lxl-component-skeleton>
              </div>
            </div>
          </div>
          <div class="lxl-widget-card-info-barcode">
            <lxl-component-skeleton></lxl-component-skeleton>
          </div>
        </div>
      </lxl-component-card>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-card-info-skeleton": LxlWidgetCardInfoSkeleton;
  }
}
