import { LitElement, html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { programApi, selectProgram } from "lxl-data/program";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import styles from "./lxl-widget-program-info.scss";

@customElement("lxl-widget-program-info")
export class LxlWidgetProgramInfo extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @state() selectedIndexTier = 0;

  query = programApi.endpoints.getProgram;
  selector = selectProgram;

  selectIndexTier(e: CustomEvent) {
    this.selectedIndexTier = e.detail.selectedIndex;
  }

  getTierPoints() {
    return this.data?.program?.tiers[this.selectedIndexTier];
  }

  lxlConnectedLoadingRender() {
    return html`<lxl-component-skeleton height="600"></lxl-component-skeleton>`;
  }

  lxlConnectedRender() {
    return html` <div class="lxl-widget-program-info" aria-labelledby="program information" role="region">
        <h4 aria-label="${this.localize("LXL_WIDGET_PROGRAM_INFO_TITLE")}">${this.localize("LXL_WIDGET_PROGRAM_INFO_TITLE")}</h4>
        <lxl-component-divider></lxl-component-divider>
        <div 
           class="lxl-widget-program-info-tiers-menu" 
           onclick="javascript:tealium_data2track.push({id: 'Click', data_element_id: 'LoyaltyProgram_TierTab', data_analytics_available_call: '1'});">
          <lxl-component-switch-menu
            @lxlswitchmenuclick="${(e) => this.selectIndexTier(e)}"
            .menuData="${this.data?.program?.tiers.map(
              (tier, index) => index + 1 + " " + tier.name
            )}"
            .menuId="tier-menu"
          ></lxl-component-switch-menu>
        </div>
        <div class="lxl-widget-program-info-tiers">
          <div class="lxl-widget-program-info-tiers-points">
            <div class="lxl-widget-program-info-tiers-points-icon">
              <img
                src="${this.data?.program?.tiers[this.selectedIndexTier].image}"
                alt=""
              />
            </div>
            <div class="lxl-widget-program-info-tiers-points-range">
              <h3>${this.getTierPoints()?.title}</h3>
              ${!!this.data?.program?.tiers
                ? html`<p>
                    ${this.selectedIndexTier ===
                    this.data?.program?.tiers?.length - 1
                      ? this.localize(
                          "LXL_WIDGET_PROGRAM_INFO_LAST_TIER_POINTS",
                          [this.getTierPoints()?.minThreshold.toLocaleString(this.locale)]
                        )
                      : this.localize("LXL_WIDGET_PROGRAM_INFO_TIER_POINTS", [
                          this.getTierPoints()?.minThreshold.toLocaleString(this.locale),
                          this.getTierPoints()?.maxThreshold.toLocaleString(this.locale),
                        ])}
                  </p>`
                : nothing}
            </div>
          </div>
          <lxl-component-divider></lxl-component-divider>
          <div class="lxl-widget-program-info-tiers-content">
            ${this.data?.program?.tiers
              .find((tier, i) => this.selectedIndexTier === i)
              ?.items.map(
                (element) =>
                  html`
                    <lxl-component-tier-info-card
                      iconUrl="${element.image}"
                      title="${element.title}"
                      content="${element.description}"
                    >
                    </lxl-component-tier-info-card>
                  `
              )}
          </div>
        </div>
      </div>
      <div role="region" aria-labelledby="program-info-secondary-title" class="lxl-widget-program-info-program">
        <h4 id="program-info-secondary-title">${this.localize("LXL_WIDGET_PROGRAM_INFO_SECONDARY_TITLE")}</h4>
        <lxl-component-divider></lxl-component-divider>
        <div class="lxl-widget-program-info-program-content">
          ${this.data?.program?.items.map(
            (item) =>
              html`
                <lxl-component-program-info-card
                  iconUrl="${item.image}"
                  title="${item.title}"
                  content="${item.description}"
                >
                </lxl-component-program-info-card>
              `
          )}
        </div>
      </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-widget-program-info": LxlWidgetProgramInfo;
  }
}
