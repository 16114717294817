import { LitElement, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import styles from "./lxl-component-load-more.scss";

@customElement("lxl-component-load-more")
export class LxlComponentLoadMore extends LxlConnectedElement {
  /* `static styles = styles;` is defining a static property called `styles` on the
 `LxlComponentLoadMore` class and assigning it the value of the imported `styles` object. This
 property is used to define the component's styles using CSS or SCSS. By setting it as a static
 property, the styles will be shared across all instances of the component and will not be
 re-evaluated for each instance. The `styles` object is imported from a separate SCSS file and
 contains the CSS rules for the component's template. */
  static styles = styles;

  /* `static shadowRootOptions` is a static property of the `LxlComponentLoadMore` class that sets the
  options for the shadow root of the component. It is using object destructuring to copy the default
  `shadowRootOptions` from the `LitElement` class and then setting the `mode` property to
  `"closed"`. This means that the component's shadow DOM will be closed, which prevents external
  JavaScript and CSS from accessing or modifying the component's internal DOM. This is a security
  feature that helps to prevent conflicts and maintain encapsulation between different parts of an
  application. */
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  /* `currentPage: number = 1;` is defining a property called `currentPage` with a type of `number`. It
  is initialized with a default value of `1`. This property is used to keep track of the current
  page number when the user clicks on the "load more" button and more items are loaded. By default,
  the `currentPage` property is set to `1`, which means that the first page of items will be loaded
  when the component is initialized. */
  @state()
  currentPage: number = 1;

  /* `progressPercentage: number = 0;` is defining a property called `progressPercentage` with a type
  of `number`. It is initialized with a default value of `0`. This property is used to keep track of
  the progress percentage of the loading process. It is updated when the user clicks on the "load
  more" button and more items are loaded. The `progressPercentage` property is also used in the
  component's template to display the progress bar. */
  @state()
  progressPercentage: number = 0;

  /* `loadedItems: number;` is defining a property called `loadedItems` with a type of `number`. This
  property is used to keep track of the number of items that have been loaded by the component. It
  is marked with the `@state` decorator, which means that changes to this property will trigger a
  re-render of the component. The initial value of `loadedItems` is `undefined`, but it will be
  updated when the user clicks on the "load more" button and more items are loaded. The
  `loadedItems` property is also used in the component's template to display the number of items
  that have been loaded. */
  @state()
  loadedItems: number;

  /* The `previousTotalItems` property is defining a variable of type `number` that is used to keep track
  of the previous value of the `totalItems` property. It is marked with the `@state` decorator, which
  means that changes to this property will trigger a re-render of the component. This property is used
  in the `render()` method to check if the `totalItems` property has been updated, and if so, it calls
  the `resetState()` method to reset the state of certain variables to their initial values. By
  keeping track of the previous value of `totalItems`, the component can detect when the total number
  of items has changed and update the UI accordingly. */
  @state()
  previousTotalItems: number;

  /* `loadMoreId: string;` is defining a property called `loadMoreId` with a type of `string`. This
  property is used to set an ID for the component's container element in the component's template.
  It is marked with the `@property` decorator, which means that changes to this property will be
  reflected in the corresponding attribute on the element. This allows the property to be set or
  updated via HTML markup or JavaScript. If the `load-more-id` attribute is set on the
  `lxl-component-load-more` element in the HTML markup, its value will be assigned to the
  `loadMoreId` property. If the `load-more-id` attribute is not set, the `loadMoreId` property will
  be `undefined`. */
  @property({ attribute: "load-more-id" })
  loadMoreId: string;

  /* `buttonLabel: string;` is defining a property called `buttonLabel` with a type of `string`. This
  property is used to set the label of the "load more" button in the component's template. It is
  marked with the `@property` decorator, which means that changes to this property will be reflected
  in the corresponding attribute on the element. This allows the property to be set or updated via
  HTML markup or JavaScript. */
  @property({ attribute: "button-label" })
  buttonLabel: string;

  /* `counterLabel: string;` is defining a property called `counterLabel` with a type of `string`. This
  property is used to set the label of the "counter" in the component's template with a ternary placeholder (Format %{0} %{1} %{2}). It is
  marked with the `@property` decorator, which means that changes to this property will be reflected
  in the corresponding attribute on the element. This allows the property to be set or updated via
  HTML markup or JavaScript. */
  @property({ attribute: "counter-label" })
  counterLabel: string;

  /* `totalItems: number = 0;` is defining a property called `totalItems` with a default value of `0`.
  This property is used to keep track of the total number of items that can be loaded by the
  component. It is also marked with the `reflect` decorator, which means that changes to this
  property will be reflected in the corresponding attribute on the element. This allows the property
  to be set or updated via HTML markup or JavaScript. */
  @property({ type: Number })
  totalItems: number = 0;

  /* `pageSize: number = 6;` is setting the default value of the `pageSize` property to `6`. This
  property determines the number of items to be loaded per page when the user clicks on the "load
  more" button. By default, it is set to `6`, but it can be overridden by setting the `pageSize`
  attribute on the `lxl-component-load-more` element in the HTML markup. */
  @property({ reflect: true, type: Number })
  pageSize: number = 6;

  /* `bubbles = false;` is setting the default value of the `bubbles` property to `false`. This
  property is used in the `_dispatchClickEvent()` method to determine whether the custom event
  should bubble up through the DOM or not. If `bubbles` is set to `true`, the event will bubble up
  and can be handled by other elements outside of the shadow DOM. If `bubbles` is set to `false`,
  the event will not bubble up and can only be handled by elements within the same shadow DOM. By
  default, the `bubbles` property is set to `false`, which means that the custom event will not
  bubble up through the DOM. */
  @property()
  bubbles = false;

  /**
   * The connectedCallback function initializes the component,loads a dictionary and dispatches a click event.
   */
  connectedCallback() {
    super.connectedCallback();
    this.loadDictionary({
      LXL_COMPONENTS_LOAD_MORE_VIEWING: this.counterLabel,
    });
    this.init();
  }

  render() {
    return html` <div
      class="lxl-component-load-more"
      id="lxl-component-load-more-${this.loadMoreId ?? nothing}"
    >
      <div class="lxl-component-load-more__counter">
        ${this.localize("LXL_COMPONENTS_LOAD_MORE_VIEWING", [
          this.totalItems > 0 ? "1" : "0",
          this.loadedItems,
          this.totalItems,
        ])}
      </div>
      <div class="lxl-component-load-more__progress-bar">
        <span style="width: ${this.progressPercentage + "%"}"></span>
      </div>
      <lxl-component-button
        style="${this.loadedItems === this.totalItems
          ? "opacity: 0.6;"
          : nothing}"
        class="lxl-component-load-more__button menu active"
        @click="${(e) => this.loadMore(e)}"
        >${this.buttonLabel}</lxl-component-button
      >
    </div>`;
  }
  lxlConnectedLoadingRender() {}
  /*  This method is responsible for resetting the state of the class instance 
   and then initializing it again. */
  public reload() {
    this.resetState();
    this.init();
  }

  /* The `resetState()` method is a private method of the `LxlComponentLoadMore` class that
  resets the state of certain variables to their initial values. Specifically, it sets the
  `currentPage` property to `1`, the `progressPercentage` property to `0`, and the
  `loadedItems` property to `0`. This method is called when the `reset` property is
  updated, which indicates that the component needs to be re-initialized with the new total
  number of items. By resetting the state of these variables, the component can start fresh
  with the new data and update the UI accordingly. */
  private resetState() {
    this.currentPage = 1;
    this.progressPercentage = 0;
    this.loadedItems = 0;
  }

  /**  The `init()` function is a private method of the `LxlComponentLoadMore` class that
   * initializes the progress percentage and loaded items based on the current page and total
   * pages. It calculates the total number of pages based on the total items and page size, and
   * then sets the progress percentage based on the current page and total pages. Finally, it
   * sets the number of loaded items to the page size and dispatches a click event.
   * This function is called when the component is connected to the DOM.
   */
  private init() {
    const totalPages = this.getTotalPages();
    this.previousTotalItems = this.totalItems;

    this.loadedItems =
      totalPages === 1 ? this.totalItems : totalPages === 0 ? 0 : this.pageSize;

    this.progressPercentage = this.getProgressPercentage(
      this.loadedItems,
      this.totalItems
    );
    this._dispatchClickEvent();
  }

  /**
   * The `loadMore` function is a private method of the `LxlComponentLoadMore` class that is
   * called when the user clicks on the "load more" button. This function loads more items and updates the progress percentage and loaded items accordingly.
   * @param {Event} e - The parameter "e" is an event object that is passed to the function. It is used
   * to prevent the default behavior of an event, such as a form submission or a link click. In this
   * case, it is used to prevent the default behavior of a click event on a "load more"
   */
  private loadMore(e: Event) {
    e.preventDefault;
    if (this.loadedItems === this.totalItems) {
      return;
    }

    const totalPages = this.getTotalPages();

    this.currentPage++;

    if (this.currentPage > totalPages) {
      this.currentPage = totalPages;
    } else {
      this.loadedItems =
        this.currentPage === totalPages
          ? this.totalItems
          : this.currentPage * this.pageSize;

      this.progressPercentage = this.getProgressPercentage(
        this.loadedItems,
        this.totalItems
      );
    }

    this._dispatchClickEvent();
  }

  /* The `getTotalPages()` function is a private method of the `LxlComponentLoadMore` class
  that calculates the total number of pages based on the total number of items and the page
  size. It uses the `Math.ceil()` method to round up the result of dividing the total number
  of items by the page size to the nearest integer, which represents the total number of
  pages needed to display all the items. The function then returns this value. */
  private getTotalPages() {
    return !!this.pageSize ? Math.ceil(this.totalItems / this.pageSize) : 0;
  }

  /* The `getProgressPercentage()` function is a private method of the `LxlComponentLoadMore`
  class that calculates the progress percentage based on the current page and the total
  number of pages. It takes two parameters: `currentPage`, which is the current page number,
  and `totalPages`, which is the total number of pages. It then calculates the progress
  percentage by multiplying the current page number by 100 and dividing the result by the
  total number of pages. The function returns the progress percentage as a number. */
  private getProgressPercentage(
    loadedItems: number,
    totalItems: number
  ): number {
    return !!totalItems ? (loadedItems * 100) / totalItems : 0;
  }

  /* The `_dispatchClickEvent()` method is a private method of the `LxlComponentLoadMore` class
  that dispatches a custom event called "lxlloadmoreclick" with a `detail` object containing
  the number of loaded items. This event can be listened to by other components or scripts
  that use this component, allowing them to perform additional actions or update their own
  state based on the number of loaded items. The `bubbles` and `composed` properties of the
  event are set to `true`, which means that the event will bubble up through the DOM and can
  be handled by other elements outside of the shadow DOM. */
  private _dispatchClickEvent() {
    this.dispatchEvent(
      new CustomEvent("lxlloadmoreclick", {
        detail: {
          loadedItems: this.loadedItems,
        },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-load-more": LxlComponentLoadMore;
  }
}
