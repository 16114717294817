import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import styles from './lxl-component-divider.scss';

@customElement('lxl-component-divider')
export class LxlComponentDivider extends LitElement {
    static styles = styles;

    static shadowRootOptions = {...LitElement.shadowRootOptions, mode: 'closed' as ShadowRootMode};

    render() {
        return html`
            <div class="lxl-component-divider">
                <hr />
            </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'lxl-component-divider': LxlComponentDivider;
}
}
