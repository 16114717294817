import { LitElement, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { repeat } from "lit/directives/repeat.js";
import styles from "./lxl-component-sort.scss";
export class SortItem {
  id: string;
  label: string;
  value?: string;
}
@customElement("lxl-component-sort")
export class LxlComponentSort extends LitElement {
  /* `static styles = styles;` is defining a static property `styles` on the `LxlComponentSort` class
 and assigning it the value of the imported `styles` object. This property is used to define the
 component's styles using CSS or SCSS. The `static` keyword is used to define a property or method
 that belongs to the class itself, rather than to instances of the class. This means that the
 `styles` property can be accessed and used without creating an instance of the `LxlComponentSort`
 class. */
  static styles = styles;

  /* `static shadowRootOptions` is a static property of the `LxlComponentSort` class that sets options
  for the shadow root of the component. The `...LitElement.shadowRootOptions` syntax is used to
  inherit the default options for the shadow root from the `LitElement` class. The `mode` option is
  set to `"closed"` to prevent external access to the shadow root. This means that the component's
  internal structure cannot be accessed or modified from outside the component. */
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  /* `sortId: string = "id";` is defining a property `sortId` with a default value of `"id"`. The
  `@property` decorator is used to define a property that can be used to set values from outside the
  component. The `type` option specifies the data type of the property, and the `attribute` option
  specifies the name of the HTML attribute that can be used to set the property value. In this case,
  `sortId` is a string property that can be set using the `sort-id` attribute in HTML. If the
  `sort-id` attribute is not provided, the default value of `"id"` will be used. */
  @property({ type: String, attribute: "sort-id" })
  sortId: string;

  /* `sortText: string;` is defining a property `sortText` of type string. This property can be
  used to set the text that appears in the component's controller element, which is the element that
  triggers the display of the component's content. The `@property` decorator is used to define this
  property, which can be set using the `sort-text` attribute in HTML. */
  @property({ type: String, attribute: "sort-text" })
  sortText: string;

  /* `sortData` is a property of the `LxlComponentSort` class that is an array of `SortItem`
  objects. The `Array<SortItem>` syntax specifies that the array can only contain `SortItem`
  objects. The `= [];` part initializes the array with an empty array as the default value. This
  property can be used to pass an array of filter options to the component, which will be rendered
  as radio buttons in the component's content area. */
  @property({
    type: Array<SortItem>
  })
  sortData: Array<SortItem> = [];

  /* `selectedItemIndex` is a property of the `LxlComponentSort` class that is initialized with a default
  value of `0`. This property is used to keep track of the index of the currently selected item in the
  `sortData` array. It is set to `0` by default, which means that the first item in the array will be
  selected by default. The `@property()` decorator is used to define this property, which can be set
  using the `selected-item-index` attribute in HTML. The `type` option specifies the data type of the
  property, which is `number` in this case. The `reflect` option is set to `true`, which means that
  changes to this property will be reflected in the corresponding HTML attribute. */
  @property({ reflect: true, type: Number })
  selectedItemIndex: number = 0;

  /* `bubbles = false;` is defining a property `bubbles` with a default value of `false`. This property
  can be used to control whether events dispatched by the component should bubble up the DOM tree or
  not. If `bubbles` is set to `true`, events dispatched by the component will bubble up the DOM tree
  and can be handled by parent elements. If `bubbles` is set to `false`, events will not bubble up
  and can only be handled by the component itself or its shadow DOM children. By default, `bubbles`
  is set to `false`, which means that events dispatched by the component will not bubble up the DOM
  tree. */
  @property()
  bubbles = false;

  /* `isPopoverOpen: boolean = false;` is defining a property `isPopoverOpen` of type boolean with a
  default value of `false`. This property is used to keep track of whether the component's popover
  content is currently open or closed. The `@state()` decorator is used to mark this property as a
  state property, which means that changes to this property will trigger a re-render of the
  component. */
  @state()
  isPopoverOpen: boolean = false;

  /* `defaultArrowIcon` is a property of the `LxlComponentSort` class that is initialized with an
  SVG icon as a default value. The icon is defined using the `html` template tag, which
  allows HTML code to be defined as a string and then rendered as HTML in the component's
  template. The SVG icon is defined using SVG markup and includes a path element that
  defines the shape of the icon. This property is used to render the default icon in the
  component's controller element, which is the element that triggers the display of the
  component's content. If a different icon is not provided, the default icon will be used. */
  private defaultArrowIcon = html`<svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <g clip-path="url(#clip0_9061_12338)">
      <path
        d="M16.4023 4.40236L15.931 3.93103L8.49999 11.362L1.06899 3.93103L0.597656 4.40236L8.49999 12.3047L16.4023 4.40236Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_9061_12338">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>`;

  private doubleArrowIcon = html`<svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M6.49999 7.99998V1.47131L10.2643 5.23565L10.7357 4.76431L6.16666 0.195312L1.59766 4.76431L2.06899 5.23565L5.83332 1.47131V7.99998H6.49999Z"
      fill="black"
    />
    <path
      d="M14.931 10.7643L11.1667 14.5286V7.99998H10.5V14.5286L6.73566 10.7643L6.26432 11.2356L10.8333 15.8046L15.4023 11.2356L14.931 10.7643Z"
      fill="black"
    />
  </svg>`;

  render() {
    return html` <div
      class="lxl-component-sort"
      id="lxl-component-sort-${!!this.sortId ? this.sortId : nothing}"
      ?open=${this.isPopoverOpen}
    >
      <lxl-component-popover
        position="bottom left"
        @lxlpopoverchange="${(e: CustomEvent) => {
          this.isPopoverOpen = e.detail.open;
        }}"
      >
        <div class="lxl-component-sort-controller" slot="controller" tabindex="0" @keydown="${this.handleKeyDown}">
          <span name="icon" class="lxl-component-sort-controller__left-icon">
            ${this.doubleArrowIcon}
          </span>
          <div class="lxl-component-sort-controller__text">
            ${this.sortText}
          </div>
          <span name="icon" class="lxl-component-sort-controller__right-icon">
            ${this.defaultArrowIcon}
          </span>
        </div>
        <div class="lxl-component-sort-content" slot="content">
          ${choose(!!this.sortData && this.sortData.length > 0, [
            [true, () => html`${this.renderItem()}`],
            [false, () => html``],
          ])}
        </div>
      </lxl-component-popover>
    </div>`;
  }

  private renderItem() {
    return html`${repeat(
      this.sortData,
      (item: SortItem) => item.id,
      (item, index) => html`<div class="row" tabindex="0" @keydown="${this.handleItemKeyDown}">
        <input
          type="radio"
          id="lxl-component-sort-content-item-${index}"
          name="sort-item"
          @click=${(e: Event) => this._dispatchCheckedItem(e, item)}
          ?checked=${index === this.selectedItemIndex || nothing}
        />
        <label for="lxl-component-sort-content-item-${index}"
          >${item.label}</label
        >
      </div>`
    )}`;
  }

  private handleKeyDown(e: KeyboardEvent) {
    if(e.key === 'Enter' || e.key === ' '){
      e.preventDefault();
      this.isPopoverOpen = !this.isPopoverOpen;
      this._dispatchStatus(this.isPopoverOpen);
    } 
  }

  private handleItemKeyDown(e: KeyboardEvent) {
    const items = this.shadowRoot.querySelectorAll('.row');
    let newIndex = this.selectedItemIndex;

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();

      if (e.key === 'ArrowDown') {
        newIndex = (this.selectedItemIndex + 1) % items.length;
      } else if (e.key === 'ArrowUp') {
        newIndex = (this.selectedItemIndex - 1 + items.length) % items.length;
      }

      this.selectedItemIndex = newIndex;
      (items[newIndex] as HTMLElement).querySelector('input')?.click();
      (items[newIndex] as HTMLElement).focus();
    }
  }

  private _dispatchStatus(value: boolean) {
    this.dispatchEvent(
      new CustomEvent("lxlpopoverchange", {
        detail: { open: value },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }


  /* The `_dispatchCheckedItem` method is dispatching a custom event named "lxlsortcheck" with
  a `CustomEvent` object. The `CustomEvent` object has a `detail` property that contains an
  object with two properties: `checkedItem` and `sortId`. The `checkedItem` property is set
  to the `SortItem` object that was clicked on by the user, and the `sortId` property is set
  to the value of the `sortId` property of the component. The `bubbles` property of the
  `CustomEvent` object is set to `false`, which means that the event will not bubble up the
  DOM tree, and the `composed` property is set to `true`, which means that the event can
  pass through the shadow DOM boundary. This method is called when a radio button in the
  component's content area is clicked, and it dispatches the "lxlsortcheck" event with the
  selected `SortItem` object and the `sortId` value. */
  private _dispatchCheckedItem(e: Event, item: SortItem) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent("lxlsortcheck", {
        detail: { checkedItem: item, sortId: this.sortId },
        bubbles: false,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-sort": LxlComponentSort;
  }
}
