import { LitElement, html, nothing } from "lit";
import { customElement, property, queryAll, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { map } from "lit/directives/map.js";
import styles from "./lxl-component-accordion.scss";
export class AccordionItem {
  title: string;
  content: string;
  note?: string;
}

@customElement("lxl-component-accordion")
export class LxlComponentAccordion extends LitElement {
  /* `static styles = styles;` is defining the component's styles using the `styles` variable imported
  from a separate SCSS file. This allows the component to have its own encapsulated styles that
  won't affect other parts of the application. */
  static styles = styles;

  /* `static shadowRootOptions` is a static property of the `LxlComponentAccordion` class that sets the
  options for the shadow root of the component. It uses the `LitElement.shadowRootOptions` object as
  a base and adds the `mode` property with a value of `"closed"`. This sets the mode of the shadow
  root to "closed", which means that the component's internal DOM is not accessible from outside the
  component. This helps to encapsulate the component's styles and behavior, preventing them from
  affecting other parts of the application and vice versa. */
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  /* `accordionId: string = "id";` is defining a property called `accordionId` on the
  `LxlComponentAccordion` class. The property is of type `string`. 
  The default value is `"id"` for the `accordionId` property. 
   */
  @property({ type: String, attribute: "accordion-id" })
  accordionId: string;

  /* `open: boolean = false;` is defining a property called `open` on the `LxlComponentAccordion` class.
  The property is of type `boolean`. The default value is `false` for the `open` property. This
  property is used to determine whether the accordion is open or closed after render. If `open` is `true`, the
  accordion is open, and if `open` is `false`, the accordion is closed. */
  @property({ type: Boolean })
  open: boolean = false;

  /* `inputList: Array<HTMLInputElement>;` is defining a property called `inputList` on the
  `LxlComponentAccordion` class. The property is an array of `HTMLInputElement` elements. It is
  decorated with the `@queryAll("input")` decorator, which queries the component's shadow DOM for
  all `input` elements and assigns them to the `inputList` property. This allows the component to
  access and manipulate the `input` elements within its shadow DOM. */
  @queryAll("input")
  inputList: Array<HTMLInputElement>;

  /* `accordionData` is a property of the `LxlComponentAccordion` class that is initialized as an empty
  array of `AccordionItem` objects. This property is used to store the data for each accordion item
  that will be rendered by the component. The `AccordionItem` interface defines the shape of the
  data that each item should have, including a `title` string, an optional `icon` string, and a
  `content` string. By initializing `accordionData` as an empty array, the component can be used
  without any accordion items initially, and items can be added dynamically later by updating the
  `accordionData` property with an array of `AccordionItem` objects. */
  @property({
    type: Array<AccordionItem>,
  })
  accordionData: Array<AccordionItem> = [];

  /* `multiselect: boolean = false;` is defining a property called `multiselect` on the
  `LxlComponentAccordion` class. The property is of type `boolean`. The default value is `false` for
  the `multiselect` property. This property is used to determine whether multiple accordion items
  can be open at the same time or not. If `multiselect` is `true`, multiple accordion items can be
  open at the same time, and if `multiselect` is `false`, only one accordion item can be open at a
  time. */
  @property({
    type: Boolean,
  })
  multiselect: boolean = false;

  /* `selectedLastItemIndex: Number | null = null;` is defining a property called
  `selectedLastItemIndex` on the `LxlComponentAccordion` class. The property is of type `Number` or
  `null`. The default value is `null` for the `selectedLastItemIndex` property. This property is
  used to keep track of the index of the last selected accordion item when `multiselect` is `false`.
  If `multiselect` is `true`, multiple accordion items can be open at the same time, so there is no
  need to keep track of the last selected item. However, if `multiselect` is `false`, only one
  accordion item can be open at a time, so the `selectedLastItemIndex` property is used to keep
  track of the index of the last selected item. */
  @state()
  selectedLastItemIndex: Number | null = null;

  /* `defaultIcon` is a property of the `LxlComponentAccordion` class that is initialized with
  an SVG icon as a default value. This icon is used as a fallback in case an accordion item
  does not have an icon specified in its data. The SVG icon is defined using the `html`
  template tag from LitElement, which allows HTML code to be embedded in a JavaScript
  template string. The SVG icon is defined using the SVG syntax and contains a path element
  that defines the shape of the icon. */
  private defaultIcon = html`<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M19 9l-7 7-7-7"
    />
  </svg>`;

  /* The `render()` method is defining the HTML structure of the `LxlComponentAccordion`
  custom element. It returns a `div` element with a class of `lxl-component-accordion` and
  an `id` attribute that is set to `lxl-widget-accordion-list-${this.accordionId}`. Inside
  the `div` element, the `map()` directive is used to iterate over the `accordionData`
  array and render an `LxlComponentAccordionItem` for each item in the array. The
  `renderItem()` method is called for each item, passing in the item's `title`, `content`,
   `index`, and `inputType` properties. The `inputType` property is set to
  `"checkbox"` if `multiselect` is `true`, and `"radio"` if `multiselect` is `false`. The
  `renderItem()` method returns an HTML structure for each item, which is then included in
  the `div` element using the `${}` syntax. */
  protected render() {
    return html` <div
      class="lxl-component-accordion"
      id="lxl-widget-accordion-${!!this.accordionId
        ? this.accordionId
        : nothing}"
      role="tablist"
    >
      ${map(
        this.accordionData,
        (value, index) => html`
          ${this.renderItem(
            value.title,
            value.content,
            value.note,
            index,
            this.multiselect ? "checkbox" : "radio"
          )}
        `
      )}
    </div>`;
  }

  /**
   * The function checks if the "open" or "multiselect" properties have changed and initializes a
   * behavior accordingly.
   * @param changedProps - A Map object that contains the properties that have been updated and their new
   * values. This method is called whenever a property of the component is updated. The "open" and
   * "multiselect" properties are checked to determine if the component's behavior needs to be
   * re-initialized.
   */
  protected updated(changedProps: Map<any, any>) {
    if (changedProps.has("open") || changedProps.has("multiselect")) {
      this.initBehaviour();
    }
  }

  /* `initBehaviour()` is a private method of the `LxlComponentAccordion` class that
  initializes the behavior of the accordion based on the `open` and `multiselect`
  properties. It checks if the `inputList` property is not null and has a length greater
  than 0. If `multiselect` is `true`, it calls the `setAllItems()` method with the value of
  `open` to set the `checked` property of all input elements to the same value. If
  `multiselect` is `false`, it sets the `checked` property of the first input element in the
  `inputList` array to the value of `open` and sets the `selectedLastItemIndex` property to
  either `0` if `open` is `true`, or `null` if `open` is `false`. This method is called
  whenever the `open` or `multiselect` properties are updated to ensure that the accordion's
  behavior is consistent with the updated properties. */
  private initBehaviour() {
    if (!!this.inputList && this.inputList.length > 0) {
      if (this.multiselect) {
        this.setAllItems(this.open);
      } else {
        this.inputList[0].checked = this.open;
        this.selectedLastItemIndex = this.open ? 0 : null;
      }
    }
  }

  /* `renderItem()` is a private method of the `LxlComponentAccordion` class that is used to
  render an individual accordion item. It takes in five parameters: `title`, `content`,
   `index`, and `inputType`. */
  private renderItem(
    title: string,
    content: string,
    note: string,
    index: number | string,
    inputType: "checkbox" | "radio"
  ) {
    return html`<div class="lxl-component-accordion-item" role="tabpanel" tabindex="0" @keydown="${(e: KeyboardEvent) => this.handleKeyDown(e, index)}">
      ${choose(inputType, [
        [
          "checkbox",
          () => html` <input
            type="checkbox"
            id="lxl-component-accordion-item-input-${index}"
            role="tab"
            aria-controls="lxl-component-accordion-panel-${index}"
            @click=${(e: Event) => this.inputChecked(e, index)}
          />`,
        ],
        [
          "radio",
          () => html` <input
            type="radio"
            id="lxl-component-accordion-item-input-${index}"
            name="accordion-item"
            @click=${(e: Event) => this.inputChecked(e, index)}
            role="tab"
            aria-controls="lxl-component-accordion-panel-${index}"
          />`,
        ],
      ])}

      <label
        class="lxl-component-accordion-item__label"
        for="lxl-component-accordion-item-input-${index}"
      >
        <span name="title">${title}</span>

        <span name="icon" class="lxl-component-accordion-item__label-icon">
          ${this.defaultIcon}
        </span>
      </label>
      <div class="lxl-component-accordion-item__content" role="region">
        <div name="content">${content}</div>
        ${!!note ? html`<div name="note">${note}</div>` : nothing}
      </div>
    </div>`;
  }

  private handleKeyDown(e: KeyboardEvent, index: number | string){
    if(e.key === 'Enter'){
      const input = this.shadowRoot.getElementById(`lxl-component-accordion-item-input-${index}`) as HTMLInputElement
      if(input){
        input.click();
      }
    }
  }

  /* The `inputChecked` method is a private method of the `LxlComponentAccordion` class that is
  called when an accordion item's input element is clicked. It takes in two parameters: `e`,
  which is the event object, and `index`, which is the index of the clicked item. */
  private inputChecked(e: Event, index) {
    e.stopPropagation();
    if (!this.multiselect && this.selectedLastItemIndex === index) {
      this.setAllItems(false);
      this.selectedLastItemIndex = null;
    } else {
      this.selectedLastItemIndex = index;
    }
  }

  /* The `setAllItems` method is a private method of the `LxlComponentAccordion` class that is
  used to set the `checked` property of all input elements in the `inputList` array to a
  specified boolean value. It takes in one parameter, `value`, which is a boolean value that
  determines whether the input elements should be checked or unchecked. */
  private setAllItems(value: boolean) {
    this.inputList.forEach((v, i, el) => {
      el[i].checked = value;
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-accordion": LxlComponentAccordion;
  }
}
