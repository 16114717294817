import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { configApi } from "lxl-data";
import styles from "./lxl-component-error.scss";

@customElement("lxl-component-error")
export class LxlComponentError extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property()
  "lxl-error-title": string;

  @property()
  "lxl-error-subtitle": string;

  @property()
  "lxl-error-action": string;

  query = configApi.endpoints.getConfig;

  retry() {
    this.dispatchEvent(
      new CustomEvent("lxlcomponenterrorretry", {
        bubbles: false,
        composed: true,
      })
    );
  }

  render() {
    return html` <lxl-component-card>
      <div class="lxl-component-error">
        <div class="lxl-component-error-text">
          <h2 class="lxl-component-error-text-title">
            ${this["lxl-error-title"]}
          </h2>
          <span class="lxl-component-error-text-subtitle"
            >${this["lxl-error-subtitle"]}</span
          >
        </div>
        <div class="lxl-component-error-button-retry">
          <lxl-component-button @click="${() => this.retry()}">
            <div class="lxl-component-error-button-retry--content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="16px"
                height="16px"
                preserveAspectRatio="xMidYMid meet"
                fill="none"
                viewBox="0 0 256 256"
                enable-background="new 0 0 256 256"
              >
                <g>
                  <path
                    fill="#ffffff"
                    data-title="Layer 0"
                    xs="0"
                    d="M128,209.7c-28.2,0-53.1-14.3-67.7-36H39.2c16.6,32.2,50.1,54.2,88.8,54.2c52.1,0,94.9-39.9,99.4-90.8H246l-27.2-36.3l-27.2,36.3h17.7C204.7,177.9,170,209.7,128,209.7z"
                  />
                  <path
                    fill="#ffffff"
                    data-title="Layer 1"
                    xs="1"
                    d="M128,28.2c-48.9,0-89.7,35.2-98.2,81.7H10l27.2,36.3l27.2-36.3H48.3c8.2-36.4,40.8-63.5,79.7-63.5c24.6,0,46.6,10.8,61.6,28l3.5-0.9h18.5C193.8,46.2,163,28.2,128,28.2z"
                  />
                </g>
              </svg>
              <span class="lxl-component-error-button-retry-text">
                ${this["lxl-error-action"]}
              </span>
            </div>
          </lxl-component-button>

          <!--   <lxl-component-button @click="${() => this.retry()}">
            <svg
              class="lxl-component-error-button-retry-icon"
              width="100%"
              height="100%"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="140" clip-path="url(#clip0_3767_329363)">
                <path
                  id="Vector"
                  d="M9.49986 0.536813C7.00334 0.536813 4.64694 1.49908 2.8645 3.2459C2.59573 3.50912 2.34398 3.78937 2.10807 4.08269V0.5H1.3164V5.65494H6.47134V4.86327H2.50509C2.78138 4.48921 3.08617 4.13613 3.41827 3.81075C5.05227 2.21 7.21194 1.32848 9.49986 1.32848C14.2914 1.32848 18.19 5.22704 18.19 10.0186C18.19 14.8102 14.2914 18.7083 9.49986 18.7083C4.7083 18.7083 0.809733 14.8102 0.809733 10.0186H0.0180664C0.0180664 15.2468 4.27169 19.5 9.49986 19.5C14.728 19.5 18.9816 15.2468 18.9816 10.0186C18.9816 4.79044 14.728 0.536813 9.49986 0.536813Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_3767_329363">
                  <rect width="100%" height="100%" />
                </clipPath>
              </defs>
            </svg>
            <span class="lxl-component-error-button-retry-text">

              ${this["lxl-error-action"]}
            </span>
          </lxl-component-button> -->
        </div>
      </div>
    </lxl-component-card>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-error": LxlComponentError;
  }
}
