import { LitElement, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { getCssVar } from "lxl-utils/lxl-css";
import styles from "./lxl-component-points-gauge.scss";

@customElement("lxl-component-points-gauge")
export class LxlComponentPointsGauge extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };
  @property()
  locale = "en";

  @property({ reflect: true, type: Number }) points: number;

  @property({ reflect: true, type: Number }) "target-points": number;

  @property({ reflect: true, type: Number }) "min-points" = 0;

  @property()
  tierCode = 1;

  @property()
  secondary: boolean | undefined;

  @property({ reflect: true, type: String, attribute: "points-label" })
  pointsLabel: string;

  /*  renderPointsCap() {
    if (this["target-points"]) {
      return html`/${this["target-points"]}`;
    }

    return nothing;
  }

  getPointsPercentage(factor: number): number {
    if (this["target-points"] > 0) {
      return (
        this.points * (factor / this["target-points"] - this["min-points"])
      );
    }

    const svgSize = this.getBoundingClientRect().width;

    return 0.01;
  } */

  getRadius() {
    return (
      50 - parseInt(getCssVar("--lxl-components-points-gauge-line-size")) / 2
    );
  }

  getStrokeWidth() {
    return `${getCssVar("--lxl-components-points-gauge-line-size")}%`;
  }

  getLinePercentage() {
    const svgSize = this.getBoundingClientRect().width;
    const circumference = 2 * 3.14 * ((svgSize * this.getRadius()) / 100);
    const pointsPercentage =
      ((this.points - this["min-points"]) /
        (this["target-points"] - this["min-points"])) *
      100;

    if (this["target-points"] > 0) {
      return (circumference * pointsPercentage) / 100;
    }

    return circumference;
  }

  render() {
    return html` <div class="lxl-component-points-gauge">
      <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="${this.pointsLabel}">
        <circle
          style="stroke: ${getCssVar(
            `--lxl-components-points-gauge-color-tier-${this.tierCode}-empty`
          )};"
          class="lxl-component-points-gauge-background"
          r=${`${this.getRadius()}%`}
          cx="50%"
          cy="50%"
          fill="transparent"
          stroke-width=${this.getStrokeWidth()}
        ></circle>
        <circle
          style="stroke:${getCssVar(
            `--lxl-components-points-gauge-color-tier-${
              this.tierCode || 1
            }-points`
          )};
                   fill: ${getCssVar(
            `--lxl-components-points-gauge-color-tier-${
              this.tierCode || 1
            }-center-background`
          )};"
          class="lxl-component-points-gauge-points"
          r=${`${this.getRadius()}%`}
          cx="50%"
          cy="50%"
          stroke-width=${this.getStrokeWidth()}
          stroke-dasharray="${this.getLinePercentage()}, 80000"
          stroke-dashoffset="0"
        ></circle>
      </svg>
      <div class="lxl-component-points-gauge-text" role="region" aria-labelledby="points-label">
        <span
          style="color: ${getCssVar(
            `--lxl-components-points-gauge-color-tier-${
              this.tierCode || 1
            }-text`
          )}"
          class="lxl-component-points-gauge-text-points"
        >
          ${this.points.toLocaleString(this.locale)}${this["target-points"] > 0
            ? `/${this["target-points"].toLocaleString(this.locale)}`
            : nothing}
        </span>
        <span id="points-label" class="lxl-component-points-gauge-text-label">
          ${this.pointsLabel}
        </span>
      </div>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-points-gauge": LxlComponentPointsGauge;
  }
}
