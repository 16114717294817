import { LitElement, html, nothing } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import Swiper from "swiper";
import styles from "./lxl-component-menu.scss";
@customElement("lxl-component-menu")
export class LxlComponentMenu extends LitElement {
  /* `static styles = styles;` is defining a static property `styles` on the `LxlComponentMenu` class
  and assigning it the value of the imported `styles` object. This property is used to define the
  CSS styles for the component. */
  static styles = styles;

  /* This code is setting the `shadowRootOptions` static property of the `LxlComponentMenu` class. It
  is using the spread operator (`...`) to copy all the properties of the `shadowRootOptions`
  property of the `LitElement` class, and then setting the `mode` property to `"closed"`. */
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  /* `menuData` is a property of the `LxlComponentMenu` class that is defined as an array of strings.
  The `= []` part is initializing the property with an empty array as its default value. This
  property is used to store the data that will be displayed in the menu. By default, the `menuData`
  property is an empty array, but it can be set to an array of strings when an instance of the
  `LxlComponentMenu` class is created. */
  @property({ type: Array<String> })
  menuData: Array<string> = [];

  /* `menuId: string;` is defining a property called `menuId` on the `LxlComponentMenu` class that is of
  type `string`. This property is used to store an ID for the menu component, which can be used to
  uniquely identify the component in the DOM. The `@property({ attribute: "menu-id" })` decorator
  above the property indicates that the `menuId` property should be mapped to the `menu-id` attribute
  in the component's HTML markup. This allows the `menuId` property to be set and retrieved using HTML
  attributes, like this: `<lxl-component-menu menu-id="my-menu"></lxl-component-menu>`. If no
  `menu-id` attribute is provided, the `menuId` property will be `undefined`. */
  @property({ attribute: "menu-id" })
  menuId: string;

  /* `bubbles = false;` is defining a default value for the `bubbles` property of the
  `LxlComponentMenu` class. If no value is provided for `bubbles` when an instance of the class is
  created, it will default to `false`. This property is used to determine whether the custom event
  dispatched by the component should bubble up through the DOM and be handled by any element in the
  document. If `bubbles` is set to `true`, the event will bubble up through the DOM, and if it is
  set to `false`, it will not. */
  @property()
  bubbles = false;

  /* `selectedItemIndex: number = 0;` is defining a property called `selectedItemIndex` on the
  `LxlComponentMenu` class that is of type `number`. It is initializing the default value of this
  property to `0`. This property is used to keep track of the index of the currently selected item in
  the menu. It is initialized to `0`, which means that the first item in the menu will be selected by
  default. This property is used to apply a different style to the selected item in the menu. */
  @property({ reflect: true, type: Number })
  selectedItemIndex: number = 0;

  /* `filter: boolean = false;` is defining a property called `filter` on the `LxlComponentMenu` class
  that is of type `boolean`. It is initializing the default value of this property to `false`. This
  property is used to determine whether or not to apply a filter style to the menu. If `filter` is set
  to `true`, the `filter` attribute is added to the menu element, which applies a CSS filter style to the
  menu items. If `filter` is set to `false`, the `filter` attribute is not added, and the menu items are
  displayed without any CSS filter style. */
  @property({ type: Boolean })
  filter: boolean = false;

  /*  This property is used to determine whether or not to show the navigation
  buttons in the menu component. If `showNavigation` is set to `true`, the navigation buttons will be
  displayed, and if it is set to `false` the navigation buttons will be linked to showMobileNavigation props value. */
  @property({ type: Boolean })
  showNavigation: boolean = false;

  /**   This property is used to determine whether or not to
   * show the navigation buttons in the menu component on mobile devices. If `showMobileNavigation` is
   * set to `true`, the navigation buttons will be displayed on mobile devices, and if it is set to
   *`false`, the navigation buttons will be hidden on mobile devices. */
  @property({ type: Boolean })
  showMobileNavigation: boolean = false;

  /* `swiperEl` is a property of the `LxlComponentMenu` class that is decorated with the `@query`
  decorator. It is used to query and store a reference to the `swiper-container` element in the
  component's template.  */
  @query("swiper-container")
  swiperEl;

  @query(".lxl-swiper-button-next")
  lxlNext;

  @query(".lxl-swiper-button-prev")
  lxlPrev;
  /**  The `swiperRef` property is declaring a variable of type `Swiper` that will be used to store a
   * reference to the Swiper instance. This allows the component to interact with the Swiper API and
   * perform actions such as updating the slides or accessing the Swiper methods and properties.
   * */
  swiperRef: Swiper;

  /* The  property `nativeWindow` on the LxlComponentMenu` class with the value of the `window` object. */
  nativeWindow: Window = window;

  /* The `isMobile` property is a boolean variable that is used to determine whether the user is
 accessing the menu component from a mobile device. */
  isMobile: boolean;

  connectedCallback(): void {
    super.connectedCallback();

    this.isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        this.nativeWindow?.navigator.userAgent.toLowerCase()
      );
  }

  render() {
    return html`<div
      class="lxl-component-menu"
      id="lxl-component-menu-${this.menuId ?? nothing}"
      ?filter="${this.filter}"
    >
      <swiper-container
        free-mode="true"
        space-between="30"
        grab-cursor="true"
        slides-offset-before="8"
        slides-offset-after="8"
        slides-per-view="auto"
        centered-slides="false"
        centered-slides-bounds="false"
        init="false"
      >
        ${map(
          this.menuData,
          (value, index) => html` <swiper-slide>
            <div
              class="lxl-component-menu__item"
              id="lxl-component-menu-item-${this.menuId ?? nothing}-${index}"
            >
              <lxl-component-button
                class="${this.selectedItemIndex === index
                  ? `${!this.filter ? "menu" : "filter"} active`
                  : `${!this.filter ? "menu" : "filter"}`}"
                @click="${(e: Event) => this.itemClicked(e, index)}"
                >${value}</lxl-component-button
              >
            </div>
          </swiper-slide>`
        )}
      </swiper-container>
      ${this.showNavigation || (this.showMobileNavigation && this.isMobile)
        ? html` <div class="lxl-swiper-button-prev"></div>
            <div class="lxl-swiper-button-next"></div>`
        : nothing}
    </div>`;
  }

  firstUpdated() {
    this.swiperInit();
  }

  updated(changedProps) {
    this.swiperRef?.update();

    // For debugging mode (is always mobile) when resize up to 426px
    const mediaQuery = window.matchMedia("(min-width: 426px)");

    if (this.isMobile && !mediaQuery.matches) {
      this.swiperRef?.slideTo(this.selectedItemIndex);
    }
    if (
      changedProps.get("showNavigation") !== undefined ||
      changedProps.get("showMobileNavigation") !== undefined
    ) {
      this.fixUndefinedNavigation();
    }
  }

  /**
   * The `swiperInit()` function initializes a Swiper element with specified parameters and assigns it
   * to `this.swiperEl`.
   */
  private swiperInit() {
    // Swiper parameters
    const swiperParams = {
      ...((this.showNavigation ||
        (this.showMobileNavigation && this.isMobile)) && {
        navigation: {
          nextEl: this.lxlNext,
          prevEl: this.lxlPrev,
        },
      }),
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      resistanceRatio: 0.5, // 0 value in order to disable the resistance default 0.85
      injectStyles: [
        `
        .swiper-wrapper {}
        :host .swiper-wrapper {}
        :root {}
      `,
      ],
      on: {
        init: (swiper: Swiper) => {
          this.swiperRef = swiper;
        },
        afterInit: (swiper: Swiper) => {
          setTimeout(() => {
            this.swiperRef = swiper;
            swiper.update();
          });
        },
      },
    };
    // Assign all parameters to Swiper element
    Object.assign(this.swiperEl, swiperParams);
    // Initialize it
    this.swiperEl.initialize();
  }

  /**
   * The function fix a visual bug when showMobile or showMobileNavigation props are changed dinamically.
   * An "undefined" value is set on the text content of the node with class `swiper-button-next` replacing the svg children element.
   * It updates the navigation next button of a swiper element by removing the text content "undefined" value.
   * It updates the swiperRef.
   */
  private fixUndefinedNavigation() {
    const swiperShadowRoot = this.swiperEl.shadowRoot as ShadowRoot;
    if (!!swiperShadowRoot.querySelector(".swiper-button-next")) {
      swiperShadowRoot.querySelector(".swiper-button-next").textContent = "";
      this.swiperRef.update();
    }
  }
  /* `itemClicked(index: number)` is a private method of the `LxlComponentMenu` class that is
  called when a menu item is clicked. It takes an index parameter that represents the index
  of the clicked item in the `menuData` array. */
  private itemClicked(e: Event, index: number) {
    e.preventDefault();
    e.stopPropagation();

    this.selectedItemIndex = index;

    this._dispatchClickEvent(index);
  }

  /* The `_dispatchClickEvent(index: number)` method is a private method of the
  `LxlComponentMenu` class that is called when a menu item is clicked. It dispatches a
  custom event named "lxlmenuclick" with the `detail` property set to an object containing
  the `selectedIndex` and `menuId` properties. The `selectedIndex` property is set to the
  index of the clicked item in the `menuData` array, and the `menuId` property is set to the
  value of the `menuId` property of the `LxlComponentMenu` instance. The `bubbles` property
  of the custom event is set to the value of the `bubbles` property of the
  `LxlComponentMenu` instance, and the `composed` property is set to `true`. This custom
  event can be listened to by other elements in the DOM and can be used to trigger actions
  based on the selected menu item. */
  private _dispatchClickEvent(index: number) {
    this.dispatchEvent(
      new CustomEvent("lxlmenuclick", {
        detail: { selectedIndex: index, menuId: this.menuId },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-menu": LxlComponentMenu;
  }
}
