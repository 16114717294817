import { LitElement, html, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import styles from "./lxl-component-switch-menu.scss";
@customElement("lxl-component-switch-menu")
export class LxlComponentSwitchMenu extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property({ attribute: "menu-id" })
  menuId: string;

  @property({ type: Array<String> })
  menuData: Array<string> = [];

  /* `bubbles = false;` is defining a default value for the `bubbles` property of the
  `LxlComponentMenu` class. If no value is provided for `bubbles` when an instance of the class is
  created, it will default to `false`. This property is used to determine whether the custom event
  dispatched by the component should bubble up through the DOM and be handled by any element in the
  document. If `bubbles` is set to `true`, the event will bubble up through the DOM, and if it is
  set to `false`, it will not. */
  @property()
  bubbles = false;

  @property({ reflect: true, type: Number })
  selectedItemIndex: number = 0;

  @state()
  glidderWidth: string = "0px";

  @state()
  glidderPositionLeft: string = "0px";

  @query(".lxl-component-switch-menu__wrapper")
  wrapper: HTMLElement;

  @query(".lxl-component-switch-menu input[type='radio']:checked  + label")
  selectedTab: HTMLElement;

  enableGlidderTransition: boolean = false;

  render() {
    return html` <div
      class="lxl-component-switch-menu"
      id="lxl-component-switch-menu-${this.menuId ?? nothing}"
      role="tablist"
      @keydown="${this.handleKeyDown}"
      tabindex="0"
    >
      <div class="lxl-component-switch-menu__wrapper">
        ${map(
          this.menuData,
          (value, index) =>
            html`   
            <input
                id="lxl-component-switch-menu-radio-${index}"
                type="radio"
                name="tabs"
                role="tab"
                aria-selected="${this.selectedItemIndex === index ? 'true' : 'false'}"
                ?checked="${this.selectedItemIndex === index}"
                @click=${(e: Event) => this.inputChecked(e, index)}
                 />
           </label>
           <label class="lxl-component-switch-menu__label" for="lxl-component-switch-menu-radio-${index}">${value}</label>
           `
        )}
        ${this.menuData?.length > 0
          ? html`<span
              class="${this.enableGlidderTransition
                ? "glider  transition"
                : "glider"}"
              style="width:${this.glidderWidth};left:${this
                .glidderPositionLeft};"
            ></span>`
          : nothing}
      </div>
    </div>`;
  }

  firstUpdated() {
    this.updateComplete.then(() => {
      const firstInput = this.shadowRoot.querySelector("input[type='radio']");
      if (firstInput instanceof HTMLElement) {
        firstInput.focus();
      }
    });
  }

  updated() {
    this.glidderBehavior();
  }

  private glidderBehavior() {
    this.glidderWidth = `${this.selectedTab.offsetWidth}px`;

    this.glidderPositionLeft = `${this.selectedTab.offsetLeft}px`;
  }

  private inputChecked(e: Event, index) {
    e.stopPropagation();

    if (!this.enableGlidderTransition) {
      this.enableGlidderTransition = true;
    }

    this.selectedItemIndex = index;

    this._dispatchClickEvent(index);
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      e.preventDefault();
      const inputs = Array.from(this.shadowRoot.querySelectorAll("input[type='radio']"));
      let newIndex = this.selectedItemIndex;

      if (e.key === 'ArrowRight') {
        newIndex = (this.selectedItemIndex + 1) % inputs.length;
      } else if (e.key === 'ArrowLeft') {
        newIndex = (this.selectedItemIndex - 1 + inputs.length) % inputs.length;
      }

      (inputs[newIndex] as HTMLElement).click();
      (inputs[newIndex] as HTMLElement).focus();
    }
  }

  private _dispatchClickEvent(index: number) {
    this.dispatchEvent(
      new CustomEvent("lxlswitchmenuclick", {
        detail: { selectedIndex: index, menuId: this.menuId },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-switch-menu": LxlComponentSwitchMenu;
  }
}
