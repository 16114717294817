import { html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import styles from "./lxl-page-dashboard.scss";

@customElement("lxl-page-dashboard")
export class LxlPageDashboard extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LxlConnectedElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property({type: Boolean})
  suspended: boolean;

  @property({type: Boolean})
  unsubscribed: boolean;

  @property({type: Boolean})
  handleStatus: boolean;

  render() {
    return html` <div class="lxl-page-dashboard">
      <div class="lxl-page-dashboard-user-info" aria-labelledby="account information" role="region">
        ${this.unsubscribed ? nothing : html`
          <lxl-widget-profile
            jwt="${this.jwt}" 
            locale="${this.locale}" 
            ?handleStatus=${this.handleStatus}
            ?suspended=${this.suspended}>
        </lxl-widget-profile>`}
        ${
          this.suspended || this.unsubscribed ? 
            nothing :
            html`
              <lxl-widget-card-info
                fetchdata
                jwt="${this.jwt}"
                locale="${this.locale}"
              >
              </lxl-widget-card-info>
            `
        }
       
      </div>
      <div class="lxl-page-dashboard-program-info">
        <lxl-widget-program-info
          fetchdata
          jwt="${this.jwt}"
          locale="${this.locale}"
        >
        </lxl-widget-program-info>
      </div>
    </div>`;
  }

  lxlConnectedLoadingRender() {}
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-dashboard": LxlPageDashboard;
  }
}
