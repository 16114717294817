import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { getCssVar } from "lxl-utils/lxl-css";
import styles from "./lxl-component-loyalty-card.scss";

@customElement("lxl-component-loyalty-card")
export class LxlComponentLoyaltyCard extends LitElement {
  @query(".barcode")
  barcodeSvg: HTMLElement;

  @property({ type: String })
  "card-number": string;

  @property({type: String, attribute: 'cardimageurl'})
  cardImageUrl: string = ''

  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return this["card-number"]
      ? html` <div class="barcode-wrapper">
          <div
            style="background-image: url(${this.cardImageUrl})"
            class="barcode-wrapper-img"
          ></div>
          <div class="barcode-wrapper-barcode">
            <lxl-component-barcode
              line-color="${getCssVar(
                "--lxl-components-loyalty-card-text-color"
              )}"
              barcode-height="${getCssVar(
                "--lxl-components-loyalty-card-height"
              )}"
              barcode-width="${getCssVar(
                "--lxl-components-loyalty-card-width"
              )}"
              font-size="${getCssVar(
                "--lxl-components-loyalty-card-font-size"
              )}"
              card-number="${this["card-number"]}"
            >
            </lxl-component-barcode>
          </div>
        </div>`
      : html`<div></div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-loyalty-card": LxlComponentLoyaltyCard;
  }
}
