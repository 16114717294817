import { LitElement, html } from "lit";
import { customElement, property, query, queryAll, state } from "lit/decorators.js";
import { modalController } from "lxl-utils/lxl-portal";
import styles from "./lxl-component-modal.scss";

@customElement("lxl-component-modal")
export class LxlComponentModal extends LitElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property({ type: Boolean }) "prevent-backdrop-close";

  @query(".lxl-component-modal") modalContent;
  @query(".lxl-component-modal-close") closeButton;
  @query(".first-element") firstSentinel;
  @query(".last-element") lastSentinel;


  firstUpdated() {
    this.addEventListener('keydown', this.handleKeyDown.bind(this));
    setTimeout(() => {
      this.modalContent.focus();
    }, 0);
  }

  disconnectedCallback() {
    this.removeEventListener('keydown', this.handleKeyDown.bind(this));
    super.disconnectedCallback();
  }

  private backdropClose() {
    if (!this["prevent-backdrop-close"]) {
      this.close();
    }
  }

  close() {
    document.body.removeAttribute("style");
    modalController.removeAll();
  }

  connectedCallback(): void {
    super.connectedCallback();
    document.body.style.overflowY = "hidden";
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.trapFocus(event);
    } else if (event.key === 'Enter' && event.target === this.closeButton) {
      this.close();
    } else if (event.key === 'Escape') {
      this.close();
    }
  }


  trapFocus(event: KeyboardEvent) {
    const focusableElements = this.getFocusableElements();
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];
    const activeElement = this.shadowRoot?.activeElement || document.activeElement;
    if (event.shiftKey) {
      if (activeElement === firstElement) {
        lastElement.focus();
        event.preventDefault();
      }
    } else {
      if (activeElement === lastElement) {
        firstElement.focus();
        event.preventDefault();
      }
    }
  }

  getFocusableElements(): HTMLElement[] {
    if (!this.shadowRoot) return [];
    return Array.from(this.shadowRoot.querySelectorAll('[tabindex]:not([tabindex="-1"]), a, button, input, textarea, select, details, [contenteditable]'))
      .filter((el: Element) => !(el as HTMLElement).hasAttribute('disabled')) as HTMLElement[];
  }


  render() {
    return html`
      <div class="lxl-component-modal-backdrop" @click=${this.backdropClose}>
        <div class="lxl-component-modal" role="dialog" aria-model="true" tabindex="0">
        <div class="first-element" tabindex="0"></div>
          <div class="lxl-component-modal-close" @click=${this.close} @keydown="${this.handleKeyDown}" tabindex="0">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector"
                d="M19.75 1.41667L18.5833 0.25L10 8.83333L1.41667 0.25L0.25 1.41667L8.83333 10L0.25 18.5833L1.41667 19.75L10 11.1667L18.5833 19.75L19.75 18.5833L11.1667 10L19.75 1.41667Z"
                fill="black"
              />
            </svg>
          </div>
          <slot></slot>
          </div>
          <div class="last-element" tabindex="0"></div>
      </div>
    `;
  }


}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-modal": LxlComponentModal;
  }
}
