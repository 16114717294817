import { LitElement, html, nothing } from "lit";
import {customElement, property} from 'lit/decorators.js';
import styles from './lxl-component-program-info-card.scss';

@customElement('lxl-component-program-info-card')
export class LxlComponentProgramInfoCard extends LitElement {
    static styles = styles;

    static shadowRootOptions = {...LitElement.shadowRootOptions, mode: 'closed' as ShadowRootMode};

    @property() iconUrl: string;
    @property() title: string;
    @property() content: string;


    getIcon() {
        return this.iconUrl ?
          html`
              <img class="lxl-component-program-info-card-icon" src="${this.iconUrl}" alt="" />
          ` :
          nothing;
    }

    render() {
        return html`
          <div class="lxl-component-program-info-card">
            ${this.getIcon()}
            <div class="lxl-component-program-info-card-content">
              <h4>${this.title}</h4>
              <p>${this.content}</p>
            </div>
          </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'lxl-component-program-info-card': LxlComponentProgramInfoCard;
}
}
