import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import skeletonStyles from './lxl-component-page-filter-skeleton.scss';
import styles from './lxl-component-page-filter.scss';
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
interface IFilterItem {
  label: string;
  value: string;
}

interface ISortItem {
  id: number;
  label: string;
  value: string;
}

@customElement('lxl-component-page-filter')
export class LxlComponentPageFilter extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: 'open' as ShadowRootMode,
  };

  /** The element id */
  @property({ attribute: 'page-filter-id' })
  pageFilterId: string;

  /* This property is used to store the data for each filter item
  that will be rendered by the component. The `IFilterItem` interface defines the shape of the
  data that each item should have, including a `label` string and a `value` string. */
  @property()
  filterItems: Array<IFilterItem>;

  /* This property is used to store the data for each sort item
  that will be rendered by the component. The `ISortItem` interface defines the shape of the
  data that each item should have, including a `id` string, a `label` string, and a
  `value` string. */
  @property()
  sortItems: Array<ISortItem>;

  /* `bubbles = false;` is defining a property called `bubbles` with a default value of `false`. This
  property is used to determine whether or not the custom event dispatched by the component should
  bubble up the DOM tree. If `bubbles` is set to `true`, the event will bubble up the DOM tree and can
  be captured by parent elements. If `bubbles` is set to `false`, the event will not bubble up the DOM
  tree and can only be captured by the component itself or its shadow DOM children. The `bubbles`
  property can be overridden by passing a different value as an attribute when using the component. */
  @property()
  bubbles = false;

  /* The `sortText` property is used to store the text that will be displayed as the label for the sort
  component. It is of type `string`, which means it can hold any string value. This property is passed
  as an attribute when using the component and is used to customize the label text for the sort
  component. */
  @property({ attribute: 'sort-text' })
  sortText: string;

  /* `selectedFilterIndex = 0;` is initializing the value of the `selectedFilterIndex` property to 0. 
  This property is used to keep track of the index of the currently selected filter item in the `filterItems` array. 
  By default, the first filter item in the array will be selected when the component is rendered. */
  @property({ reflect: true, type: Number })
  selectedFilterIndex = 0;

  /* `selectedSortIndex = 0;` is initializing the value of the `selectedSortIndex` property to 0. This
  property is used to keep track of the index of the currently selected sort item in the `sortItems`
  array. By default, the first sort item in the array will be selected when the component is rendered. */
  @property({ reflect: true, type: Number })
  selectedSortIndex = 0;

  /* The `checkedSortItem` property is used to store the currently selected sort item in the
  `sortItems` array. It is of type `ISortItem`, which defines the shape of the data that
  each sort item should have, including an `id` number, a `label` string, and a `value`
  string. This property is updated when the user selects a sort item, and it is used to
  dispatch a custom event with the selected sort item as the event detail. */
  @state() checkedSortItem: ISortItem;

  lxlConnectedLoadingRender() {}

  firstUpdated() {
    const firstFilterButton = this.shadowRoot?.querySelector('.lxl-component-page-filter__header-filter--button lxl-component-button');
    if (firstFilterButton instanceof HTMLElement) {
      firstFilterButton.focus();
      const liveRegion = this.shadowRoot?.getElementById('live-region');
      if (liveRegion) {
        liveRegion.textContent = this.localize("LXL_ACCESSIBILITY_FILTER_FOCUS", [this.filterItems[0].label]);
      }
    }
  }

  render() {
    return html` <div
      class="lxl-component-page-filter"
      id="lxl-component-page-filter-${this.pageFilterId ?? nothing}"
    >
    <span id="live-region" style="position: absolute; width: 1px; height: 1px; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0;" aria-live="assertive" aria-atomic="true"></span>
      <div class="lxl-component-page-filter__header">
        <div class="lxl-component-page-filter__header-filter" role="tablist">
          ${map(
            this.filterItems.map((item) => item.label),
            (value, index) => html` <div
              class="lxl-component-page-filter__header-filter--button"
              role="tab"
              aria-selected="${this.selectedFilterIndex === index ? 'true' : 'false'}"
              tabindex="-1"
            >
              <lxl-component-button
                class="${this.selectedFilterIndex === index
                  ? `menu active`
                  : `menu`}"
                @click="${(e: Event) =>
                  this._dispatchSelectFilterEvent(e, index)}"
                >${value}</lxl-component-button
              >
            </div>`
          )}
        </div>
        <div class="lxl-component-page-filter__header-sort" role="region">
          <lxl-component-sort
            sort-text="${this.sortText}"
            .sortData="${this.sortItems}"
            selectedItemIndex="${this.selectedSortIndex}"
            @lxlsortcheck="${(e: CustomEvent) =>
              this._dispatchCheckSortEvent(e.detail.checkedItem)}"
          ></lxl-component-sort>
        </div>
      </div>
    </div>`;
  }

  private _dispatchSelectFilterEvent(e: Event, index: number) {
    e.stopPropagation();
    this.selectedFilterIndex = index;

    this.dispatchEvent(
      new CustomEvent('lxlselectfilter', {
        detail: { selectedFilterIndex: index },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }

  private _dispatchCheckSortEvent(selectedSortItem: ISortItem) {
    this.dispatchEvent(
      new CustomEvent('lxlchecksort', {
        detail: { selectedSortItem },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'lxl-component-page-filter': LxlComponentPageFilter;
  }
}

@customElement('lxl-component-page-filter-skeleton')
export class LxlComponentPageFilterSkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: 'open' as ShadowRootMode,
  };

  render() {
    return html`
      <div class="lxl-component-page-filter-skeleton">
        <div class="lxl-component-page-filter-skeleton__header">
          <div class="lxl-component-page-filter-skeleton__header-filter">
            ${map(
              Array(4),
              (value, index) => html` <div
                class="lxl-component-page-filter-skeleton__header-filter--button"
              >
                <lxl-component-skeleton
                  button
                  height="40"
                ></lxl-component-skeleton>
              </div>`
            )}
          </div>
          <div class="lxl-component-page-filter-skeleton__header-sort">
            <lxl-component-skeleton button height="40"></lxl-component-skeleton>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'lxl-component-page-filter-skeleton': LxlComponentPageFilterSkeleton;
  }
}
