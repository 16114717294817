import { LitElement, html, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { store } from "lxl-data";
import {
  HistoryEvent,
  HistoryState,
  historyApi,
  selectHistory,
} from "lxl-data/history";
import { LxlConnectedElement } from "lxl-utils/lxl-connected-element";
import styles from "./lxl-page-history.scss";
import skeletonStyles from "./lxl-page-history.skeleton.scss";
interface HistoryPoints {
  gained: number;
  lost: number;
}

class HistorySortItem {
  id: string;
  label: string;
  value?: string;
}
@customElement("lxl-page-history")
export class LxlPageHistory extends LxlConnectedElement {
  static styles = styles;

  static shadowRootOptions = {
    ...LxlConnectedElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  @property()
  lxlHistoryData: HistoryState;

  @state() selectedFilterIndex = 0;

  @state() selectedSortIndex = 0;

  @state()
  historyData: HistoryEvent[];

  @state()
  historyDataTotal: number;

  @state()
  currentBalanceValue: number;

  @state()
  noItemsFoundMessage: string;

  @state()
  historyPoints: HistoryPoints = { gained: 0, lost: 0 };

  @query("lxl-component-paginator")
  paginatorElement: LitElement & { reload: () => {} };

  @query("lxl-component-page-filter")
  filterElement: LitElement;

  filterItems: any[] = [{ label: "", value: "" }];

  sortItems: HistorySortItem[] = [{ id: "", label: "", value: "" }];

  query = historyApi.endpoints.getHistory;

  selector = selectHistory;

  fetchData() {
    store
      .dispatch(
        historyApi.endpoints.getHistory.initiate({
          jwt: this.jwt,
          locale: this.locale,
        })
      )
      .then(() => this.init());
  }

  lxlConnectedLoadingRender() {
    return html`<lxl-page-history-skeleton></lxl-page-history-skeleton>`;
  }

  lxlConnectedRender() {
    this.filterItems = [
      { label: this.localize("LXL_WIDGET_HISTORY_FILTER_ALL"), value: "ALL" },
      {
        label: this.localize("LXL_WIDGET_HISTORY_FILTER_GAINED"),
        value: "GAINED",
      },
      { label: this.localize("LXL_WIDGET_HISTORY_FILTER_LOST"), value: "LOST" },
    ];

    this.sortItems = [
      {
        id: "0",
        label: this.localize("LXL_WIDGET_HISTORY_SORT_NEWEST"),
        value: "ASC",
      },
      {
        id: "1",
        label: this.localize("LXL_WIDGET_HISTORY_SORT_OLDEST"),
        value: "DESC",
      },
    ];

    return html`<div class="lxl-page-history" role="region">
      <lxl-component-page-filter
        selectedFilterIndex=${this.selectedFilterIndex}
        .filterItems=${this.filterItems}
        .sortItems=${this.sortItems}
        sort-text=${this.localize("LXL_WIDGET_HISTORY_SORT")}
        @lxlselectfilter="${(e: CustomEvent) =>
          this.selectFilterIndex(e.detail.selectedFilterIndex)}"
        @lxlchecksort="${(e: CustomEvent) =>
          this.selectSortItem(e.detail.selectedSortItem)}"
      >
      </lxl-component-page-filter>

      <div class="lxl-page-history__list" role="list">
        ${this.historyDataTotal > 0
          ? html`<div class="lxl-page-history__list--header">
                <span
                  >${this.localize("LXL_WIDGET_HISTORY_HEADER_ACTIONS")}</span
                >
                <span>${this.localize("LXL_WIDGET_HISTORY_HEADER_DATE")}</span>
                <span
                  >${this.localize("LXL_WIDGET_HISTORY_HEADER_POINTS")}</span
                >
              </div>
              ${map(
                this.historyData,
                (ev) => html` <div class="lxl-page-history__list--item" role="listitem">
                  <div>
                    <span>${ev.originalEventType}</span>
                    <span
                      >${!!ev.expired
                        ? this.localize("LXL_PAGE_HISTORY_POINTS_EXPIRED")
                        : ev.accrualEventDescription}</span
                    >
                  </div>
                  <div>
                    ${new Date(ev.accrualEventDate).toLocaleDateString(
                      this.locale,
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        timeZone: "UTC"
                      }
                    )}
                  </div>
                  <div
                    class="${!ev.expired
                      ? ev.accrualEventPoints > 0
                        ? "gained"
                        : ev.accrualEventPoints < 0
                        ? "lost"
                        : nothing
                      : "expired"}"
                  >
                    ${(ev.accrualEventPoints > 0 ? "+" : "") +
                    ev.accrualEventPoints}
                  </div>
                </div>`
              )}`
          : nothing}
        ${this.historyDataTotal === 0 ? this.noItemsFoundMessage : nothing}
      </div>

      ${this.historyDataTotal > 0
        ? html`<div class="lxl-page-history__paginator">
            <div class="lxl-page-history__paginator-container">
              <lxl-component-paginator
                paginator-id="history-paginator"
                pageSize="10"
                totalItems="${this.historyDataTotal || 0}"
                @lxlpaginatorclick="${(e: CustomEvent) => {
                  this.paginatorHistoryClick(e.detail);
                }}"
              >
              </lxl-component-paginator>
            </div>
          </div>`
        : nothing}
    </div>`;
  }

  private init() {
    this.historyDataTotal = this.data?.history?.length || 0;
    this.getNoItemFoundMessage(0);

    /*  this.currentBalanceValue =
      this.lxlHistoryData?.currentTier?.currentBalanceValue; */
    // this.historyPoints = { ...this.getHistoryPoints() };
  }

  private getHistoryPoints() {
    const gained = this.data.history?.reduce((acc, curr) => {
      return curr.accrualEventPoints > 0 ? acc + curr.accrualEventPoints : acc;
    }, 0);

    const lost = this.data.history?.reduce((acc, curr) => {
      return curr.accrualEventPoints < 0 ? acc + curr.accrualEventPoints : acc;
    }, 0);

    return { gained, lost };
  }

  private paginatorHistoryClick({
    startItemIndex,
    endItemIndex,
  }: {
    startItemIndex: number;
    endItemIndex: number;
  }) {
    // this.scrollItems();
    const status = this.getHistoryItemStatus(this.selectedFilterIndex);
    const historyItems = this.getHistoryItems(status);

    this.historyData = [...historyItems].slice(
      startItemIndex,
      endItemIndex + 1
    );
  }

  private getHistoryItems(pointsStatus: any) {
    const sortedData = this.sortedData();

    return sortedData.filter((item) =>
      pointsStatus === "ALL"
        ? true
        : pointsStatus === "GAINED"
        ? item.accrualEventPoints > 0
        : item.accrualEventPoints < 0
    );
  }

  private sortedData() {
    return [...this.data.history].sort((a, b) => {
      return this.selectedSortIndex === 0
        ? this.sortDesc(a.accrualEventDate, b.accrualEventDate)
        : this.sortAsc(a.accrualEventDate, b.accrualEventDate);
    });
  }

  private sortAsc(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  private sortDesc(a, b) {
    return a < b ? 1 : a > b ? -1 : 0;
  }

  private async selectFilterIndex(index: number) {
    this.selectedFilterIndex = index;

    this.getNoItemFoundMessage(index);

    this.recalculateTotalItems(index);

    if (this.historyDataTotal > 0) {
      await this.updateComplete;
      this.paginatorElement?.reload();
    } else {
      this.historyData = [];
    }
  }

  private async selectSortItem(selectedItem: any) {
    this.selectedSortIndex = this.sortItems.findIndex(
      (el) => el.id === selectedItem.id
    );

    this.recalculateTotalItems(this.selectedFilterIndex);

    if (this.historyDataTotal > 0) {
      await this.updateComplete;
      this.paginatorElement?.reload();
    } else {
      this.historyData = [];
    }
  }
  private recalculateTotalItems(index: number) {
    const status = this.getHistoryItemStatus(index);

    this.historyDataTotal = this.getHistoryItems(status).length;
  }

  private getHistoryItemStatus(index: number) {
    return this.filterItems[index].value;
  }

  private getHistoryItemLabel(index: number) {
    return this.filterItems[index].label;
  }

  /**
   * The function sets the "noItemsFoundMessage" property by localizing a message with the status and
   * page name.
   * @param {number} index - The `index` parameter is a number that represents the index of a history
   * item.
   */
  private getNoItemFoundMessage(index: number) {
    const status: string = this.getHistoryItemStatus(index);
    const label: string = this.getHistoryItemLabel(index);
    const pageName = this.localize("LXL_MYLOYALTY_MENU_HISTORY").toLowerCase();

    this.noItemsFoundMessage = this.localize(
      "LXL_MYLOYALTY_NO_ITEMS_FOUND_MESSAGE",
      [status !== "ALL" ? label.toLowerCase() : "", pageName]
    ).replace(/\s+/g, " ");
  }

  /**
   * The scrollItems function scrolls the filterElement into view smoothly.
   */
  private scrollItems() {
    setTimeout(() => {
      this.filterElement?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-history": LxlPageHistory;
  }
}

@customElement("lxl-page-history-skeleton")
export class LxlPageHistorySkeleton extends LitElement {
  static styles = [styles, skeletonStyles];

  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  render() {
    return html`<div class="lxl-page-history-skeleton">
      <lxl-component-page-filter-skeleton> </lxl-component-page-filter-skeleton>

      <div class="lxl-page-history-skeleton__list">
        <lxl-component-skeleton></lxl-component-skeleton>
      </div>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-page-history-skeleton": LxlPageHistorySkeleton;
  }
}
