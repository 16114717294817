import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import styles from "./lxl-component-popover.scss";

export enum PopoverPosition {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  BOTTOM_LEFT = "bottom left",
}

@customElement("lxl-component-popover")
export class LxlComponentPopover extends LitElement {
  /* `static styles = styles;` is defining the component's styles using the `styles` variable imported
  from a separate SCSS file. The `styles` variable contains the CSS rules for the component and is
  assigned to the `styles` property of the component class. This allows the component to use the
  styles defined in the SCSS file. */
  static styles = styles;

  /* `static shadowRootOptions` is a static property of the `LxlComponentPopover` class that sets the
  options for the shadow root of the component. It is using the spread operator (`...`) to copy the
  default `shadowRootOptions` from the `LitElement` class and then setting the `mode` property to
  `"closed"`. This means that the component's shadow root will be closed and inaccessible from
  outside the component. This is a security measure to prevent external styles and scripts from
  affecting the component's internal behavior. */
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    mode: "open" as ShadowRootMode,
  };

  /* `position: string = PopoverPosition.BOTTOM;` is defining a property called `position` with a
  default value of `PopoverPosition.BOTTOM`. `PopoverPosition` is an enum that defines the possible
  values for the `position` property, which are `TOP`, `BOTTOM`, `LEFT`, and `RIGHT`. By setting the
  default value to `PopoverPosition.BOTTOM`, the component will initially render with the popover
  positioned at the bottom. The `position` property can be overridden by passing a different value
  as an attribute when using the component. */
  @property({ type: String })
  position: string = PopoverPosition.BOTTOM;

  /* `enableClick: boolean = true;` is defining a property called `enableClick` with a default value of
  `true`. This property is used to determine whether or not the popover can be triggered by a
  click event. If `enableClick` is set to `true`, the popover can be triggered by a click event. If
  `enableClick` is set to `false`, the popover can only be triggered by a mouseover event. The
  `enableClick` property can be overridden by passing a different value as an attribute when using
  the component. */
  @property({ type: Boolean, attribute: "enable-click" })
  enableClick: boolean = true;

  /* `open: boolean = false;` is defining a property called `open` with a default value of `false`.
  This property is used to determine whether or not the popover is currently open. If `open` is set
  to `true`, the popover is visible. If `open` is set to `false`, the popover is hidden. The `open`
  property can be overridden by passing a different value as an attribute when using the component. */
  @property({ type: Boolean })
  open: boolean = false;

  /* `bubbles = false;` is defining a property called `bubbles` with a default value of `false`. This
  property is used to determine whether or not the custom event dispatched by the component should
  bubble up the DOM tree. If `bubbles` is set to `true`, the event will bubble up the DOM tree and can
  be captured by parent elements. If `bubbles` is set to `false`, the event will not bubble up the DOM
  tree and can only be captured by the component itself or its shadow DOM children. The `bubbles`
  property can be overridden by passing a different value as an attribute when using the component. */
  @property()
  bubbles = false;

  protected render() {
    return html`
      <div
        class="${this.open
          ? "lxl-component-popover open"
          : "lxl-component-popover"}"
      >
        <div class="lxl-component-popover__container ${this.position}">
          <div class="lxl-component-popover__container--slotted">
            <slot name="content"></slot>
          </div>
        </div>
        <div
          class="lxl-component-popover__trigger"
          @mouseover="${() => {
            this.enableClick ? "" : this.showPopover();
          }}"
          @mouseout="${() => {
            this.enableClick ? "" : this.hidePopover();
          }}"
          @click="${() => {
            this.enableClick ? this.togglePopover() : "";
          }}"
          @keydown="${(e: KeyboardEvent) => {
            if(e.key === 'Enter' || e.key === ' '){
              e.preventDefault();
              this.togglePopover();
            }
          }}"
        >
          <slot name="controller"></slot>
        </div>
      </div>
    `;
  }

  /**  The `_dispatchStatus(value: boolean)` function is a private function in the
   * `LxlComponentPopover` class that dispatches a custom event with a boolean value indicating
   * the status of the popover being opened. It takes a boolean value as a parameter, which
   * represents the new status of the popover (i.e., whether it should be open or closed).
   * @param {boolean} value - The value parameter is a boolean value that represents the new status of
   * the popover. It is used to determine whether the popover should be open or closed.
   */
  private _dispatchStatus(value: boolean) {
    this.dispatchEvent(
      new CustomEvent("lxlpopoverchange", {
        detail: { open: value },
        bubbles: this.bubbles,
        composed: true,
      })
    );
  }

  /* `hidePopover()` is a private function that sets the `open` property of the component to
  `false`, which hides the popover. It also calls the `_dispatchStatus()` function with a
  `false` value, which dispatches a custom event with a boolean value indicating the status
  of the popover being opened. This function is called when the mouse leaves the trigger
  element if the `enableClick` property is set to `false`. */
  private hidePopover() {
    this.open = false;
    this._dispatchStatus(false);
  }

  /* `showPopover()` is a private function that sets the `open` property of the component to
  `true`, which shows the popover. It also calls the `_dispatchStatus()` function with a
  `true` value, which dispatches a custom event with a boolean value indicating the status
  of the popover being opened. This function is called when the mouse enters the trigger
  element if the `enableClick` property is set to `false`. */
  private showPopover() {
    this.open = true;
    this._dispatchStatus(true);
  }

  /* `togglePopover()` is a function that toggles the value of the `open` property of the
  component. If `open` is currently `false`, it sets it to `true`, and if `open` is
  currently `true`, it sets it to `false`. It then calls the `_dispatchStatus()` function
  with the new value of `open`, which dispatches a custom event with a boolean value
  indicating the status of the popover being opened. This function is called when the
  trigger element is clicked if the `enableClick` property is set to `true`. */
  private togglePopover() {
    this.open = !this.open;
    this._dispatchStatus(this.open);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "lxl-component-popover": LxlComponentPopover;
  }
}
