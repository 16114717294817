import {LitElement, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import styles from './lxl-component-skeleton.scss';

@customElement('lxl-component-skeleton')
export class LxlComponentSkeleton extends LitElement {
    static styles = styles;

    static shadowRootOptions = {...LitElement.shadowRootOptions, mode: 'closed' as ShadowRootMode};

    @property({type: Boolean})
    round;

    @property({type: Boolean})
    button

    @property()
    width: number

    @property()
    height: number;

    render() {
        const classes = { 'lxl-component-skeleton': true, round: this.round, button: this.button };
        return html`
            <div 
              style="height: ${`${this.height}px`};"
              class=${classMap(classes)}>
                <slot></slot>
            </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'lxl-component-skeleton': LxlComponentSkeleton;
}
}
